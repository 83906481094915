/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AuthenticatedViews
 */
import * as React from 'react';
import {observer} from 'mobx-react-lite';
import {useAppServices} from '../app/AppStates';
import { Redirect } from 'react-router-dom';
import {useInitData, useServerData} from "../core/data/DataLoaderHooks";
import {FullScreenLoading} from "../../common/splash/SplashScreen";
import {APP_ROUTES} from "../app/AppRoutes";
import {useLocation} from "react-use";

interface AuthenticatedViewsProps {
}

export const AuthenticatedViews: React.FC<AuthenticatedViewsProps> = observer(({...p}) => {
    const {authService} = useAppServices();

    const currentPage = useLocation().pathname;

    return <>
        {authService.authenticated ? <UserView {...p}>{p.children}</UserView> : <Redirect to={authService.getRedirectRoute(currentPage)}/>}
    </>
});

// ======================
// UserView
// ======================

interface UserViewProps{
}

export const UserView: React.FC<UserViewProps> = observer((props)=>{

    const {authService} = useAppServices();

    useInitData({
        poll: ()=> authService.fetchUserInfo(),
        pollInterval: 30
    })

    return useServerData(authService.currentUser, <FullScreenLoading/>, data=> {
        return <React.Fragment {...props}>
            {props.children}
        </React.Fragment>
    })
})


