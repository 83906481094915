/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: ServerAPI
 */
import {isDevelopment} from '../../common/utils/util';
import {APIError} from '../../common/errors';
import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
//http://localhost:3020/api/v1/
export class ServerAPI {
    private axios: AxiosInstance;

    private _apiToken: string | null = null;
    constructor() {
        this.axios = axios.create({
                                      baseURL: isDevelopment() ? `http://localhost:3020/api/v1` : '/api/v1/',
                                      headers: {
                                          accept: 'application/json',
                                      },
                                  });

        this.axios.interceptors.request.use(async c => {
            if (this._apiToken)
            {
                c.headers['Authorization'] = `Bearer ${this._apiToken}`;
            }

            return c;
        });

        this.axios.interceptors.response.use(r => r, error => {
            const errorData = error.response ? error.response.data : null;
            if (error.response && errorData && !!errorData.error && errorData.message)
            {
                const statusText = error.response.statusText;
                const message    = errorData.message || statusText;
                const status     = error.response.status;
                throw new APIError(message, status, statusText, error);
            }
            throw error;
        });
    }

    setAPIToken(value: string) {
        this._apiToken = value;
    }

    interceptError(statusCode: number, cb: (err: Error) => Promise<any>) {
        this.axios.interceptors.response.use(r => r, async error => {
            let match = false;
            if (error instanceof APIError)
            {
                match = error.status === statusCode;
            }
            else if (error)
            {
                match = error.response.status === statusCode;
            }

            if (match)
            {
                await cb(error);
            }
            throw error;
        });
    }

    async GET<T = any>(url: string, opts: AxiosRequestConfig = {}): Promise<T> {
        const response = await this.axios.get<T>(url, opts);
        return response.data;
    }

    async PUT<T = any>(url: string,data:any, opts: AxiosRequestConfig = {}): Promise<T> {
        const response = await this.axios.put<T>(url, data,opts);
        return response.data;
    }
    async POST<T = any>(url: string,data:any, opts: AxiosRequestConfig = {}): Promise<T> {
        const response = await this.axios.post<T>(url, data,opts);
        return response.data;
    }

    async DELETE<T = any>(url: string, opts: AxiosRequestConfig = {}): Promise<T> {
        const response = await this.axios.delete<T>(url, opts);
        return response.data;
    }

}

