import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {MigrationProjectDetailsState} from "./MigrationProjectDetailsState";
import {useMigrationProjectDetailsStyles} from "./MigrationProjectStyles";
import {useInitData, useServerData} from "../../core/data/DataLoaderHooks";
import {
    Avatar,
    Box,
    Button,
    Card,
    Chip, FormControl, Grid, IconButton, InputLabel, MenuItem, OutlinedInput, Select,
    SvgIcon, Tooltip,
    Typography,
    useTheme
} from "@material-ui/core";
import {
    ACTIVITY_TYPES, DROPZONE_FILE_TYPE,
    NEW_PROJECT_ACTIVITY_FORM_VALUES_TYPE,
    PROJECT_ACTIVITIES_TYPE, PROJECT_ACTIVITY_ATTACHMENT_TYPE,
    PROJECT_ACTIVITY_TYPE, TIMESHEET_ENTRY_INITIAL_VALUES
} from "../MigrationProjectTypes";
import * as yup from "yup";
import {Form, Formik, useField} from "formik";
import {
    FormCheckboxItem,
    FormDateTimePicker,
    FormTextField,
    useSelectStyles
} from "../../../common/form/FormComponents";
import {
    IoIosAdd,
    IoMdCalendar,
    FaUserCircle,
    FaChartLine, IoIosGitNetwork, IoMdAdd, FaUser, IoMdClose, MdDelete, FiTrash2
} from "react-icons/all";
import {ContactView, EmployeeView} from "../../auth/EmployeeView";
import {
    add,
    differenceInMinutes,
    format,
    formatDistanceToNow,
    formatISO,
    formatISO9075,
    formatRelative,
    parseISO
} from "date-fns";
import {PSCardList, PSTable} from "../../../common/table/PSTable";
import {ProjectActivityEventIDs} from "../MigrationProjectTypes";
import {Schema} from "yup";
import {LoadingScreen} from "../../../common/splash/SplashScreen";
import {useIsDesktop} from "../../layout/MainLayout";
import {QDropzone} from "../../core/dropzone/DropzoneComponents";
import {DownloadFileCard} from "./Questionnaire/MigrationProjectQuestionnaireView";
import {useAppServices} from "../../app/AppStates";
import {APP_ROUTES} from "../../app/AppRoutes";

// ======================
// ProjectActivity
// ======================

interface ProjectActivityProps {
    detailsState: MigrationProjectDetailsState
}

export const ProjectActivity: React.FC<ProjectActivityProps> = observer((props) => {


    const {detailsState} = props;

    useInitData({
        poll: () => detailsState.fetchProjectActivities(),
        pollInterval: 1
    })

    return useServerData(detailsState.activities, <LoadingScreen/>, (data: PROJECT_ACTIVITIES_TYPE) => {
        return <>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Box pb={2} pt={4}>
                        <Typography variant={'h4'}>
                            Activity
                        </Typography>
                    </Box>
                    <Box>
                        <NewActivityCard
                            addActivity={(values: NEW_PROJECT_ACTIVITY_FORM_VALUES_TYPE) => detailsState.addProjectActivity(values)}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box pt={0} pb={10}>
                        <PSCardList tableData={data} cardComponent={ActivityRowComponent}
                                    tableState={detailsState.activitiesTableState}
                                    noPagination
                                    cardProps={{detailsState: detailsState}}/>
                    </Box>
                </Grid>

            </Grid>


        </>

    })

})

// ======================
// ActivityRowComponent
// ======================

interface ActivityRowComponentProps {
    data: PROJECT_ACTIVITY_TYPE
    detailsState: MigrationProjectDetailsState
}

export const ActivityRowComponent: React.FC<ActivityRowComponentProps> = observer((props) => {

    const {data, detailsState} = props;
    const t = useTheme();
    const styles = useMigrationProjectDetailsStyles(props);
    const isDesktop = useIsDesktop();
    const {migrationProjectService} = useAppServices();

    let icon: React.ReactElement;

    const projectUpdate: Array<string> = [ProjectActivityEventIDs.PROJECT_APPROVED, ProjectActivityEventIDs.PROJECT_PROGRESS_UPDATED,
        ProjectActivityEventIDs.PROJECT_COMPLETED, ProjectActivityEventIDs.PROJECT_INFO_UPDATED]

    if (projectUpdate.includes(data.eventId)) {
        icon = <FaChartLine/>
    } else if (data.eventId === ProjectActivityEventIDs.SYSTEM_GENERATED_EVENT) {
        icon = <IoIosGitNetwork/>
    } else if (data.eventId === ProjectActivityEventIDs.TIMESHEET_LOG_EVENT) {
        icon = <IoMdCalendar/>
    } else if (data.eventId === ProjectActivityEventIDs.USER_ENTERED_EVENT) {
        icon = <FaUserCircle/>
    } else {
        icon = <IoIosGitNetwork/>
    }

    const renderAcitivtyDeleteButton = (eventId: string) => {
        if (eventId === ProjectActivityEventIDs.TIMESHEET_LOG_EVENT || eventId === ProjectActivityEventIDs.USER_ENTERED_EVENT) {
            return <Box>
                <Tooltip title={'Delete Activity'}>
                    <IconButton className={styles.activityDeleteButton}
                                onClick={() => detailsState.deleteActivity(data.id)}>
                        <SvgIcon>
                            <FiTrash2/>
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            </Box>
        } else {
            return null;
        }
    }

    return (<Box pt={1}>
            <svg width={'100%'} height={20}>
                <line x1={'50%'} y1={0} x2={'50%'} y2={20} strokeWidth={3} stroke={t.palette.primary.main}/>
            </svg>
            <Card>
                <Box p={2}>
                    <Box className={styles.activityTitleBar} pb={1}>
                        <Box>
                            <Typography className={styles.dataOverline}
                                        variant={'overline'}>{formatDateString(data.createdAt)} ({formatDistanceToNow(parseISO(data.createdAt))} ago)</Typography>
                            <Box className={styles.activitiesCardContent} pt={1} pb={1}>
                                <Box pr={1}>
                                    <SvgIcon>
                                        {icon}
                                    </SvgIcon>
                                </Box>
                                <Box>
                                    <Typography variant={'h5'}>{data.title}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {renderAcitivtyDeleteButton(data.eventId)}
                    </Box>
                    {data.message &&
                    <Box pb={2}>
                        <Typography variant={'subtitle1'}>
                            {data.message}
                        </Typography>
                    </Box>
                    }
                    {data.attachments &&
                    <Box pb={3}>
                        <Grid container spacing={3}>
                            {data.attachments.map(attachment => {
                                return <Grid item xs={12} sm={4}>
                                    <DownloadFileCard file={attachment}
                                                      downloadFunction={(uuid: string, fileName: string) => migrationProjectService.downloadActivityAttachment(uuid, fileName)}/>
                                </Grid>
                            })}
                        </Grid>
                    </Box>
                    }
                    <Box className={styles.spaceBetweenItems}>
                        <Box className={styles.activitiesCardContent}>
                            <Box pr={1}>
                                <Avatar className={styles.xsAvatar} src={data.author.pictureUrl}/>
                            </Box>
                            <Typography variant={'caption'}>{data.author.fullName}</Typography>
                        </Box>
                        <Box>
                            {data.confidential &&
                            <Chip label={'Confidential'} className={styles.confidentialChip}/>
                            }
                        </Box>
                    </Box>

                </Box>
            </Card>
        </Box>
    )
})


// ======================
// NewActivityCard
// ======================

interface NewActivityCardProps {
    addActivity: (values: NEW_PROJECT_ACTIVITY_FORM_VALUES_TYPE) => void;
}

export const NewActivityCard: React.FC<NewActivityCardProps> = observer((props) => {

    const {addActivity} = props;

    const [activityType, setActivityType] = useState('')

    const resetActivityType = () => {
        setActivityType('')
    }

    return (
        <Card>
            <Box p={3}>
                <SelectActivityDropdown activityType={activityType}
                                        setActivityType={(type: string) => setActivityType(type)}/>
            </Box>
            {activityType === ACTIVITY_TYPES.LOG &&
            <Box>
                <LogTimeForm addActivity={addActivity} resetDropdown={resetActivityType}/>
            </Box>
            }
            {activityType === ACTIVITY_TYPES.GENERAL &&
            <Box>
                <BasicActivityForm addActivity={addActivity} resetDropdown={resetActivityType}/>
            </Box>
            }

        </Card>
    )
})


// ======================
// SelectActivityDropdown
// ======================

interface SelectActivityDropdownProps {
    activityType: string | null;
    setActivityType: (type: string) => void;
}

export const SelectActivityDropdown: React.FC<SelectActivityDropdownProps> = observer((props) => {

    const styles = useSelectStyles(props);

    const renderList = (list: Array<any>) => {
        return list.map(name => (
            <MenuItem key={name} value={name}>
                {name}
            </MenuItem>
        ))
    }

    const renderValue = (value: any) => {
        if (value) {
            return value;
        } else {
            return ''
        }
    }

    const selectionList = ['General', 'Timesheet Entry']

    const handleChange = (event: React.ChangeEvent<any>) => {
        props.setActivityType(event.target.value)
    };

    const label = 'Add New Activity'

    return (
        <>
            <Box>
                <FormControl fullWidth>
                    <InputLabel id={'select-label'} variant={'outlined'}>{label}</InputLabel>

                    <Select labelId={'select-label'}
                            fullWidth {...props}
                            className={styles.field}
                            value={props.activityType}
                            onChange={handleChange}
                            renderValue={renderValue}
                            variant={'outlined'}
                            input={<OutlinedInput labelWidth={label.length * 7}/>}
                            inputProps={{'aria-label': 'Add New Activity Dropdown'}}
                    >
                        {renderList(selectionList)}
                    </Select>
                </FormControl>

            </Box>

        </>
    )
})


// ======================
// BasicActivityForm
// ======================

interface BasicActivityFormProps {
    addActivity: (values: NEW_PROJECT_ACTIVITY_FORM_VALUES_TYPE) => void;
    resetDropdown: () => void;
}

export const BasicActivityForm: React.FC<BasicActivityFormProps> = observer((props) => {

    const {addActivity} = props;

    const styles = useMigrationProjectDetailsStyles(props);

    const initialValues: { title: string, message: string, confidential: boolean, attachments: Array<DROPZONE_FILE_TYPE> } = {
        title: '',
        message: '',
        confidential: false,
        attachments: null
    };


    const schema = yup.object({
        title: yup.string().required().max(1024),
        message: yup.string().notRequired().max(1024),
        confidential: yup.boolean().notRequired().default(false),
        attachments: yup.mixed().nullable()
    });

    return <Formik initialValues={initialValues} validationSchema={schema} onSubmit={async (values, actions) => {

        let formattedAttachments = formatAttachments(values.attachments);

        let formattedValues: NEW_PROJECT_ACTIVITY_FORM_VALUES_TYPE = {
            title: values.title,
            message: values.message,
            confidential: values.confidential,
            attachments: formattedAttachments
        }

        await addActivity(formattedValues);
        props.resetDropdown();
    }}>
        {props => {
            return <Form>
                <Box pt={0} pr={3} pb={3} pl={3}>
                    <EmployeeView>
                        <Box>
                            <FormCheckboxItem label={'Confidential'} name={'confidential'}/>
                        </Box>
                    </EmployeeView>
                    <Box pb={3}>
                        <FormTextField variant={'outlined'} name={'title'} label={'Title'}/>
                    </Box>
                    <Box pb={3}>
                        <FormTextField variant={'outlined'} multiline rows={5} name={'message'}
                                       label={'Message'}/>
                    </Box>
                    <Box>
                        <QDropzone label={'File Attachments'} dropzoneId={'General_Activity_Attachment'}/>
                    </Box>
                </Box>
                <Box className={styles.addActivityButton} pb={3}>
                    <Button startIcon={<SvgIcon><IoMdAdd/></SvgIcon>} variant={'contained'}
                            disableElevation type={'submit'}
                            onClick={e => {
                                e.preventDefault();
                                props.submitForm()
                            }}
                            color={'primary'}>
                        <Box pl={1} pr={1}>Add Activity</Box>
                    </Button>
                </Box>
            </Form>;
        }}
    </Formik>;
});

// ======================
// LogTimeForm
// ======================

interface LogTimeFormProps {
    addActivity: (values: NEW_PROJECT_ACTIVITY_FORM_VALUES_TYPE) => void;
    resetDropdown: () => void;

}

export const LogTimeForm: React.FC<LogTimeFormProps> = observer((props) => {

    const {addActivity} = props;

    const styles = useMigrationProjectDetailsStyles(props);

    const initialValues: TIMESHEET_ENTRY_INITIAL_VALUES = {
        title: '',
        message: '',
        timeFrom: new Date(Date.now()),
        timeTo: new Date(add(Date.now(), {minutes: 5})),
        confidential: false,
        attachments: null
    };


    const schema = yup.object({
        title: yup.string().required('Enter a description.').max(1024),
        message: yup.string().notRequired().max(1024),
        timeFrom: yup.date().required('Enter a date.').typeError('Invalid Date.'),
        timeTo: yup.date().when('timeFrom', (timeFrom: any, schema: any) => {
            //check if date is invalid: if invalid, return original schema; if not, return schema with min date time
            if (!isNaN(timeFrom.getTime())) {
                return schema.min(add(timeFrom, {minutes: 5}), 'Time logged must be at least 5 minutes.')
            } else {
                return schema;
            }
        }).required('Enter a date.').typeError('Invalid Date.'),
        confidential: yup.boolean().notRequired().default(false),
        attachments: yup.mixed().nullable()

    });

    return <Formik initialValues={initialValues} validationSchema={schema} onSubmit={async (values, actions) => {

        const formattedAttachments = formatAttachments(values.attachments)

        const submissionValues: NEW_PROJECT_ACTIVITY_FORM_VALUES_TYPE = {
            event: 'TIMESHEET_LOG_EVENT',
            title: values.title,
            message: `Logged time: ${format(values.timeFrom, 'MM/dd/yyyy HH:mm')} to ${format(values.timeTo, 'MM/dd/yyyy HH:mm')}`,
            eventData: {
                from: formatISO(values.timeFrom),
                to: formatISO(values.timeTo)
            },
            confidential: values.confidential,
            attachments: formattedAttachments
        }
        await addActivity(submissionValues);
        props.resetDropdown();

    }}>
        {props => {
            return <Form>
                <Box pt={0} pr={3} pb={3} pl={3}>
                    <EmployeeView>
                        <Box>
                            <FormCheckboxItem label={'Confidential'} name={'confidential'}/>
                        </Box>
                    </EmployeeView>
                    <Box className={styles.formFields}>
                        <Box pr={3} pb={3}>
                            <FormDateTimePicker label={'Time From:'} name={'timeFrom'} maxDate={new Date()}
                            />
                        </Box>
                        <Box pb={3}>
                            <FormDateTimePicker label={'Time To:'} name={'timeTo'}
                                                minDate={props.values.timeFrom}
                                                maxDate={new Date()}
                            />
                        </Box>

                    </Box>
                    <Box pb={3}>
                        <FormTextField variant={'outlined'} name={'title'} label={'Description'}/>
                    </Box>
                    <Box pb={3}>
                        <QDropzone label={'File Attachments'} dropzoneId={'Time_Log_Attachment'}/>
                    </Box>
                </Box>
                <Box className={styles.addActivityButton} pb={3}>
                    <Button startIcon={<SvgIcon><IoMdAdd/></SvgIcon>} variant={'contained'}
                            disableElevation type={'submit'}
                            onClick={e => {
                                e.preventDefault();
                                props.submitForm()
                            }}
                            color={'primary'}>
                        <Box pl={1} pr={1}>Add Activity</Box>
                    </Button>
                </Box>
            </Form>;
        }}
    </Formik>;
})


export const formatDateString = (isoString: string): string => {
    return formatISO9075(parseISO((isoString)));
};
export const formatRelativeDateString = (isoString: string, base: Date = null): string => {
    base = base || new Date();
    return formatRelative(parseISO((isoString)), base);
};

export const formatAttachments = (attachments: Array<DROPZONE_FILE_TYPE> | null) => {
    if (!attachments) {
        return []
    } else {
        return attachments.map((a: DROPZONE_FILE_TYPE): PROJECT_ACTIVITY_ATTACHMENT_TYPE => {
            return {
                fileName: a.name,
                fileType: a.type,
                fileSize: a.size,
                uuid: a.uuid,
            }
        })
    }


}