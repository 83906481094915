import { observable } from "mobx";


export interface POPOVER_TYPE {
    id: string,
    open: boolean,
    anchor: any
}

export class PopoverState {
    @observable popover: POPOVER_TYPE = {
        id: '',
        open: false,
        anchor: ''
    };

    constructor(popoverId: string){
        this.popover.id = popoverId;
    }

    togglePopover(){
        this.popover.open = !this.popover.open
    }

    setAnchor(value: any){
        this.popover.anchor = value;
    }

    handleClick(e: React.MouseEvent){
        this.togglePopover();
        this.setAnchor(e.currentTarget)

    }

    handleClose(){
        this.togglePopover();
        this.setAnchor(null)
    }

}