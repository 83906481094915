import {observable} from 'mobx';
import {
    PROJECT_LIST_TYPE,
    CDS_REP_LIST_TYPE, PARTNER_REP_LIST_TYPE, NEW_PROJECT_FORM_VALUES_TYPE, CONTACT_TYPE, EMPLOYEE_TYPE
} from "./MigrationProjectTypes";
import {DetailsStatesMap} from "../core/data/DetailsStatesMap";
import {MigrationProjectDetailsState} from './migrationProjectDetails/MigrationProjectDetailsState';
import {ServerAPI} from "../core/ServerAPI";
import {ServerData} from "../core/data/ServerData";
import {APP_ROUTES} from "../app/AppRoutes";
import {DialogService} from '../core/dialog/DialogService';
import {PopoverState} from "../../common/popover/PopoverService";
import {Box} from "@material-ui/core";
import {AutocompleteState} from "../core/autocomplete/AutocompleteState";
import {ProgressService} from '../core/progress/ProgressService';
import {TableState} from "../../common/table/TableState";
import axios from "axios";


export class MigrationProjectService {

    TABLE_PAGE_SIZE = 10

    dialogService: DialogService;
    apiPrefix: string = APP_ROUTES.MIGRATION;
    api: ServerAPI;
    progressService: ProgressService;

    @observable tableData = new ServerData<PROJECT_LIST_TYPE>();

    @observable proposedProjectsTableData = new ServerData<PROJECT_LIST_TYPE>();

    @observable cdsReps = new ServerData<CDS_REP_LIST_TYPE>();
    @observable partnerReps = new ServerData<PARTNER_REP_LIST_TYPE>();

    @observable tab: number = 0;

    @observable approvedProjectsTableState = new TableState('approvedProjects', this.TABLE_PAGE_SIZE, {
        engineer: null,
        projectStatus: null,
        projectName: null
    });
    @observable proposedProjectsTableState = new TableState('proposedProjects', this.TABLE_PAGE_SIZE);


    @observable detailsState: MigrationProjectDetailsState = null;

    constructor(dialogService: DialogService, api: ServerAPI, progressService: ProgressService) {
        this.dialogService = dialogService;
        this.api = api;
        this.progressService = progressService
    }

    @observable employeeAutocompleteState: AutocompleteState<EMPLOYEE_TYPE> = new AutocompleteState<EMPLOYEE_TYPE>('employeeReps', () => this.fetchEmployeeReps())
    @observable contactAutocompleteState: AutocompleteState<CONTACT_TYPE> = new AutocompleteState<CONTACT_TYPE>('contactReps', () => this.fetchContactReps())

    @observable downloadURL: string;

    @observable activityAttachmentURL: string;

    @observable popoverStates = new Map();

    switchTabs(tab: number) {
        this.tab = tab;
    }

    @observable openStates: Array<boolean> = [true];

    addOpenState(index: number){
        this.openStates[index] = true;
    }

    openAll(){
        this.openStates.forEach((s,i)=> {
            this.openStates[i] = true;
        })
    }

    get openStatesLength(){
        return this.openStates.length
    }

    deleteOpenState(index: number){
        this.openStates.splice(index, 1);
    }

    setOpen(index: number){
        this.openStates[index] = true;
    }

    setClosed(index: number){
        this.openStates[index] = false;

    }

    toggleOpenState(index: number){
        const currentState = this.openStates[index];
        this.openStates[index] = !currentState;
    }

    async downloadQuestionnaireFiles(uuid: string, fileName: string, fileType: string) {
        let data = {file_uuid: uuid};
        const result = await this.dialogService.catchAndAlertError(axios.post('https://www.migration.fyi/api/v1/common/file/object-download-requests', data))
        if (result) {
            const url = result.data.download_url;
            const file = await (axios.get(url, {responseType:'arraybuffer'}));
            if (file){
                const blob = new Blob([file.data], {type: fileType})
                const downloadLink = URL.createObjectURL(blob);
                // Create an invisible A element
                const a = document.createElement("a");
                a.style.display = "none";
                document.body.appendChild(a);

                // Set the HREF to a Blob representation of the data to be downloaded
                a.href = downloadLink;

                // Use download attribute to set desired file name
                a.setAttribute("download", fileName);

                // Trigger the download by simulating click
                a.click();

                // Cleanup
                window.URL.revokeObjectURL(a.href);
                document.body.removeChild(a)
            }

        }
    }

    async downloadActivityAttachment(uuid: string, fileName: string) {
        const result = await this.dialogService.catchAndAlertError(this.api.GET(`/files/${uuid}`,
            {
                params:
                    {
                        fileName: fileName,
                        download: true
                    }
            }))
        if (result) {
            //console.log(result)
            this.activityAttachmentURL = result.downloadUrl;
            window.open(this.activityAttachmentURL)
        }
    }

    async fetchEmployeeReps() {
        return await this.employeeAutocompleteState.data.loadDataFromAPI(this.api.GET('users/employees'))
    }

    async fetchContactReps() {
        return await this.contactAutocompleteState.data.loadDataFromAPI(this.api.GET('users/contacts'))
    }

    async fetchProjects() {
        const params = new URLSearchParams()
        params.append('page', `${this.approvedProjectsTableState.page+1}`);
        params.append('pageSize',`${this.TABLE_PAGE_SIZE}`);
        if (!!this.approvedProjectsTableState.filters.projectStatus){
            for (let status in this.approvedProjectsTableState.filters.projectStatus){
                if (this.approvedProjectsTableState.filters.projectStatus[status] === true){
                    params.append('projectStatuses',`${status}`)
                }
            }
        }
        if (!!this.approvedProjectsTableState.filters.engineer){
            params.append('person', `${this.approvedProjectsTableState.filters.engineer}`)
        }

        if (!!this.approvedProjectsTableState.filters.projectName){
            params.append('projectName', `${this.approvedProjectsTableState.filters.projectName}`)
        }

        return await this.tableData.loadDataFromAPI(this.api.GET(this.apiPrefix, {
            params: params
        }))
    }

    async fetchProposedProjects() {
        return await this.proposedProjectsTableData.loadDataFromAPI(this.api.GET(APP_ROUTES.MIGRATION_PROPOSALS, {
            params: {
                page: this.proposedProjectsTableState.page + 1,
                pageSize: this.TABLE_PAGE_SIZE
            }
        }))
    }

    async approveProposedProject(projectId: string) {
        const confirmed = await this.dialogService.addConfirmDialog('Are you sure you want to approve this project?', null, false)
        if (confirmed) {
            await this.progressService.track(this.api.POST(`${this.apiPrefix}/${projectId}/approval`, {}));
            await this.dialogService.addAlertDialog('Success!', 'Project approved.');

        }
        return confirmed;
    }

    async deleteProject(projectId: string) {
        let doubleConfirmed: unknown = false;
        const confirmed = await this.dialogService.addConfirmDialog('Are you sure you want to delete this project? This action cannot be undone.', null, false);
        if (confirmed) {
            doubleConfirmed = await this.dialogService.addConfirmDialog('Delete project permanently.', null, false);
            if (doubleConfirmed) {
                await this.dialogService.catchAndAlertError(this.api.DELETE(`${this.apiPrefix}/${projectId}`));
                await this.dialogService.addAlertDialog('Success!', 'Project deleted.')
                await this.fetchProposedProjects()
            }

        }
        return doubleConfirmed;
    }

    initProjectDetailsState(projectId: any) {

        this.detailsState = new MigrationProjectDetailsState(projectId, this.api, this.dialogService, this.progressService);
        return this.detailsState;
    }

    async addNewProject(data: NEW_PROJECT_FORM_VALUES_TYPE) {
        await this.dialogService.catchAndAlertError(this.api.POST(this.apiPrefix, data))
        await this.fetchProposedProjects()
        this.switchTabs(1)

    }

    setTab(tab: number) {
        this.tab = tab;
    }


}



