import React from "react";
import {observer} from "mobx-react-lite";
import {Box, Chip, makeStyles, Theme} from "@material-ui/core";
import {FaLongArrowAltRight} from "react-icons/all";
import Typography from "@material-ui/core/Typography";

// ======================
// Path
// ======================

const usePathStyles=makeStyles((t: Theme)=>({
    path: {
        display: 'flex',
        alignItems: 'center'
    }
}))

interface PathProps{
    from: string,
    to: string,
    string?: boolean
    chipSize?: "medium" | "small";
}

export const Path: React.FC<PathProps> = observer((props)=>{
    const styles = usePathStyles(props);

    if (props.string){
        return <Box className={styles.path}>
            <Typography variant={'caption'}><strong>{props.from}</strong></Typography>
            <FaLongArrowAltRight/>
            <Typography variant={'caption'}><strong>{props.to}</strong></Typography>
        </Box>
    }else {
        return <Box className={styles.path}>
            <Chip label={props.from} size={props.chipSize}/>
            <FaLongArrowAltRight/>
            <Chip label={props.to} size={props.chipSize}/>
        </Box>
    }

})