import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {
    Box,
    Divider,
    Tabs, Tab, ListItem, ListItemAvatar, Avatar, Chip, SvgIcon, Grid, Link, Button,
} from "@material-ui/core";
import {useParams} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {useAppServices} from "../../app/AppStates";
import {useInitData, useServerData} from "../../core/data/DataLoaderHooks";
import {MigrationProjectDetailsState} from "./MigrationProjectDetailsState";
import {BackButton} from "../../../common/buttons/commonButtons";
import {useIsDesktop, useIsMobile} from "../../layout/MainLayout";
import {PEOPLE_TYPE, PROJECT_TYPE} from "../MigrationProjectTypes";
import {ProjectChecklist, PsOnlyProjectChecklist} from "./MigrationProjectProgressTracker";
//@ts-ignore
import {useMigrationProjectDetailsStyles} from './MigrationProjectStyles';
import {ProjectActivity} from "./MigrationProjectActivity";
import xbytes, {ParsedUnit} from "xbytes";
import {PopoverChip, UserListPopover} from "../../../common/popover/PopoverButton";
import {Path} from '../../../common/path/Path';
import {PageNotFound} from "../../../common/error/404";
import {LoadingScreen} from "../../../common/splash/SplashScreen";
import {ProjectStage, PsOnlyProjectStage} from "./MigrationProjectStage";
import {ContactView, EmployeeView} from "../../auth/EmployeeView";
import {MigrationProjectSettings} from "./MigrationProjectSettings";
import {MuiTabContent} from "../../core/tab/Tabs";
import {ProjectTimesheet} from "./MigrationProjectTimesheet";
import {renderDealType, shortenDate, StatusIcon} from "../MigrationProjectCommon";
import {APP_ROUTES} from "../../app/AppRoutes";
import {
    AiOutlineProject,
    AiOutlineSetting,
    FaLongArrowAltRight,
    FiActivity, FiExternalLink,
    IoIosSettings,
    IoMdCalendar, MdLocalShipping
} from "react-icons/all";
import {ProposedMigrationDetailsPage} from "./ProposedMigrationProjectDetailsView";
import {MigrationProjectShipmentsView} from "./MigrationProjectShipmentsView";


// ======================
// MigrationProjectDetailsView
// ======================

interface MigrationProjectDetailsViewProps {

}

export const MigrationProjectDetailsView: React.FC<MigrationProjectDetailsViewProps> = observer((props) => {

    const {projectId} = useParams();

    const {migrationProjectService} = useAppServices();

    useEffect(
        () => {
            migrationProjectService.initProjectDetailsState(projectId)
        },
        [projectId]
    )

    if (!!migrationProjectService.detailsState) {
        return <MigrationProjectDetails key={projectId}/>
    } else {
        return null;
    }
})

// ======================
// MigrationProjectDetails
// ======================

interface MigrationProjectDetailsProps {

}

export const MigrationProjectDetails: React.FC<MigrationProjectDetailsProps> = observer((props) => {

    const {migrationProjectService} = useAppServices();

    const styles = useMigrationProjectDetailsStyles(props);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        migrationProjectService.detailsState.setTab(newValue);
    };

    const isDesktop = useIsDesktop();
    const isMobile = useIsMobile();

    useInitData({
        poll: () => migrationProjectService.detailsState.fetchProjectInfo(),
        pollInterval: 1
    })

    return useServerData(migrationProjectService.detailsState.project, <LoadingScreen/>, data => {

        if (data.project.status === 'PROPOSED') {
            return <ProposedMigrationDetailsPage data={data} detailsState={migrationProjectService.detailsState}/>
        } else {
            return <>
                <Box pb={4}>
                    <Box pb={1}>
                        <BackButton backPageName={'All Projects'} backPagePath={APP_ROUTES.MIGRATION}/>
                    </Box>
                    {isDesktop && <ProjectTitleBar data={data} detailsState={migrationProjectService.detailsState}/>}
                    {!isDesktop &&
                    <MobileProjectTitleBar data={data} detailsState={migrationProjectService.detailsState}/>}

                    <Divider/>
                    <Box pt={4} width={'100%'}>
                        <EmployeeView>
                            <Tabs value={migrationProjectService.detailsState.tab} onChange={handleTabChange}
                                  centered={true} variant={'fullWidth'}>
                                <Tab label={isDesktop ?
                                    <Box className={styles.tabIcons}><SvgIcon><AiOutlineProject/></SvgIcon><Typography
                                        variant={'caption'}>Phases</Typography></Box> :
                                    <SvgIcon><AiOutlineProject/></SvgIcon>}/>
                                <Tab label={isDesktop ?
                                    <Box className={styles.tabIcons}><SvgIcon><FiActivity/></SvgIcon><Typography
                                        variant={'caption'}>Activity</Typography></Box> :
                                    <SvgIcon><FiActivity/></SvgIcon>}/>
                                <Tab label={isDesktop ?
                                    <Box className={styles.tabIcons}><SvgIcon><IoMdCalendar/></SvgIcon><Typography
                                        variant={'caption'}>Timesheet</Typography></Box> :
                                    <SvgIcon><IoMdCalendar/></SvgIcon>}/>
                                <Tab label={isDesktop ?
                                    <Box className={styles.tabIcons}><SvgIcon><MdLocalShipping/></SvgIcon><Typography
                                        variant={'caption'}>Shipments</Typography></Box> :
                                    <SvgIcon><MdLocalShipping/></SvgIcon>}/>
                                <Tab label={isDesktop ?
                                    <Box className={styles.tabIcons}><SvgIcon><AiOutlineSetting/></SvgIcon><Typography
                                        variant={'caption'}>Details</Typography></Box> :
                                    <SvgIcon><AiOutlineSetting/></SvgIcon>}/>
                            </Tabs>
                        </EmployeeView>
                        <ContactView>
                            <Tabs value={migrationProjectService.detailsState.tab} onChange={handleTabChange}
                                  centered={true} variant={'fullWidth'}>
                                <Tab label={isDesktop ?
                                    <Box className={styles.tabIcons}><SvgIcon><AiOutlineProject/></SvgIcon><Typography
                                        variant={'caption'}>Phases</Typography></Box> :
                                    <SvgIcon><AiOutlineProject/></SvgIcon>}/>
                                <Tab label={isDesktop ?
                                    <Box className={styles.tabIcons}><SvgIcon><FiActivity/></SvgIcon><Typography
                                        variant={'caption'}>Activity</Typography></Box> :
                                    <SvgIcon><FiActivity/></SvgIcon>}/>
                                <Tab label={isDesktop ?
                                    <Box className={styles.tabIcons}><SvgIcon><IoMdCalendar/></SvgIcon><Typography
                                        variant={'caption'}>Timesheet</Typography></Box> :
                                    <SvgIcon><IoMdCalendar/></SvgIcon>}/>
                                <Tab label={isDesktop ?
                                    <Box className={styles.tabIcons}><SvgIcon><MdLocalShipping/></SvgIcon><Typography
                                        variant={'caption'}>Shipments</Typography></Box> :
                                    <SvgIcon><MdLocalShipping/></SvgIcon>}/>

                            </Tabs>
                        </ContactView>
                    </Box>
                    <MuiTabContent value={migrationProjectService.detailsState.tab} index={0}>
                        {data.psOnly &&
                            <PsOnlyProjectStage data={data}/>
                        }
                        {
                            !data.psOnly &&
                            <ProjectStage data={data}/>
                        }
                        {data.psOnly &&
                            <PsOnlyProjectChecklist detailsState={migrationProjectService.detailsState}/>
                        }
                        {
                         !data.psOnly &&
                            <ProjectChecklist detailsState={migrationProjectService.detailsState}/>
                        }
                    </MuiTabContent>
                    <MuiTabContent value={migrationProjectService.detailsState.tab} index={1}>
                        <ProjectActivity detailsState={migrationProjectService.detailsState}/>
                    </MuiTabContent>
                    <MuiTabContent value={migrationProjectService.detailsState.tab} index={2}>
                        <ProjectTimesheet detailsState={migrationProjectService.detailsState}/>
                    </MuiTabContent>
                    <MuiTabContent value={migrationProjectService.detailsState.tab} index={3}>
                        <MigrationProjectShipmentsView detailsState={migrationProjectService.detailsState}/>
                    </MuiTabContent>
                    <MuiTabContent value={migrationProjectService.detailsState.tab} index={4}>
                        <MigrationProjectSettings detailsState={migrationProjectService.detailsState} data={data}/>
                    </MuiTabContent>

                </Box>
            </>
        }


    })

})

// ======================
// ProjectTitleBar
// ======================

interface ProjectTitleBarProps {
    data: PROJECT_TYPE,
    detailsState: MigrationProjectDetailsState
    proposal?: boolean
}

export const ProjectTitleBar: React.FC<ProjectTitleBarProps> = observer((props) => {
    const styles = useMigrationProjectDetailsStyles(props);
    const {data, detailsState, proposal} = props;
    return <>
        <Box pb={2} className={styles.projectTitleBar}>
            <Box>
                <Typography variant={'overline'}
                            className={styles.projectCodeOverline}>ID: {data.project.projectCode}</Typography>
                <Typography variant={'h4'} className={styles.title}>{data.project.projectName}</Typography>
            </Box>
            <Box>
                <StatusIcon status={data.project.status}/>
            </Box>

        </Box>
        <Box className={styles.projectTitleBar} pb={2}>


            <EmployeeView>
                <Box>
                    <Typography variant={'overline'}>Deal Type</Typography>
                    <Box>
                        <Chip label={renderDealType(data.dealType, 'caption')}/>

                    </Box>
                </Box>
            </EmployeeView>
            <Box>
                <Typography variant={'overline'}>Data</Typography>
                <Box>
                    <Chip label={xbytes(data.migrationDataAmount as number)}/>
                </Box>
                {/*<Typography variant={'h5'} className={styles.number}>{xbytes(data.migrationDataAmount as number)}</Typography>*/}
            </Box>
            <Box>
                <Typography variant={'overline'}>Est. Time Frame</Typography>
                <Path from={data.project.projectTimeFrameFrom} to={data.project.projectTimeFrameTo}/>
            </Box>
            <Box>
                <Typography variant={'overline'}>Project Dates</Typography>
                <Path from={data.project.projectStartDate || 'not set'} to={data.project.projectEndDate || 'not set'}/>
            </Box>

            <Box>
                <Typography variant={'overline'}>Migration Path</Typography>
                <Path from={formatMigrationPath(data.migrationSource)}
                      to={formatMigrationPath(data.migrationDestination)}/>
            </Box>
            {!proposal &&
            <Box>
                <Typography variant={'overline'}>Reps</Typography>
                <UserListPopover popoverState={detailsState.repList} label={'Rep List'} userList={data.project.people}
                                 children={renderRepList(data.project.people)}/>
            </Box>
            }
        </Box>
        <Box pb={3}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} lg={9}>
                    <Box>
                        <Typography variant={'body1'}>{data.project.projectDescription}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
                        <MattermostLink link={data.project.mmChannel}/>
                    </Box>
                </Grid>
            </Grid>

        </Box>


    </>
})

// ======================
// MobileProjectTitleBar
// ======================

interface MobileProjectTitleBarProps {
    data: PROJECT_TYPE,
    detailsState: MigrationProjectDetailsState

}

export const MobileProjectTitleBar: React.FC<MobileProjectTitleBarProps> = observer((props) => {
    const styles = useMigrationProjectDetailsStyles(props);
    const {data, detailsState} = props;


    return <>
        <Box pb={2}>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <Typography variant={'h4'} className={styles.number}>{data.project.projectName}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box display={'flex'} justifyContent={'flex-end'}>
                        <StatusIcon status={data.project.status}/>

                    </Box>
                </Grid>

            </Grid>
        </Box>
        <Box className={styles.mobileProjectTitleBar}>
            <Box pb={2}>
                <Typography variant={'body1'}
                            className={styles.mobileRepChips}>
                    <PopoverChip popoverState={detailsState.timeFramePopover}
                                 label={'More Info...'}
                                 children={<>
                                     <Box pb={2}>
                                         <Typography variant={'overline'} className={styles.moreInfoTitles}>
                                             Project ID
                                         </Typography>
                                         <br/>
                                         <Chip label={data.project.projectCode}/>
                                     </Box>
                                     <Box pb={2}>
                                         <Typography variant={'overline'} className={styles.moreInfoTitles}>
                                             Est. Time Frame
                                         </Typography>
                                         <Path from={data.project.projectTimeFrameFrom}
                                               to={data.project.projectTimeFrameTo}/>
                                     </Box>
                                     <Box pb={2}>
                                         <Typography variant={'overline'} className={styles.moreInfoTitles}>
                                             Actual Project Dates
                                         </Typography>
                                         <Path from={data.project.projectStartDate || 'not set'}
                                               to={data.project.projectEndDate || 'not set'}/>
                                     </Box>
                                     <Box pb={2}>
                                         <Box>
                                             <Typography variant={'overline'} className={styles.moreInfoTitles}>
                                                 Source
                                             </Typography>
                                         </Box>

                                         <Box className={styles.chipBox}>

                                             {formatMigrationPath(data.migrationSource)}

                                         </Box>
                                     </Box>
                                     <Box pb={2}>
                                         <Box>
                                             <Typography variant={'overline'} className={styles.moreInfoTitles}>
                                                 Destination
                                             </Typography>
                                         </Box>

                                         <Box className={styles.chipBox}>
                                             {formatMigrationPath(data.migrationDestination)}
                                         </Box>
                                     </Box>
                                     <EmployeeView>
                                         <Box pb={2}>
                                             <Typography variant={'overline'} className={styles.moreInfoTitles}>
                                                 Deal Type
                                             </Typography>
                                             <br/>
                                             <Chip label={renderDealType(data.dealType, 'caption')}/>
                                         </Box>
                                     </EmployeeView>
                                 </>}
                    />
                </Typography>
            </Box>
            <Box pb={2}>
                <UserListPopover popoverState={detailsState.repList} label={'Rep List'}
                                 userList={data.project.people} children={renderRepList(data.project.people)}/>
            </Box>
        </Box>
        <Box pb={1}>
            <Typography variant={'body1'}>{data.project.projectDescription}</Typography>
        </Box>
        <Box pb={2} display={'flex'} justifyContent={'flex-start'}>
            <MattermostLink link={data.project.mmChannel}/>
        </Box>

    </>
})

// ======================
// MattermostLink
// ======================

interface MattermostLinkProps {
    link: string;
}

export const MattermostLink: React.FC<MattermostLinkProps> = observer((props) => {
    const {link} = props;

    const url = `https://messenger.cdsi.us.com/ps/channels/${link}`
    if (!!link) {
        return <EmployeeView>
            <Button color={'primary'} endIcon={<FiExternalLink/>} href={url} target={'_blank'}>
                Mattermost Channel
            </Button>
            {/*<Link type={'button'}  href={url} target={'_blank'} color={'primary'}>Mattermost Channel</Link>*/}
        </EmployeeView>
    }
    ;
    return null;

})


export const renderRepList = (data: Array<PEOPLE_TYPE>) => {
    if (data.length > 0) {
        return data.map(rep =>
            <>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar src={rep.pictureUrl}/>
                    </ListItemAvatar>
                    {rep.fullName}
                </ListItem>
            </>
        )
    } else {
        return <Typography variant={'body1'}>No reps assigned.</Typography>
    }

}


export const formatMigrationPath = (path: string) => {
    if (path.includes('/')) {
        return path.replace(" /", ",");
    } else {
        return path;
    }
}