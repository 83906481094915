import React from 'react';
import {observer} from "mobx-react-lite";
import {useField} from "formik";
import {useAppServices} from "../../app/AppStates";
import {DROPZONE_FILE_TYPE} from "./dropzone_types";
import {Box, Card, Divider, IconButton, LinearProgress, SvgIcon, Theme, Tooltip, Typography} from "@material-ui/core";
import Dropzone from "react-dropzone";
import {FaFileAlt, GoCloudUpload, IoIosCloseCircle, IoMdHelpCircleOutline} from "react-icons/all";
import xbytes from "xbytes";
import {DropzoneState} from "./DropzoneService";
import {makeStyles} from "@material-ui/core/styles";


// ======================
// QDropzone
// ======================

const useDropzoneStyles = makeStyles((t:Theme)=>({
    dropzone: {
        outline: 'none',
    }
}))

interface QDropzoneProps {
    label: string,
    dropzoneId: string,
    hint?: string,
}

export const QDropzone: React.FC<QDropzoneProps> = observer((props) => {

    const styles = useDropzoneStyles(props);

    const {label, dropzoneId, hint} = props;

    const {dropzoneService} = useAppServices();

    React.useEffect(() => {
        dropzoneService.initDropzoneState(dropzoneId);

        return () => {
            dropzoneService.deInitDropzoneState(dropzoneId)
        }
    }, [])

    const dropzoneState = dropzoneService.getDropzoneState(dropzoneId)

    const [field, meta, helpers] = useField('attachments');

    const {setValue} = helpers;


    const handleDrop = async (acceptedFiles: any) => {
        dropzoneState.addFile(acceptedFiles);
        await dropzoneState.uploadFiles()
        if (dropzoneState.files.length > 1) {
            setValue(dropzoneState.files)
        } else {
            setValue([dropzoneState.files[0]])
        }
    }

    const handleDelete = (file: DROPZONE_FILE_TYPE) => {
        dropzoneState.deleteFile(file);
        if (dropzoneState.files.length < 1) {
            setValue(null)
        } else {
            setValue(dropzoneState.files)
        }
    }

    if (!!dropzoneState) {
        return <Box>
            <Dropzone onDrop={handleDrop} multiple>
                {({getRootProps, getInputProps, acceptedFiles}) => {
                    return <Box width={'100%'}>

                        <Box width={'100%'} display={'flex'} alignItems={'center'}>
                            {hint &&
                            <Box pr={1}>
                                <Tooltip title={hint}>
                                    <SvgIcon htmlColor={'#888888'}>
                                        <IoMdHelpCircleOutline/>
                                    </SvgIcon>
                                </Tooltip>
                            </Box>
                            }
                            <Box width={'100%'}>
                                <Card variant={'outlined'}  {...getRootProps()} className={styles.dropzone}>
                                    <Box display={'flex'} alignItems={'center'} p={2}>
                                        <input {...getInputProps()}/>
                                        <Box pr={2}>
                                            <SvgIcon>
                                                <GoCloudUpload/>
                                            </SvgIcon>
                                        </Box>
                                        <Box>
                                            <Typography variant={'body1'}>{label}</Typography>
                                            <Typography variant={'body1'} color={'textSecondary'}>Drop file or click to
                                                select.</Typography>
                                        </Box>
                                    </Box>
                                    <Box width={'100%'}>
                                        {dropzoneState.files && dropzoneState.files.map((file: DROPZONE_FILE_TYPE) => {
                                            return <DropzoneFile dropzoneState={dropzoneState} file={file}
                                                                 handleDelete={handleDelete}/>
                                        })}
                                    </Box>
                                </Card>

                            </Box>
                        </Box>
                    </Box>
                }}
            </Dropzone>

        </Box>
    }

})

// ======================
// DropzoneFile
// ======================

interface DropzoneFileProps {
    dropzoneState: DropzoneState;
    file: DROPZONE_FILE_TYPE,
    handleDelete: (file: DROPZONE_FILE_TYPE) => void;
}

export const DropzoneFile: React.FC<DropzoneFileProps> = observer((props) => {
    const {dropzoneState, file, handleDelete} = props;
    return (<>
            <Divider/>

            <Box p={2} display={'flex'} alignItems={'center'}>
                <SvgIcon>
                    <FaFileAlt/>
                </SvgIcon>
                <Box pl={2} flexGrow={1}>
                    <Typography variant={'body1'}>{file.name}</Typography>
                    <Typography variant={'body2'}>{`${xbytes(file.size)} (${file.type})`}</Typography>
                </Box>
                <Box>
                    <IconButton role={'button'}
                        onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(file)
                    }}>
                        <SvgIcon>
                            <IoIosCloseCircle/>
                        </SvgIcon>
                    </IconButton>
                </Box>
            </Box>
            <Box pl={2} pb={2} pr={2} width={'100%'} display={'flex'} justifyContent={'flex-start'}>
                <Typography variant={'body2'}
                            color={'textSecondary'}>{xbytes(dropzoneState.uploadProgress.loaded)}/{xbytes(dropzoneState.uploadProgress.total)} ({dropzoneState.uploadProgress.percent}%)</Typography>
            </Box>
            <LinearProgress variant={'determinate'} value={dropzoneState.uploadProgress.percent}/>
        </>
    )
})