import React from 'react';
import { observer } from 'mobx-react-lite';
import {useAppServices} from "../app/AppStates";

// ======================
// EmployeeView
// ======================

interface EmployeeViewProps{
}

export const EmployeeView: React.FC<EmployeeViewProps> = observer((props)=> {
    const {authService} = useAppServices();
    return <>{authService.currentUser.data.userType === 'Employee' &&
    props.children
    }
    </>
})

// ======================
// ContactView
// ======================

interface ContactViewProps{
}

export const ContactView: React.FC<ContactViewProps> = observer((props)=>{
    const { authService } = useAppServices();
    return <>{ authService.currentUser.data.userType === 'Contact' &&
    props.children
    }
    </>

})