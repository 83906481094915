import {computed, observable} from "mobx";
import {
    ACTIVITIES_FILTERS_TYPE,
    MIGRATION_PROJECT_PHASE,
    MIGRATION_PROJECT_QUESTIONNAIRE_TYPE,
    NEW_PROJECT_ACTIVITY_FORM_VALUES_TYPE,
    NEW_PROJECT_FORM_VALUES_TYPE,
    PHASE_VALUES_TYPE,
    PROJECT_ACTIVITIES_TYPE,
    PROJECT_ACTIVITY_TYPE,
    PROJECT_TYPE,
    ProjectActivityEventIDs,
    SHIPMENT_INFO_TYPE,
    SHIPMENT_LIST_TYPE,
    SHIPMENT_STATUS
} from "../MigrationProjectTypes";
import {ServerData} from "../../core/data/ServerData";
import {ServerAPI} from "../../core/ServerAPI";
import {APP_ROUTES} from "../../app/AppRoutes";
import {PopoverState} from "../../../common/popover/PopoverService";
import {DialogService} from "../../core/dialog/DialogService";
import {ProgressService} from "../../core/progress/ProgressService";
import {TableState} from "../../../common/table/TableState";
import {AutocompleteState} from "../../core/autocomplete/AutocompleteState";


export class MigrationProjectDetailsState {

    TABLE_PAGE_SIZE = 1000

    private api: ServerAPI;
    projectId: string;
    apiPrefix: string;
    testAPIPrefix: string;
    dialogService: DialogService;
    progressService: ProgressService;

    @observable error: any = null;


    constructor(projectId: string, api: ServerAPI, dialogService: DialogService, progressService: ProgressService) {
        this.projectId = projectId;
        this.api = api;
        this.apiPrefix = `${APP_ROUTES.MIGRATION}/${this.projectId}`;
        this.testAPIPrefix = `${APP_ROUTES.MIGRATION}/${this.projectId}`;
        this.dialogService = dialogService;
        this.api.interceptError(404, async err => {
            console.log(err)
            this.error = err;
        });
        this.progressService = progressService
    }

    @observable project = new ServerData<PROJECT_TYPE>();
    @observable proposedProject = new ServerData<PROJECT_TYPE>();
    @observable tab: number = 0;
    @observable settingsTab: number = 0;
    @observable activities = new ServerData<PROJECT_ACTIVITIES_TYPE>();
    @observable timesheet = new ServerData<PROJECT_ACTIVITIES_TYPE>();

    @observable questionnaire = new ServerData<MIGRATION_PROJECT_QUESTIONNAIRE_TYPE>();
    @observable settingsMenuPopover = new PopoverState('settingsMenu')

    @observable repList = new PopoverState('repList');
    @observable timeFramePopover = new PopoverState('timeFrame');
    @observable migrationPathPopover = new PopoverState('migrationPath');

    @observable phaseEditState: PHASE_VALUES_TYPE = null;
    @observable phaseBeingEdited: MIGRATION_PROJECT_PHASE = null;

    @observable activityType: string = '';
    @observable activitiesTableState = new TableState<PROJECT_ACTIVITY_TYPE>('activities', this.TABLE_PAGE_SIZE)

    @observable employeeTimesheetAutocompleteState: AutocompleteState<PROJECT_ACTIVITIES_TYPE> = new AutocompleteState<PROJECT_ACTIVITIES_TYPE>('employeeTimesheet', () => this.fetchTimesheetPeopleAutocomplete())

    @observable timesheetTableState = new TableState<ACTIVITIES_FILTERS_TYPE>('timesheet', this.TABLE_PAGE_SIZE, {
        author: null,
        timesheetEntryFrom: null,
        timesheetEntryTo: null
    }, [this.employeeTimesheetAutocompleteState]);

    @observable downloadURL: string = '';

    @observable questionnaireMenuPopoverState = new PopoverState('questionnaireMenu');

    @observable shipmentsList = new ServerData<SHIPMENT_LIST_TYPE>();
    @observable shipmentsTableState = new TableState<SHIPMENT_INFO_TYPE>('shipments', this.TABLE_PAGE_SIZE);

    async fetchProjectInfo() {
        return await this.project.loadDataFromAPI(this.api.GET(this.apiPrefix))
    }

    async fetchTimesheetPeopleAutocomplete() {
        return await this.employeeTimesheetAutocompleteState.data.loadDataFromAPI(this.api.GET(`${this.apiPrefix}/activities`, {params: {event: ProjectActivityEventIDs.TIMESHEET_LOG_EVENT}}))
    }

    async updateProjectInfo(values: NEW_PROJECT_FORM_VALUES_TYPE, successMessage: boolean = true) {
        const response = await this.dialogService.catchAndAlertError(this.api.PUT(this.apiPrefix, values));
        if (response && successMessage) {
            await this.dialogService.addAlertDialog('Success!', 'Project saved.')
        }
    }

    async fetchQuestionnaireInfo() {
        return await this.questionnaire.loadDataFromAPI(this.api.GET(`${this.apiPrefix}/questionnaire`))
    }

    async updateQuestionnaireInfo(values: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE) {
        await this.dialogService.catchAndAlertError(this.api.PUT(`${this.apiPrefix}/questionnaire`, values));
        await this.dialogService.addAlertDialog('Success!', 'Questionnaire Information Updated.')
    }

    getValueError() {
        return this.phaseEditState ? !!this.phaseEditState[this.phaseBeingEdited].currentValue.toString().match(/\D/) || this.phaseEditState[this.phaseBeingEdited].currentValue.toString() === '' : false;
    }

    getTotalError() {
        return this.phaseEditState ? !!this.phaseEditState[this.phaseBeingEdited].totalValue.toString().match(/\D/) || this.phaseEditState[this.phaseBeingEdited].totalValue.toString() === '' : false;
    }

    getUnitError() {
        return this.phaseEditState ? !this.phaseEditState[this.phaseBeingEdited].unit || this.phaseEditState[this.phaseBeingEdited].unit.toString() === '' : false;
    }

    setTab(tab: number) {
        this.tab = tab;
    }

    setSettingsTab(tab: number) {
        this.settingsTab = tab;
    }

    editPhase(phase: MIGRATION_PROJECT_PHASE) {
        //save copy of values on edit
        if (this.phaseValues) {
            this.phaseEditState = this.phaseValues;
        } else {
            this.phaseEditState = this.getInitialProgress();
        }
        this.phaseBeingEdited = phase;
    }

    savePhase(phase: MIGRATION_PROJECT_PHASE) {
        return async (values: PHASE_VALUES_TYPE) => {
            //get new values before save
            let newValues;
            if (this.phaseValues) {
                newValues = this.phaseValues;
            } else {
                newValues = this.getInitialProgress();
            }
            newValues[phase] = values[phase]
            await this.setPhaseValues(newValues)
            await this.fetchProjectInfo()
            this.phaseEditState = null;
            this.phaseBeingEdited = null;
        }
    }

    @computed get phaseValues() {
        return this.project.ready ? this.project.data.progress : null;
    }

    async fetchProjectActivities() {
        return await this.activities.loadDataFromAPI(this.api.GET(`${this.apiPrefix}/activities`, {
            params: {
                page: this.timesheetTableState.page + 1,
                pageSize: this.TABLE_PAGE_SIZE
            }
        }))
    }

    async fetchFilteredTimesheet() {
        return await this.timesheet.loadDataFromAPI(this.api.GET(`${this.apiPrefix}/activities`, {
            params: {
                page: this.timesheetTableState.page + 1,
                pageSize: this.TABLE_PAGE_SIZE,
                event: ProjectActivityEventIDs.TIMESHEET_LOG_EVENT,
                author: this.timesheetTableState.filters.author,
                timesheetEntryFrom: this.timesheetTableState.filters.timesheetEntryFrom,
                timesheetEntryTo: this.timesheetTableState.filters.timesheetEntryTo
            }
        }))
    }

    async addProjectActivity(values: NEW_PROJECT_ACTIVITY_FORM_VALUES_TYPE) {
        await this.dialogService.catchAndAlertError(this.api.POST(`${this.apiPrefix}/activities`, values))
        await this.fetchProjectActivities()
    }

    async setPhaseValues(values: PHASE_VALUES_TYPE) {
        await this.dialogService.catchAndAlertError(this.api.PUT(`${this.apiPrefix}/progress`, values))
        await this.fetchProjectInfo()
    }

    async deleteProject() {
        let doubleConfirmed: unknown = false;
        const confirmed = await this.dialogService.addConfirmDialog('Are you sure you want to delete this project? This action cannot be undone.', null, false)
        if (confirmed) {
            doubleConfirmed = await this.dialogService.addConfirmDialog('Delete project permanently.', null, false)
            if (doubleConfirmed) {
                await this.dialogService.catchAndAlertError(this.api.DELETE(this.apiPrefix))
                await this.dialogService.addAlertDialog('Success!', 'Project deleted.')

            }
        }
        return doubleConfirmed;
    }

    async deleteActivity(activityId: number) {
        const confirmed = await this.dialogService.addConfirmDialog('Are you sure you want to delete this activity?', null, false)
        if (confirmed) {
            const doubleConfirmed = await this.dialogService.addConfirmDialog('Delete activity permanently.', null, false)
            if (doubleConfirmed) {
                await this.dialogService.catchAndAlertError(this.api.DELETE(`${this.apiPrefix}/activities/${activityId}`));
                await this.dialogService.addAlertDialog('Success!', 'Activity deleted.')
                await this.fetchProjectActivities()
            }
        }
    }

    async fetchShipments() {
        return await this.shipmentsList.loadDataFromAPI(this.api.GET(`${this.apiPrefix}/shipments`))
    }

    async addNewShipmentRecord(shipmentId: number) {
        return await this.dialogService.catchAndAlertError(this.progressService.track(this.api.PUT(`${this.apiPrefix}/shipments/${shipmentId}`, null)));
    }

    async resyncShipmentRecords(){
        return await this.dialogService.catchAndAlertError(this.api.POST(`${this.apiPrefix}/shipments/sync`, null));
    }

    async deleteShipmentRecord(shipmentId: number){
        const confirmed = await this.dialogService.addConfirmDialog('Are you sure you want to delete this shipment record?', null, false);
        if (confirmed){
            await this.dialogService.catchAndAlertError(this.progressService.track(this.api.DELETE(`${this.apiPrefix}/shipments/${shipmentId}`)));
            await this.fetchShipments();
        }
    }

    getInitialProgress() {
        if (this.project.data?.psOnly){
            return {
                kickOff: {
                    currentValue: 0,
                    totalValue: 10,
                    unit: 'Hours'
                },
                config: {
                    currentValue: 0,
                    totalValue: 100,
                    unit: '%'
                },
                migration: {
                    currentValue: 0,
                    totalValue: 100,
                    unit: '%'
                },
                cutover: {
                    currentValue: 0,
                    totalValue: 100,
                    unit: '%'
                }
            }
        }
        return {
            kickOff: {
                currentValue: 0,
                totalValue: 100,
                unit: '%'
            },
            config: {
                currentValue: 0,
                totalValue: 100,
                unit: '%'
            },
            migration: {
                currentValue: 0,
                totalValue: 100,
                unit: '%'
            },
            cutover: {
                currentValue: 0,
                totalValue: 100,
                unit: '%'
            }
        }

    }

}

const dummyShipments = [{
    statusStr: SHIPMENT_STATUS.Completed,
    description: 'Shipment description goes here.',
    accountAka: 'Pure',
    id: 420,
    barcode: "30042-PDK67",
    locationName: 'Arlington, VA',
    created: 1609882871000,
    shipped: 1610055812000,
    bsiLink: 'https://www.google.com'
},
    {
        statusStr: SHIPMENT_STATUS.Shipped,
        description: 'Shipment description goes here.',
        accountAka: 'Pure',
        id: 425,
        barcode: "30042-PDK67",
        locationName: 'Arlington, VA',
        created: 1609882871000,
        shipped: 1610055812000,
        bsiLink: 'https://www.google.com'
    },
    {
        statusStr: SHIPMENT_STATUS.ReadyForShipment,
        description: 'Shipment description goes here.',
        accountAka: 'Pure',
        id: 430,
        barcode: "30042-PDK67",
        locationName: 'Arlington, VA',
        created: 1609882871000,
        shipped: 1610055812000,
        bsiLink: 'https://www.google.com'
    },
    {
        statusStr: SHIPMENT_STATUS.PartiallyReturned,
        description: 'Shipment description goes here.',
        accountAka: 'WWT',
        id: 435,
        barcode: "30042-PDK67",
        locationName: 'Arlington, VA',
        created: 1609882871000,
        shipped: 1610055812000,
        bsiLink: 'https://www.google.com'
    },
    {
        statusStr: SHIPMENT_STATUS.Preparation,
        description: 'Shipment description goes here.',
        accountAka: 'WWT',
        id: 435,
        barcode: "30042-PDK67",
        locationName: 'Arlington, VA',
        created: 1609882871000,
        shipped: 1610055812000,
        bsiLink: 'https://www.google.com'
    },
    {
        statusStr: SHIPMENT_STATUS.Cancelled,
        description: 'Shipment description goes here.',
        accountAka: 'WWT',
        id: 435,
        barcode: "30042-PDK67",
        locationName: 'Arlington, VA',
        created: 1609882871000,
        shipped: 1610055812000,
        bsiLink: 'https://www.google.com'
    },
]



const testData = {
    user: {
        name: "Lindsey",
        email: "llam2046@gmail.com",
        phone: "5166601208"
    },
    source_storage: {
        total_storage_involved: 2,
        total_capacity: 50,
        entries: [{
            make: "EMC",
            model: "VMAX",
            targets: '44',
            credentials: true,
            storage_report_file: {
                uuid: "244aeadf-5c59-4d20-b1e9-9dc5a690390f",
                fileName: "cat1.jpg",
                fileSize: 36700
            }
        },
            {
                make: "EMC",
                model: "VMAX",
                targets: '44',
                credentials: false,
                storage_report_file: {
                    uuid: "6912fbd3-2542-4c74-89fd-5f2c2a42ab05",
                    fileName: "cat2.jpeg",
                    fileSize: 38095
                }
            }
        ]
    },
    source_fabric: {
        model: 'Cisco Model2',
        vendor: 'Brocade',
        port_zone: true,
        link_speed: 200,
        credentials: false,
        vsan_enabled: true,
        fabric_restrictions: '44',
        switch_report_file: {
            uuid: "f2b21b52-9afb-44cb-864d-f2b5ddcfcb83",
            fileName: "cat3.jpg",
            fileSize: 50325
        }
    },
    san_config: {
        cluster: {
            aix: true,
            esx: true,
            hpux: true,
            linux: true,
            solaris: true,
            windows: true,
        },
        host_type_description: 'test description',
        total_hosts: {
            aix: 1,
            esx: 2,
            hpux: 3,
            linux: 4,
            solaris: 5,
            windows: 6,
        },
        lpar_notes: 'lpar notes',
        san_boot: {
            aix: true,
            esx: true,
            hpux: true,
            linux: true,
            solaris: true,
            windows: true,
        },
        average_initiators: {
            aix: 1,
            esx: 2,
            hpux: 3,
            linux: 4,
            solaris: 5,
            windows: 6,
        },
        blade_server_notes: 'blade server notes'
    },
    destination: {
        total_storage_involved: 1,
        entries: [{
            model: 'iPhone',
            credentials: true
        }]
    },
    network: {
        local: {
            firewall_restrictions: false,
            firewall_rules_modifiable: false,
            network_security_notes: 'network security notes'
        },
        remote: {
            remote_access_availability: 'yes'
        }
    },
    project_time_frame: {
        must_start_date: '03/20/20',
        must_complete_date: '04/01/20',
        expected_start_date: '03/20/20',
        expected_complete_date: '04/01/20'
    },
    site_info: {
        customer: {
            name: 'Albert',
            email: 'albert@albert.albert',
            phone: '5166601208',
            company: 'Youtube'
        },
        location: {
            source: {
                address: '6000 Pennsylvania Avenue',
                onsite_contact: {
                    name: 'George',
                    email: 'george@washington.com',
                    phone: '5555555'
                },
                escort_required: true,
                security_requirements: 'badge',
                installation_permission: 'yes'
            },
        }
    },
    misc: {
        deal_identifier: 'dealio'
    }
}
