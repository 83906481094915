import React, {useEffect, useState} from 'react';
import Clipboard from 'clipboard';
import {observer} from "mobx-react-lite";
import {Box, Button, Card, Divider, Tooltip, Typography} from "@material-ui/core";
import {useAppServices} from "../../modules/app/AppStates";

// ======================
// ClipboardTextArea
// ======================


export interface ClipboardProps{
    text: string | React.ReactComponentElement<any>;
    id: string;
    title?: string;
}
export const ClipboardText: React.FC<ClipboardProps> = observer(props=>{
    const { text, id, title } = props;
    let clipboard: Clipboard;


    const [tooltipOpen, setTooltipOpen ] = useState(false);
    const [tooltipText, setTooltipText] = useState(null);


    const showTooltip = (result: string) =>{
        if (result ==='success'){
            setTooltipText('Copied!');
            setTooltipOpen(true);
            setTimeout(()=>setTooltipOpen(false), 500)
        } else if (result === 'error'){
            setTooltipText('Copy Unsuccessful. Use CTRL+C to copy text.');
            setTooltipOpen(true);
            setTimeout(()=>setTooltipOpen(false), 500);

        }

    }

    useEffect(()=>{
        clipboard = new Clipboard('.clipboardButton');
        clipboard.on('success', ()=>{
            showTooltip('success')
        })
        clipboard.on('error', ()=>{
            showTooltip('error')
        })
        return ()=> clipboard.destroy();
    },[])

        return <Box>
            <Box display={'flex'} justifyContent={'space-between'} p={4}>
                <Typography variant={'h5'}>{title}</Typography>
                <Tooltip title={tooltipText} arrow={true} open={tooltipOpen} leaveDelay={0}
                         placement={'top'}
                >
                    <Button variant={'outlined'} className={'clipboardButton'} data-clipboard-target={`#${id}`}>Copy To Clipboard</Button>
                </Tooltip>
            </Box>
            <Divider variant={'fullWidth'} />
            <Box p={4}>
                <Card variant={'outlined'}>
                    <Box p={2} id={id}>
                        {text}
                    </Box>
                </Card>
            </Box>


            <Box display={'flex'} justifyContent={'flex-end'} p={4}>
                <Tooltip title={tooltipText} arrow={true} open={tooltipOpen} leaveDelay={0}
                placement={'top'}
                >
                    <Button variant={'outlined'} className={'clipboardButton'} data-clipboard-target={`#${id}`}>Copy To Clipboard</Button>
                </Tooltip>
            </Box>

        </Box>


})