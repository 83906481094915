import {PopoverState} from "./PopoverService";
import React from "react";
import {observer} from "mobx-react-lite";
import {useAppServices} from "../../modules/app/AppStates";
import {useMigrationProjectDetailsStyles} from "../../modules/migration/migrationProjectDetails/MigrationProjectStyles";
import {
    Box,
    Chip,
    Avatar,
    Button,
    makeStyles,
    Theme,
    Tooltip,
    IconButton,
    SvgIcon,
    ButtonProps, ButtonBaseProps, ButtonTypeMap
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import {PEOPLE_TYPE} from "../../modules/migration/MigrationProjectTypes";
import {Color} from "@material-ui/lab";

const usePopoverStyles = makeStyles((t:Theme)=>({
    avatar: {
        height:32,
        width: 32
    }
}))




// ======================
// PopoverChip
// ======================

interface PopoverChipProps {
    popoverState: PopoverState,
    label: string,
    children: any
}

export const PopoverChip: React.FC<PopoverChipProps> = observer((props) => {
    const styles = useMigrationProjectDetailsStyles(props);
    const { children, label, popoverState } = props;

    return (<>
            <Chip variant={'outlined'} color={'primary'} label={label} onClick={(e: React.MouseEvent)=>popoverState.handleClick(e)} className={styles.repChip}/>
            <Popover open={popoverState.popover.open}
                     anchorEl={popoverState.popover.anchor}
                     onClose={()=>popoverState.handleClose()}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'center',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'center',
                     }}>
                <Box p={2} >
                    <Typography variant={'body1'}>
                        {children}
                    </Typography>
                </Box>
            </Popover>
        </>
    )
})

// ======================
// UserListPopover
// ======================

interface UserListPopoverProps{
    popoverState: PopoverState,
    label: string,
    children: any,
    userList: Array<PEOPLE_TYPE>
}

export const UserListPopover: React.FC<UserListPopoverProps> = observer((props)=>{
    const styles = usePopoverStyles(props);
    const { children, label, popoverState, userList } = props;

    return (<>
            <AvatarGroup>
                {userList.slice(0,2).map((user: PEOPLE_TYPE)=>{
                    return <Avatar className={styles.avatar} src={user.pictureUrl}/>
                })}
                    <Avatar className={styles.avatar}>
                        <Button onClick={(e: React.MouseEvent)=>popoverState.handleClick(e)}>
                        {userList.length>2 ? `+${userList.length-2}` : '...'}
                        </Button>

                    </Avatar>

            </AvatarGroup>
            <Popover open={popoverState.popover.open}
                     anchorEl={popoverState.popover.anchor}
                     onClose={()=>popoverState.handleClose()}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'center',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'center',
                     }}>
                <Box p={2} >
                    <Typography variant={'body1'}>
                        {children}
                    </Typography>
                </Box>
            </Popover>
        </>
    )
})

// ======================
// PopoverIconButton
// ======================

interface PopoverIconButtonProps{
    popoverState: PopoverState,
    label: string,
    children: any,
    icon: any
    noGutter?: boolean;
}

export const PopoverIconButton: React.FC<PopoverIconButtonProps> = observer((props)=>{
    const { children, label, popoverState, icon, noGutter } = props;

    return (<>
            <Tooltip title={label}>
                <IconButton color={'inherit'} onClick={(e: React.MouseEvent)=> popoverState.handleClick(e)}>
                    <SvgIcon>
                        {icon}
                    </SvgIcon>
                </IconButton>
            </Tooltip>
            <Popover open={popoverState.popover.open}
                     anchorEl={popoverState.popover.anchor}
                     onClose={()=>popoverState.handleClose()}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'center',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'center',
                     }}>
                <Box p={noGutter? 0 : 2}>
                    <Typography variant={'body1'}>
                        {children}
                    </Typography>
                </Box>
            </Popover>
        </>
    )
})

// ======================
// PopoverButton
// ======================

interface PopoverButtonProps{
    popoverState: PopoverState,
    buttonLabel: string;
    buttonIcon?: any;
    buttonColor: any;
}

export const PopoverButton: React.FC<PopoverButtonProps & Partial<ButtonProps>> = observer((props)=>{

    const { popoverState, buttonLabel, buttonIcon, buttonColor, ...p } = props;

    return (<>
            <Button startIcon={buttonIcon} color={buttonColor} onClick={(e: React.MouseEvent)=>popoverState.handleClick(e)} {...props}>{buttonLabel}</Button>
            <Popover open={popoverState.popover.open}
                     anchorEl={popoverState.popover.anchor}
                     onClose={()=>popoverState.handleClose()}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'center',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'center',
                     }}>
                <Box p={2} >
                    <Typography variant={'body1'}>
                        {p.children}
                    </Typography>
                </Box>
            </Popover>
        </>
    )
})