import React from 'react';
import {observer} from "mobx-react-lite";
import {createColumn, PSTable} from "../../../common/table/PSTable";
import {useInitData, useServerData} from "../../core/data/DataLoaderHooks";
import {LoadingScreen} from "../../../common/splash/SplashScreen";
import {Avatar, Box, Button, Card, Divider, TableCell, TableRow, Typography} from "@material-ui/core";
import {MigrationProjectDetailsState} from "./MigrationProjectDetailsState";
import {
    ACTIVITIES_FILTERS_TYPE,
    PROJECT_ACTIVITY_TYPE,
} from "../MigrationProjectTypes";
import {add, format, formatISO, parseISO} from "date-fns";
import {useMigrationProjectDetailsStyles} from "./MigrationProjectStyles";
import {
    AutocompleteFormField,
    FormDatePicker,
    FormDateTimePicker,
} from "../../../common/form/FormComponents";
import {Formik} from "formik";
import * as yup from "yup";
import {TableState} from "../../../common/table/TableState";

// ======================
// ProjectTimesheet
// ======================

interface ProjectTimesheetProps {
    detailsState: MigrationProjectDetailsState
}

export const ProjectTimesheet: React.FC<ProjectTimesheetProps> = observer((props) => {

    const {detailsState} = props;

    const styles = useMigrationProjectDetailsStyles(props);

    const columns = [
        createColumn('author', 'Name'),
        createColumn('activity', 'Activity'),
        createColumn('date', 'Date'),
        createColumn('timeFrom', 'Time From'),
        createColumn('timeTo', 'Time To'),
        createColumn('hours', 'Hours')
    ]

    useInitData({
        poll: () => detailsState.fetchFilteredTimesheet(),
        pollInterval: 1
    })

    return useServerData(detailsState.timesheet, <LoadingScreen/>, (data) => {

        const totalHours = data.items.length > 0 ? formatHours(data.items.map(item => item.params.minutes).filter(min => min > 0).reduce((a, b,) => a + b, 0)) : 0;
        return <Box pt={4}>
            <Box pb={2}>
                <Typography variant={'h4'}>
                    Timesheet
                </Typography>
            </Box>

            <Card>
                <Box><PSTable tableData={data} columns={columns}
                              containerComponent={Box}
                              rowComponent={TimesheetTableRow}
                              tableState={detailsState.timesheetTableState}
                              refreshFunction={() => detailsState.fetchFilteredTimesheet()}
                              densePadding
                              noPagination
                              filterForm={TimesheetFilterForm}
                />
                </Box>
                <Box pt={2} pr={6} pb={2} className={styles.timesheetTotalHours}>
                    <Typography variant={'subtitle1'}>Total Hours: {totalHours}</Typography>
                </Box>
            </Card>
        </Box>


    })
})

// ======================
// TimesheetTableRow
// ======================

interface TimesheetTableRowProps {
    data: PROJECT_ACTIVITY_TYPE
}

export const TimesheetTableRow: React.FC<TimesheetTableRowProps> = observer((props) => {

    const {data} = props;

    const styles = useMigrationProjectDetailsStyles(props);

    return (
        <TableRow>
            <TableCell>
                {data.author.fullName}
            </TableCell>
            <TableCell>
                {data.title}
            </TableCell>
            <TableCell>
                {getFullDate(data.params.from)}
            </TableCell>
            <TableCell>
                {getTime(data.params.from)}
            </TableCell>
            <TableCell>
                {getTime(data.params.to)}
            </TableCell>
            <TableCell>
                {data.params.minutes >= 0 &&
                formatHours(data.params.minutes)}
            </TableCell>
        </TableRow>
    )
})

const formatHours = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes - (hours * 60)
    let formattedHours: string | number = hours;
    let formattedMinutes: string | number = mins;
    if (hours < 10) {
        formattedHours = `0${hours}`
    }
    if (mins < 10) {
        formattedMinutes = `0${mins}`;
    }
    return `${formattedHours}:${formattedMinutes}`
}

const getFullDate = (isoString: string) => {
    const date = parseISO(isoString);
    try {
        return format(date, 'P');
    } catch (e) {
        console.log(e)
    }
}

const getTime = (isoString: string) => {
    const date = parseISO(isoString);
    return format(date, 'HH:mm');

}

// ======================
// TimesheetFilterForm
// ======================

interface TimesheetFilterFormProps<T> {
    tableState: TableState<T>
}

export const TimesheetFilterForm: React.FC<TimesheetFilterFormProps<ACTIVITIES_FILTERS_TYPE>> = observer((props) => {

    const {tableState} = props;

    const styles = useMigrationProjectDetailsStyles(props);

    const getInitialValues = () => {
        let initialValues: { author: { name: string | null, id: number | null }, timesheetEntryFrom: Date | null, timesheetEntryTo: Date | null }
        if (tableState.filterOn){
            initialValues = {
                author: tableState.filterLabels.author,
                timesheetEntryFrom: tableState.filterLabels.timesheetEntryFrom,
                timesheetEntryTo: tableState.filterLabels.timesheetEntryTo
            }
        } else {
            initialValues = {
                author: {name: '', id: null},
                timesheetEntryFrom: null,
                timesheetEntryTo: null
            }
        }

        return initialValues;
    }

    const schema = yup.object({
        author: yup.object().nullable().label('Name'),
        timesheetEntryFrom: yup.date().nullable().label('Time From').typeError('Invalid Date.'),
        timesheetEntryTo: yup.date().nullable().label('Time To').typeError('Invalid Date.')

    });

    const filterEngineerOptions = (options: Array<{ name: string, id: number }>) => {
        const result = [];
        const map = new Map();
        for (const item of options) {
            if (!map.has(item.id)) {
                map.set(item.id, true);    // set any value to Map
                result.push({
                    id: item.id,
                    name: item.name
                });
            }
        }
        return result;
    }

    return <Formik initialValues={getInitialValues()} validationSchema={schema} onSubmit={async (values, actions) => {

        const authorId = values.author ? values.author.id : null;

        const valuesToFilter: ACTIVITIES_FILTERS_TYPE = {
            author: authorId,
            timesheetEntryFrom: values.timesheetEntryFrom,
            timesheetEntryTo: values.timesheetEntryTo
        }

        const filterLabelValues: { author: { name: string | null, id: number | null }, timesheetEntryFrom: Date | null, timesheetEntryTo: Date | null } = {
            author: values.author,
            timesheetEntryFrom: values.timesheetEntryFrom,
            timesheetEntryTo: values.timesheetEntryTo
        }

        await tableState.setFilterLabels(filterLabelValues)
        await tableState.setFilterValues(valuesToFilter)

        actions.setSubmitting(false)

    }}>
        {props => {
            return <Box>
                <Box pr={2} pl={2} pb={2}>
                    <AutocompleteFormField name={'author'}
                                           autocompleteState={tableState.matchAutocomplete('employeeTimesheet')}
                                           label={'Name'}
                                           getOptionLabel={data => data.name}
                                           getOptionFormat={(item: PROJECT_ACTIVITY_TYPE) => {
                                               return {
                                                   name: item.author.fullName,
                                                   id: item.authorId
                                               }
                                           }}
                                           filterOptions={filterEngineerOptions}
                    />
                </Box>
                <Box>
                    <Box p={2}>
                        <FormDateTimePicker label={'Time From'} name={'timesheetEntryFrom'} maxDate={new Date()}
                                            PopoverProps={{anchorOrigin:{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            },
                                                transformOrigin:{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}}
                        />
                    </Box>
                    <Box p={2}>
                        <FormDateTimePicker label={'Time To'} name={'timesheetEntryTo'} maxDate={new Date()}
                                            minDate={add(props.values.timesheetEntryFrom, {days: 1})}
                                            PopoverProps={{anchorOrigin:{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                },
                                                transformOrigin:{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}}
                        />
                    </Box>
                </Box>
                <Box className={styles.flexJustifyCenter} p={2}>
                    <Button color={'primary'} variant={'contained'} disableElevation
                            disabled={props.isSubmitting}
                            onClick={(e) => {
                                e.preventDefault();
                                props.submitForm()
                            }}><Box pl={3} pr={3}>{'Apply Filters'}</Box></Button>
                </Box>
                <Box className={styles.flexJustifyCenter} pb={2}>
                    <Button color={'primary'} variant={'outlined'} disableElevation
                            disabled={props.isSubmitting || !tableState.filterOn}
                            onClick={(e) => {
                                e.preventDefault();
                                tableState.resetFilters()
                            }}><Box pl={3} pr={3}>{'Clear Filters'}</Box></Button>
                </Box>
            </Box>

        }}
    </Formik>
})