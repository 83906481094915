/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppRoutes
 */
import * as React from 'react';

export const APP_ROUTES = {
    LOGIN: '/login',
    HOME: '/home',
    MAIN_BASE: '/main',
    RANDOM_SCREEN : '/main/random',
    MIGRATION: '/projects/migration',
    MIGRATION_PROPOSALS: '/projects/migration-proposals',
    REPORTS: '/reports',
    MIGRATION_FORM: '/create-new-migration-project',
    AUTH_CALLBACK: '/auth-callback',
    GENERATE_REPORT_FORM: '/generate-new-report'
};