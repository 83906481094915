import * as React from 'react';

import {createMuiTheme, responsiveFontSizes, Theme, ThemeProvider} from '@material-ui/core/styles';
import primary from '@material-ui/core/colors/blue';
import secondary from '@material-ui/core/colors/lightBlue';
import grey from '@material-ui/core/colors/grey';
import {observer} from 'mobx-react-lite';
import {useAppServices} from "./AppStates";


// add custom variables here
declare module '@material-ui/core/styles/createMuiTheme' {
    interface Theme {
    }
}


export const makeAppTheme = (light = true) => {
    let theme;

    if (light) {
       theme = createMuiTheme({
            palette: {
                background: {
                    default: grey['50'],
                },
                primary: {
                    ...primary,
                    main: primary['800'],
                },
                secondary: {
                    ...secondary,
                    main: secondary['200'],
                    light: secondary['200']
                },
                type: light ? 'light' : 'dark',
            },
        });
        theme     = responsiveFontSizes(theme);

    }else{
        theme = createMuiTheme({
            palette: {
                background: {
                    default: grey['900'],
                },
                primary: {
                    ...primary,
                    main: primary['800'],
                },
                secondary: {
                    ...secondary,
                    main: secondary['200'],
                },
                type: light ? 'light' : 'dark',
            },
        });
        theme     = responsiveFontSizes(theme);
    }
    return theme;
};


// ======================
// LightModeTheme
// ======================
interface LightModeThemeProps {
}

export const LightModeTheme: React.FC<LightModeThemeProps> = observer(({...p}) => {
    return <ThemeProvider theme={makeAppTheme(true)}>
        {p.children}
    </ThemeProvider>;
});
// ======================
// DarkModeTheme
// ======================
interface DarkModeThemeProps {
}

export const DarkModeTheme: React.FC<DarkModeThemeProps> = observer(({...p}) => {
    return <ThemeProvider theme={makeAppTheme(false)}>
        {p.children}
    </ThemeProvider>;
});

// ======================
// AppTheme
// ======================

interface AppThemeProps{

}

export const AppTheme: React.FC<AppThemeProps> = observer(({...p})=>{

    const { appGlobalService } = useAppServices();

    if (appGlobalService.isLightMode){

        return <LightModeTheme {...p}> {p.children}</LightModeTheme>
    } else{
        return <DarkModeTheme {...p}> {p.children}</DarkModeTheme>
    }
})