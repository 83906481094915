import {TableState} from "../../common/table/TableState";
import {AppUser} from "../../../../server/src/modules/users/entities/AppUser";

export enum ProjectActivityEventIDs {
    SYSTEM_GENERATED_EVENT = 'SYSTEM_GENERATED_EVENT',
    USER_ENTERED_EVENT = 'USER_ENTERED_EVENT',
    TIMESHEET_LOG_EVENT = 'TIMESHEET_LOG_EVENT',

    PROJECT_INFO_UPDATED = 'PROJECT_INFO_UPDATED',
    PROJECT_PROGRESS_UPDATED = 'PROJECT_PROGRESS_UPDATED',
    PROJECT_COMPLETED = 'PROJECT_COMPLETED',
    PROJECT_APPROVED = 'PROJECT_APPROVED',
}

export enum MigrationProjectDealType {
    GENERAL_PS = 'GENERAL_PS', // General PS / Support
    PURE_BUNDLE = 'PURE_BUNDLE', // Pure Storage Bundle
    OTHER = 'OTHER',
}

export enum ACTIVITY_TYPES {
    LOG = 'Timesheet Entry',
    SHIP = 'Shipping',
    GENERAL = 'General'
}


export enum MIGRATION_PROJECT_PHASE {
    KICKOFF = 'kickOff',
    CONFIG = 'config',
    MIGRATION = 'migration',
    CUTOVER = 'cutover'
}

export type MIGRATION_PROJECT_DEAL_TYPE = 'GENERAL_PS' | 'PURE_BUNDLE' | 'OTHER'

export enum MIGRATION_PROJECT_STATUS {
    COMPLETED = 'COMPLETED',
    APPROVED = 'APPROVED',
    IN_PROGRESS = 'IN_PROGRESS',
    ON_HOLD = 'ON_HOLD',
    PROPOSED = 'PROPOSED'
}

export type MIGRATION_PROJECT_STATUS_TYPE =
    MIGRATION_PROJECT_STATUS.APPROVED
    | MIGRATION_PROJECT_STATUS.COMPLETED
    | MIGRATION_PROJECT_STATUS.ON_HOLD
    | MIGRATION_PROJECT_STATUS.IN_PROGRESS
    | MIGRATION_PROJECT_STATUS.PROPOSED

export interface PROJECT_LIST_TYPE {
    items: Array<PROJECT_TYPE>,
    pagination: PAGINATION_TYPE
}

export interface TABLE_DATA_TYPE {
    items: Array<any>,
    pagination: PAGINATION_TYPE
}

export interface PROJECT_FILTERS_TYPE {
    projectName?: string;
    projectStatus?: { [key in MIGRATION_PROJECT_STATUS]?: boolean };
    engineer?: string;
}

export interface PROJECT_TYPE {
    psOnly: boolean;
    projectId: string;
    migrationSource: string;
    migrationDestination: string;
    migrationDataAmount: number | string;
    progress: PHASE_VALUES_TYPE;
    progressWeight: PROGRESS_WEIGHT_TYPE;
    overallProgress: number;
    weightedOverallProgress: number;
    project: PROJECT_INFO_TYPE;
    dealType: MIGRATION_PROJECT_DEAL_TYPE
}

export interface PROJECT_INFO_TYPE {
    createdAt: Date | string;
    updatedAt: Date | string;
    projectId: string;
    projectCode: string;
    status: MIGRATION_PROJECT_STATUS_TYPE;
    salesForceId: string;
    projectName: string;
    projectDescription: string;
    questionnaireId: string;
    projectTimeFrameFrom: string;
    projectTimeFrameTo: string;
    projectStartDate: string;
    projectEndDate: string;
    people?: Array<PEOPLE_TYPE>;
    mmChannel: string;
    psOnly?: boolean
}

export type PROGRESS_WEIGHT_TYPE = { [key in MIGRATION_PROJECT_PHASE]?: number }

export type PHASE_VALUES_TYPE = { [key in MIGRATION_PROJECT_PHASE]?: PHASE_VALUE_TYPE }

export interface PHASE_VALUE_TYPE {
    currentValue: number,
    totalValue: number,
    unit: string
}

export interface NEW_PROJECT_ACTIVITY_FORM_VALUES_TYPE {
    title: string,
    message: string,
    confidential?: boolean,
    eventData?: {
        from: string,
        to: string
    },
    event?: string
    attachments?: Array<PROJECT_ACTIVITY_ATTACHMENT_TYPE>
}

export interface PROJECT_ACTIVITIES_TYPE {
    items: Array<PROJECT_ACTIVITY_TYPE>,
    pagination: PAGINATION_TYPE

}

export interface PROJECT_ACTIVITY_TYPE {
    id: number;
    createdAt: string;
    projectId: string;
    confidential: boolean;
    eventId: string;
    title: string;
    message: string | null;
    authorId: number;
    params: PROJECT_ACTIVITY_PARAMS_TYPE | null;
    author: PEOPLE_TYPE;
    attachments?: Array<PROJECT_ACTIVITY_ATTACHMENT_TYPE>
}

export interface PROJECT_ACTIVITY_ATTACHMENT_TYPE {
    uuid: string,
    fileName: string,
    fileSize: number,
    fileType: string
}


export interface PROJECT_ACTIVITY_PARAMS_TYPE {
    "to": string,
    "from": string,
    "minutes": number
}

export interface PAGINATION_TYPE {
    total: number;
    page: number;
    pageSize: number;
}

export type CDS_REP_LIST_TYPE = Array<EMPLOYEE_TYPE>;

export interface EMPLOYEE_TYPE {
    firstName: string,
    lastName: string,
    titles: Array<string>,
    status: number,
    employeeId: number,
    email: string
}

export type PARTNER_REP_LIST_TYPE = Array<CONTACT_TYPE>;

export interface CONTACT_TYPE {
    firstName: string,
    lastName: string,
    status: number,
    accountId: number,
    accountName: string,
    contactId: number,
    email: string
}

export interface PEOPLE_TYPE {
    id: number,
    userType: string,
    email: string,
    accountName?: string,
    accountId?: number,
    pictureUrl: string,
    fullName: string
    employeeId?: number,
    contactId?: number
}

export interface NEW_PROJECT_FORM_VALUES_TYPE {
    dealType: string,
    migrationSource: string,
    migrationDestination: string,
    migrationDataAmount: number,
    salesForceId: string,
    projectName: string,
    projectDescription: string,
    projectTimeFrameFrom: string,
    projectTimeFrameTo: string,
    projectStartDate: string,
    projectEndDate: string,
    projectEmployees: Array<number>,
    projectContacts: Array<number>,
    mmChannel?: string,
    psOnly?: boolean
    onHold?: boolean
}

export interface EMPLOYEE_FORM_LABEL_TYPE {
    name: string,
    id: number
}

export interface CONTACT_FORM_LABEL_TYPE extends EMPLOYEE_FORM_LABEL_TYPE {
    account: string
}

export interface NEW_PROJECT_FORM_INITIAL_VALUES_TYPE {
    migrationSource: string,
    migrationDestination: string,
    dataAmount: number,
    dataUnit: string,
    salesForceId: string,
    projectName: string,
    projectDescription: string,
    projectTimeFrameFrom: Date,
    projectTimeFrameTo: Date,
    projectStartDate?: Date,
    projectEndDate?: Date,
    projectEmployees: Array<EMPLOYEE_FORM_LABEL_TYPE>,
    projectContacts: Array<CONTACT_FORM_LABEL_TYPE>,
    dealType: string;
    mmChannel?: string;
    psOnly?: boolean;
    onHold?: boolean;
}

export type WAFFLE_VALUE = { [key: string]: string | number }


export interface COLUMN_TYPE {
    name: string,
    label: string
}

export interface FilterFormProps<T> {
    detailsState?: any,
    tableState: TableState<T>
}

export interface ACTIVITIES_FILTERS_TYPE {
    author?: number | null,
    from?: Date | null,
    to?: Date | null,
    eventId?: ProjectActivityEventIDs | null,
    timesheetEntryFrom?: Date | null,
    timesheetEntryTo?: Date | null,
}

export interface TIMESHEET_ENTRY_INITIAL_VALUES {
    title: string,
    message: string,
    timeFrom: Date,
    timeTo: Date,
    confidential: boolean,
    attachments: Array<DROPZONE_FILE_TYPE>
}

export interface MIGRATION_PROJECT_QUESTIONNAIRE_TYPE {
    misc?: MigrationQMiscInfo;
    user?: MigrationQUserInfo;
    network: MigrationQNetworkInfo;
    site_info: MigrationQSiteInfo;
    san_config: MigrationQSANConfigInfo;
    destination: MigrationQDestinationInfo;
    source_fabric: MigrationQSourceFabricInfo;
    source_storage: MigrationQSourceStorageInfo
    project_time_frame: MigrationQTimeFrameInfo;
    additional_site_info?: Array<MIGRATION_PROJECT_SITE_TYPE>;
}

export interface MIGRATION_PROJECT_SITE_TYPE{
    source_storage: MigrationQSourceStorageInfo;
    source_fabric: MigrationQSourceFabricInfo;
    san_config: MigrationQSANConfigInfo;
    destination: MigrationQDestinationInfo;
    network: MigrationQNetworkInfo;
    project_time_frame: MigrationQTimeFrameInfo;
    site_info: MigrationQSiteInfo;
}

export interface MigrationQMiscInfo {
    deal_identifier: string;
}

export interface MigrationQSourceStorageInfo {
    entries: MigrationQSourceStorageEntry[];
    total_capacity: number;
    total_storage_involved: number;
    san_connectivity: string;
    iscsi: MigrationQSourceStorageIscsiInfo
}

export interface MigrationQSourceStorageIscsiInfo {
    switch_report: DOWNLOAD_FILE_TYPE[];
    array_performance_stats: DOWNLOAD_FILE_TYPE[];
    host_performance_stats: DOWNLOAD_FILE_TYPE[];
    adaptors: MigrationQIscsiAdaptorInfo[]
}


export interface MigrationQIscsiAdaptorInfo {
    make: string;
    model: string;
}

export interface MigrationQDestinationInfo {
    entries: MigrationQDestinationEntry[];
    total_storage_involved: number;
}

export interface MigrationQTimeFrameInfo {
    must_start_date: string | Date;
    must_complete_date: string | Date;
    expected_start_date: string | Date;
    expected_complete_date: string | Date;
}

export interface MigrationQDestinationEntry {
    model: string;
    credentials: boolean;
}

export interface MigrationQNetworkInfo {
    local: {
        firewall_restrictions: boolean | null;
        network_security_notes: string;
        firewall_rules_modifiable: boolean | null
    };
    remote: { remote_access_availability: string };
}

export interface MigrationQSANConfigInfo {
    cluster: { [key: string]: boolean | null };
    san_boot: { [key: string]: boolean | null };
    lpar_notes: string;
    total_hosts: { [key: string]: number };
    average_initiators: { [key: string]: number };
    blade_server_notes: string;
    host_type_description: string;
}

export interface MigrationQSiteInfo {
    customer: MigrationQUserInfo;
    location: MigrationQLocationInfo;
}

export interface MigrationQUserInfo {
    name: null | string;
    email: null | string;
    phone: null | string;
    company?: string;
}

export interface MigrationQLocationInfo {
    source: {
        address: string;
        onsite_contact: MigrationQUserInfo;
        escort_required: boolean;
        security_requirements: string;
        installation_permission: string;
    };
}

export interface MigrationQSourceFabricInfo {
    model: string;
    vendor: string;
    port_zone: boolean;
    link_speed: number;
    credentials: boolean;
    vsan_enabled: boolean;
    switch_report_file: Array<DOWNLOAD_FILE_TYPE> | DOWNLOAD_FILE_TYPE;
    fabric_restrictions: string;
}

export interface MigrationQSourceStorageEntry {
    make: string;
    model: string;
    targets: string;
    credentials: boolean;
    storage_report_file: Array<DOWNLOAD_FILE_TYPE> | DOWNLOAD_FILE_TYPE;
}

export interface DROPZONE_FILE_TYPE {
    name: string;
    lastModified: number;
    lastModifiedDate: Date;
    webkitRelativePath: string;
    size: number;
    type: string;
    path: string;
    uuid?: string;
}

export interface DROPZONE_FILE_RESPONSE_TYPE {
    uuid: string;
    filename?: string;
    file_type?: string;
    upload_url: string;
}

export interface DOWNLOAD_FILE_TYPE {
    uuid: string;
    fileName: string;
    fileType: string;
    fileSize: number;
}

export type SHIPMENT_LIST_TYPE = Array<SHIPMENT_TYPE>;

export interface SHIPMENT_TYPE {
    projectId: string;
    project: PROJECT_INFO_TYPE;
    shipmentId: number;
    linkedAt: string;
    updatedAt: string;
    linkedBy: AppUser;
    linkedByUserId: number;
    shipmentStatus: SHIPMENT_STATUS;
    shipmentInfo: SHIPMENT_INFO_TYPE;
}

export interface SHIPMENT_INFO_TYPE {
    id: number;
    status: number;
    statusStr: SHIPMENT_STATUS;
    barcode: string;
    po: string;
    description: string;
    shipped: number; // date
    location: {
        id: number;
        name: string;
        address: string;
    };
    account: {
        id: number;
        accountId: number;
        companyName: string;
        companyAka: string;
        description: string;
    };
    recipient: {
        fullName: string;
        account: {
            id: number;
            accountId: number;
            companyName: string;
            companyAka: string;
            description: string;
        }
    }
/*    inspectedByFullName?: string;
    notes?: null | string;
    statusStr: SHIPMENT_STATUS;
    accountName?: string;
    description: string;
    estimatedShippingDate?: number;
    locationId?: number;
    accountAka: string;
    id: number;
    barcode: string;
    locationName: string;
    created?: number;
    salespersonFullName?: string;
    inspected?: number;
    accountId?: number;
    shipped: number;
    createdByFullName?: string;
    attentionRequired?: boolean;
    status?: number;
    action?: SHIPMENT_ACTION[];
    bsiLink?: string;*/
}

export enum SHIPMENT_ACTION {
    ShippingChange = "shipping change",
    ShippingView = "shipping view",
}


export enum SHIPMENT_STATUS {
    Preparation = 'Preparation',
    Cancelled = 'Cancelled',
    Completed = "Completed",
    Shipped = "Shipped",
    ReadyForShipment = "Ready For Shipment",
    PartiallyReturned = "Partially Returned"
}