import React from "react";
import {observer} from "mobx-react-lite";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import {useIsDesktop} from "../layout/MainLayout";
import {BackButton} from "../../common/buttons/commonButtons";
import {APP_ROUTES} from "../app/AppRoutes";
import * as yup from "yup";
import {Formik, FormikHelpers, FormikValues} from "formik";
import {REPORT_TYPES, SUBMIT_REPORT_TYPE} from "./report_types";
import {AutocompleteFormField, FormCheckboxItem, FormSelect, FormTextField} from "../../common/form/FormComponents";
import {useAppServices} from "../app/AppStates";
import {useHistory} from "react-router-dom";
import {
    EMPLOYEE_FORM_LABEL_TYPE,
    EMPLOYEE_TYPE,
    MIGRATION_PROJECT_STATUS,
    MIGRATION_PROJECT_STATUS_TYPE
} from "../migration/MigrationProjectTypes";
import {renderStatusText} from "../migration/MigrationProjectCommon";


// ======================
// NewReportDialogView
// ======================

interface NewReportDialogViewProps {
}

export const NewReportDialogView: React.FC<NewReportDialogViewProps> = observer((props) => {
    const isDesktop = useIsDesktop();

    return <Box pr={isDesktop ? 10 : 0} pl={isDesktop ? 10 : 0} pb={6}>
        <BackButton backPageName={'Back To Reports'} backPagePath={APP_ROUTES.REPORTS}/>
        <Typography variant={'h4'}>Generate New Report</Typography>
        <Box pt={4}>
            <NewReportForm/>
        </Box>
    </Box>
})

// ======================
// NewReportForm
// ======================

interface NewReportFormProps {
}

export const NewReportForm: React.FC<NewReportFormProps> = observer((props) => {

    const {reportsService, migrationProjectService} = useAppServices();

    const history = useHistory();

    const isDesktop = useIsDesktop();

    const initialValues: SUBMIT_REPORT_TYPE = {
        name: '',
        engineer: null,
        projectStatus: {
            [MIGRATION_PROJECT_STATUS.APPROVED]: true,
            [MIGRATION_PROJECT_STATUS.COMPLETED]: true,
            [MIGRATION_PROJECT_STATUS.IN_PROGRESS]: true,
            [MIGRATION_PROJECT_STATUS.ON_HOLD]: true,
            [MIGRATION_PROJECT_STATUS.PROPOSED]: true,
        },
        description: '',
        reportType: reportTypeLabels[REPORT_TYPES.MIGRATION_PROJECTS_SUMMARY],
    }

    const schema = yup.object({
        name: yup.string().nullable(),
        engineer: yup.object({
            id: yup.number(),
            name: yup.string()
        }).nullable(),
        projectStatus: yup.object({
            [MIGRATION_PROJECT_STATUS.APPROVED]: yup.boolean(),
            [MIGRATION_PROJECT_STATUS.COMPLETED]: yup.boolean(),
            [MIGRATION_PROJECT_STATUS.IN_PROGRESS]: yup.boolean(),
            [MIGRATION_PROJECT_STATUS.ON_HOLD]: yup.boolean(),
            [MIGRATION_PROJECT_STATUS.PROPOSED]: yup.boolean()
        }),
        description: yup.string().nullable(),
        reportType: yup.string().required('Enter report type.')
    })

    const renderStatusCheckboxes = () => {
        return Object.values(MIGRATION_PROJECT_STATUS).map(s => {
            return <FormCheckboxItem key={s} label={renderStatusText(s)} name={`projectStatus.${s}`} defaultChecked={true}/>
        })
    }

    const submit = async (values: FormikValues, actions: FormikHelpers<SUBMIT_REPORT_TYPE>) => {
        const eng = values.engineer?.email || null;
        const statusArray = [];
        for (let status in values.projectStatus) {
            if (values.projectStatus[status as MIGRATION_PROJECT_STATUS_TYPE] === true) {
                statusArray.push(status as MIGRATION_PROJECT_STATUS_TYPE)
            }
        }

        values.reportType = getReportTypeValue(values.reportType);
        if (values.reportType === REPORT_TYPES.MIGRATION_PROJECTS_SUMMARY) {
            //console.log(statusArray)
            await reportsService.generateProjectSummaryReport(eng, statusArray);
            history.push(APP_ROUTES.REPORTS)
        }
    }

    return <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submit}>
        {props =>
            <Box>
                <Box display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
                    <Box pb={2} width={isDesktop ? 300 : '100%'}>
                        <FormTextField label={'Name'} name={'name'} disabled/>
                    </Box>
                    <Box pb={2} width={isDesktop ? 300 : '100%'}>
                        <FormSelect label={'Report Type'} name={'reportType'} selectionList={typeSelectionList}/>
                    </Box>
                </Box>


                <Box pb={4}>
                    <FormTextField label={'Description'} name={'description'} disabled multiline={true}/>
                </Box>
                <Box pb={4}>
                    <Typography variant={'h6'}>Advanced Options</Typography>
                </Box>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Box pb={1}>
                            <Typography>Filter By CDS Representative:</Typography>
                        </Box>
                        <Box width={250}>
                            <AutocompleteFormField name={'engineer'}
                                                   autocompleteState={migrationProjectService.employeeAutocompleteState}
                                                   label={'CDS Representative'}
                                                   getOptionLabel={data => data.name}
                                                   getOptionFormat={(item: EMPLOYEE_TYPE) => {
                                                       return {
                                                           name: `${item.firstName} ${item.lastName}`,
                                                           email: item.email
                                                       }
                                                   }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <Typography>Filter By Project Status:</Typography>
                            {renderStatusCheckboxes()}
                        </Box>
                    </Grid>

                </Grid>

                <Box display={'flex'} justifyContent={'flex-end'}>
                    <Button color={'primary'} variant={'contained'} type={'submit'} disableElevation
                            onClick={async (e) => {
                                e.preventDefault();
                                await props.submitForm()
                            }
                            }>
                        Generate Report
                    </Button>
                </Box>

            </Box>
        }
    </Formik>
})


const reportTypeLabels = {
    [REPORT_TYPES.MIGRATION_PROJECTS_SUMMARY]: 'Migration Projects Summary',
}

const typeSelectionList = Object.keys(reportTypeLabels).map((type) => reportTypeLabels[type as keyof typeof REPORT_TYPES])


export const getReportTypeLabel = (type: REPORT_TYPES): string => {
    if (type === REPORT_TYPES.MIGRATION_PROJECTS_SUMMARY) {
        return reportTypeLabels[REPORT_TYPES.MIGRATION_PROJECTS_SUMMARY]
    }
}

export const getReportTypeValue = (type: string): REPORT_TYPES => {
    if (type === reportTypeLabels[REPORT_TYPES.MIGRATION_PROJECTS_SUMMARY]) {
        return REPORT_TYPES.MIGRATION_PROJECTS_SUMMARY
    }
}