import {observer} from "mobx-react-lite";
import React from "react";
import {Box, BoxProps, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useDataDisplayStyles = makeStyles({
    overline: {
        lineHeight: 1.2
    }
})

// ======================
// DataItem
// ======================

interface DataItemProps{
    label: string,
    data: string | number
}


export const DataItem: React.FC<DataItemProps & Partial<BoxProps>> = observer((props)=>{

    const {label, data, ...p} = props;

    const styles= useDataDisplayStyles(props);

    return <Box {...p}>
        <Typography variant={'overline'} className={styles.overline}>{label}</Typography>
        <Typography variant={'body1'}>{data}</Typography>
    </Box>
})