/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: MainLayout
 */
import * as React from 'react';
import {
    makeStyles,
    Theme,
    useMediaQuery,
    useTheme,
    Box,
    AppBar,
    Toolbar,
    IconButton,
    Typography, SvgIcon, Tooltip, Divider, Button
} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {useAppServices} from '../app/AppStates';
import Container from "@material-ui/core/Container";
import grey from '@material-ui/core/colors/grey';
import {APP_ROUTES} from "../app/AppRoutes";
import MenuIcon from '@material-ui/icons/Menu';

import {
    IoMdSunny, FiLogOut, IoIosMoon, FaUserCircle, AiFillProject, MdTrackChanges, AiOutlineLineChart, IoMdMenu
} from "react-icons/all";
import {Link, useRouteMatch} from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import {PopoverIconButton} from "../../common/popover/PopoverButton";
import {PopoverState} from "../../common/popover/PopoverService";
import {EmployeeView} from "../auth/EmployeeView";


const DESKTOP_BREAKPOINT = 'md'; // beyond this consider desktop
const MOBILE_BREAKPOINT = 'xs';
export const useIsDesktop = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up(DESKTOP_BREAKPOINT));
};

export const useIsMobile = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.down(MOBILE_BREAKPOINT));
}

interface MainFrameLayoutProps {
}

const useMainFrameStyles = makeStyles((t: Theme) => ({
    root: {
        height: '100vh',
        display: 'flex',
    },
    main: {
        height: `100%`,
        paddingTop: 120,
        paddingLeft: t.spacing(2),
        paddingRight: t.spacing(2)
        //paddingTop: t.spacing(TOP_BAR_HEIGHT),
    },
    mobileMain: {
        height: `100%`,
        paddingTop: 110,
        paddingLeft: t.spacing(2),
        paddingRight: t.spacing(2)
        //paddingTop: t.spacing(TOP_BAR_HEIGHT),
    },
    toolBar: {
        display: 'flex',
        paddingTop: t.spacing(1),
        paddingBottom: t.spacing(1)
    },
    menuButton: {
        marginRight: t.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'flex',
    },

    homeButton: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center'
    },

    homeText: {
        paddingLeft: t.spacing(1)
    },
    iconButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    link: {
        color: grey['100'],
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center'

    },
    activeLink: {
        color: t.palette.secondary.light,
        textDecoration: 'none'
    },
    userPopover: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    menuItems: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

export const MainFrameLayout: React.FC<MainFrameLayoutProps> = observer(({...p}) => {
    const styles = useMainFrameStyles(p);
    const isDesktop = useIsDesktop();

    const containerClassName = isDesktop ? styles.main : styles.mobileMain

    return (
        <Box className={styles.root}>
            {isDesktop && <DesktopMenuBar/>}
            {!isDesktop && <MobileMenuBar/>}
            <Container maxWidth={'xl'} className={containerClassName} component={'main'} disableGutters>
                <Box pr={isDesktop? 10: 0} pl={isDesktop? 10: 0}>
                    {p.children}

                </Box>
            </Container>
        </Box>
    )
});

// ======================
// MobileMenuBar
// ======================

interface MobileMenuBarProps {
}

export const MobileMenuBar: React.FC<MobileMenuBarProps> = observer((props) => {
    const styles = useMainFrameStyles(props);

    const { authService } = useAppServices();

    return (
        <AppBar elevation={0}>
            <Toolbar className={styles.toolBar}>
                <Box className={styles.homeButton}>
                    <Link to={APP_ROUTES.HOME} className={styles.link}>
                        <SvgIcon color={'inherit'}>
                            <MdTrackChanges/>
                        </SvgIcon>
                    </Link>
                </Box>
                <MenuIconsGroup/>
                <Box>
                    <MenuButton logOut={()=>authService.backToBSI()} popoverState={authService.mobileMenuPopover}
                                userDisplayProps={{name: authService.currentUser.data.fullName, avatar: authService.currentUser.data.pictureUrl}}/>
                </Box>
            </Toolbar>
        </AppBar>
    )
})

// ======================
// DesktopMenuBar
// ======================

interface DesktopMenuBarProps {
}

export const DesktopMenuBar: React.FC<DesktopMenuBarProps> = observer((props) => {
    const styles = useMainFrameStyles(props);
    const { appGlobalService,authService } = useAppServices();

    return (
        <AppBar elevation={0}>
            <Toolbar className={styles.toolBar}>
                <Box className={styles.homeButton}>
                    <Link to={APP_ROUTES.HOME} className={styles.link}>

                        <SvgIcon color={'inherit'}>
                            <MdTrackChanges/>
                        </SvgIcon>
                        <Typography variant="h6" className={styles.homeText}>
                            PS Portal
                        </Typography>
                    </Link>

                </Box>


                <MenuIconsGroup/>
                <UserButton popoverState={authService.userPopover}
                            userDisplayProps={{avatar:authService.currentUser.data.pictureUrl, name:authService.currentUser.data.fullName}}/>
                <BrightnessButton toggleMode={()=>appGlobalService.toggleLightMode()}
                                  isLightMode={appGlobalService.isLightMode}/>
                <LogOutButton logOut={()=>authService.backToBSI()}/>
            </Toolbar>
        </AppBar>
    )
})


// ======================
// BrightnessButton
// ======================

interface BrightnessButtonProps {
    toggleMode: ()=> void;
    isLightMode: boolean
}

export const BrightnessButton: React.FC<BrightnessButtonProps> = observer((props) => {
    const { isLightMode, toggleMode } = props;

    return (
        <Tooltip title={isLightMode ? 'Dark Mode' : 'Light Mode'}>
            <IconButton color={'inherit'} onClick={() => toggleMode()}>
                {isLightMode ? <SvgIcon><IoIosMoon/></SvgIcon> : <SvgIcon><IoMdSunny/></SvgIcon>}
            </IconButton>
        </Tooltip>

    )
})

// ======================
// LogOutButton
// ======================

interface LogOutButtonProps {
    logOut: ()=>Promise<any>;
}

export const LogOutButton: React.FC<LogOutButtonProps> = observer((props) => {
    const { logOut } = props;
    return (
        <Tooltip title={'Back to BSI'}>
            <IconButton color={'inherit'} onClick={() => logOut()}>
                <SvgIcon>
                    <FiLogOut/>
                </SvgIcon>
            </IconButton>
        </Tooltip>
    )
})

// ======================
// UserButton
// ======================

interface UserButtonProps {
    popoverState: PopoverState,
    userDisplayProps: {name: string, avatar: string}

}

export const UserButton: React.FC<UserButtonProps> = observer((props) => {
    const { popoverState, userDisplayProps  } = props;
    return <PopoverIconButton popoverState={popoverState} label={'User Info'} icon={<FaUserCircle/>}
                              children={<UserDisplay avatar={userDisplayProps.avatar} name={userDisplayProps.name}/>}/>
                              })

// ======================
// UserDisplay
// ======================

interface UserDisplayProps {
    avatar: string;
    name: string;
}

export const UserDisplay: React.FC<UserDisplayProps> = observer((props) => {
    const styles = useMainFrameStyles(props);
    const { avatar, name } = props;
    return (
        <Box className={styles.userPopover}>
            <Box pr={1}>
                <Avatar src={avatar}/>
            </Box>
            <Box>
                <Typography variant={'body1'}>
                    {name}
                </Typography>
            </Box>
        </Box>
    )
})


// ======================
// MenuButton
// ======================

interface MenuButtonProps {
    logOut: () => Promise<any>;
    popoverState: PopoverState;
    userDisplayProps: {name: string, avatar: string}
}

export const MenuButton: React.FC<MenuButtonProps> = observer((props) => {
    const styles = useMainFrameStyles(props);
    const { logOut, popoverState, userDisplayProps } = props;

    return (
        <PopoverIconButton popoverState={popoverState} label={'Menu'} icon={<MenuIcon/>} children={
            <>
                <Box pb={2}>
                    <UserDisplay avatar={userDisplayProps.avatar} name={userDisplayProps.name}/>
                </Box>
                <Divider/>
                <Box className={styles.menuItems} pt={1}>
                    <Button startIcon={<FiLogOut/>}
                            onClick={() => logOut()}
                    >
                        Back to BSI
                    </Button>
                </Box>

            </>
        }/>
    )
})

// ======================
// MenuIconsGroup
// ======================

interface MenuIconsGroupProps {
}

export const MenuIconsGroup: React.FC<MenuIconsGroupProps> = observer((props, ...p) => {
    const styles = useMainFrameStyles(props);

    return (
        <Box className={styles.title}>
            <ModuleIcon icon={<AiFillProject/>} route={APP_ROUTES.MIGRATION} name={'projects'}/>
            <EmployeeView>
                <ModuleIcon icon={<AiOutlineLineChart/>} route={APP_ROUTES.REPORTS} name={'reports'}/>
            </EmployeeView>
        </Box>
    )
})

// ======================
// ModuleIcon
// ======================

interface ModuleIconProps {
    icon: React.ReactElement,
    route: string,
    name: string,
    disabled?: boolean
}

export const ModuleIcon: React.FC<ModuleIconProps> = observer((props) => {
    const {icon, route, name, disabled, ...p} = props;
    const styles = useMainFrameStyles(props);
    const match = useRouteMatch(props.route);

    return (
        <Link to={route}
              className={!!match ? styles.activeLink : styles.link}>
            <IconButton disabled={disabled} color={'inherit'} href={route}>

                <Box className={styles.iconButton}>
                    <SvgIcon>
                        {icon}
                    </SvgIcon>
                    <Typography variant={"caption"}>{name}</Typography>
                </Box>

            </IconButton>
        </Link>
    )
})