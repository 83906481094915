import {observer} from "mobx-react-lite";
import React, {useEffect, useState} from "react";
import {useAppServices} from "../app/AppStates";

import Typography from "@material-ui/core/Typography";

import {
    Box,
    Button,
    Chip,
    Tabs,
    Tab,
    Card,
    LinearProgress,
    Fab,
    SvgIcon,
    Tooltip,
} from "@material-ui/core";
import {
    ACTIVITIES_FILTERS_TYPE, EMPLOYEE_TYPE, MIGRATION_PROJECT_STATUS, MIGRATION_PROJECT_STATUS_TYPE,
    PROJECT_ACTIVITY_TYPE,
    PROJECT_FILTERS_TYPE,
    PROJECT_TYPE, SHIPMENT_STATUS
} from "./MigrationProjectTypes";
import {PSCardList} from "../../common/table/PSTable";
import {APP_ROUTES} from "../app/AppRoutes";
import {Link, Route, Switch} from "react-router-dom";
import {MigrationProjectDetailsView} from "./migrationProjectDetails/MigrationProjectDetailsView";
import {useInitData, useServerData} from "../core/data/DataLoaderHooks";

import {
    IoMdAdd,
    FaLongArrowAltRight,
} from "react-icons/all";
import xbytes from "xbytes";
import {Path} from "../../common/path/Path";
import {ContactView, EmployeeView} from "../auth/EmployeeView";
import {LoadingScreen} from "../../common/splash/SplashScreen";
import {MuiTabContent} from "../core/tab/Tabs";
import {useIsDesktop} from "../layout/MainLayout";
import {
    renderDealType, renderStatusText,
    shortenDate,
    shortenName,
    StatusIcon,
    useMigrationProjectListStyles
} from "./MigrationProjectCommon";
import {ProposedMigrationProjectTable} from "./MigrationProjectProposalView";
import {TableState} from "../../common/table/TableState";
import {useMigrationProjectDetailsStyles} from "./migrationProjectDetails/MigrationProjectStyles";
import * as yup from "yup";
import {Formik, FormikFormProps, FormikValues} from "formik";
import {
    AutocompleteFormField,
    FormCheckboxItem,
    FormDateTimePicker,
    FormTextField
} from "../../common/form/FormComponents";
import {add} from "date-fns";


// ======================
// MigrationProjectView
// ======================


interface MigrationProjectViewProps {
}

export const MigrationProjectView: React.FC<MigrationProjectViewProps> = observer((props) => {

    return (
        <>
            <Switch>
                <Route path={APP_ROUTES.MIGRATION} exact>
                    <MigrationProjectMainView/>
                </Route>
                <Route path={`${APP_ROUTES.MIGRATION}/:projectId`}>
                    <MigrationProjectDetailsView/>
                </Route>

                <Route path={APP_ROUTES.HOME}>
                    <MigrationProjectMainView/>
                </Route>
            </Switch>
        </>

    )
})

// ======================
// MigrationProjectMainView
// ======================

interface MigrationProjectMainViewProps {
}

export const MigrationProjectMainView: React.FC<MigrationProjectMainViewProps> = observer((props) => {

    const {migrationProjectService} = useAppServices();
    const styles = useMigrationProjectListStyles(props);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        migrationProjectService.setTab(newValue);
    };

    const isDesktop = useIsDesktop();

    return <Box pb={6}>
        <Box pb={2} className={styles.titleBar}>
            <Box className={styles.title}>
                <Typography variant={'h4'}>
                    Migration Projects
                </Typography>
            </Box>
            <EmployeeView>
                <Box>
                    {isDesktop &&
                    <Link to={APP_ROUTES.MIGRATION_FORM} className={styles.buttonLink}>
                        <Button color={'primary'} startIcon={<IoMdAdd/>}>propose project</Button>
                    </Link>
                    }
                    {!isDesktop &&
                    <Link to={APP_ROUTES.MIGRATION_FORM} className={styles.buttonLink}>
                        <Tooltip title={'Propose Project'}>
                            <Fab color={'primary'} size={'small'}>
                                <SvgIcon>
                                    <IoMdAdd/>
                                </SvgIcon>
                            </Fab>
                        </Tooltip>
                    </Link>
                    }
                </Box>
            </EmployeeView>
        </Box>
        <EmployeeView>
            <Box>
                <Tabs value={migrationProjectService.tab} onChange={handleTabChange} centered={true}>
                    <Tab label={'Approved'}/>
                    <Tab label={'Proposed'}/>
                </Tabs>
            </Box>
        </EmployeeView>
        <MuiTabContent value={migrationProjectService.tab} index={0}>
            <MigrationProjectTable/>
        </MuiTabContent>
        <MuiTabContent value={migrationProjectService.tab} index={1}>
            <ProposedMigrationProjectTable/>
        </MuiTabContent>
    </Box>
})


// ======================
// MigrationProjectTable
// ======================

interface MigrationProjectTableProps {
}

export const MigrationProjectTable: React.FC<MigrationProjectTableProps> = observer((props) => {

    const {migrationProjectService} = useAppServices();

    const isDesktop = useIsDesktop();


    useInitData({
        poll: () => migrationProjectService.fetchProjects(),
        pollInterval: 1
    })

    return useServerData(migrationProjectService.tableData, <LoadingScreen/>, data => {
        return <Box pt={2}>
            {isDesktop &&
            <>
                <PSCardList tableData={data}
                            cardComponent={MigrationProjectCard}
                            filterForm={ProjectTableFilterForm}
                            tableState={migrationProjectService.approvedProjectsTableState}
                            refreshFunction={() => migrationProjectService.fetchProjects()}
                />
            </>
            }
            {!isDesktop &&
            <PSCardList tableData={data} cardComponent={MigrationProjectMobileCard}
                        filterForm={ProjectTableFilterForm}
                        refreshFunction={() => migrationProjectService.fetchProjects()}
                        tableState={migrationProjectService.approvedProjectsTableState}
                        noPagination
            />
            }

        </Box>
    })
})

// ======================
// MigrationProjectCard
// ======================

interface MigrationProjectCardProps {
    data: PROJECT_TYPE
}

export const MigrationProjectCard: React.FC<MigrationProjectCardProps> = observer((props) => {
    const {data} = props;

    const styles = useMigrationProjectListStyles(props)

    return <Box pb={2}>
        <Card>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} pr={2} pt={1} pl={2}>
                <Box pl={1}>
                    <Typography variant={'overline'}>ID: {data.project.projectCode}</Typography>
                </Box>
                <Box>
                    <StatusIcon status={data.project.status} size={'small'}/>
                </Box>
            </Box>
            <Box pl={2} pr={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'} pb={2}>
                <Box width={380}>

                    <Box pb={1}>
                        <Link to={`${APP_ROUTES.MIGRATION}/${data.projectId}`}
                              data-testid={`${data.project.projectName}Details`}
                              className={styles.projectLink}
                        >
                            <Tooltip title={data.project.projectName}>
                                <Button className={styles.button} color={'inherit'}>{shortenName(data.project.projectName, 45)}</Button>
                            </Tooltip>
                        </Link>
                    </Box>
                </Box>
                <Box width={320} display={'flex'} alignItems={'center'}>
                    <Path from={shortenName(data.migrationSource, 40)} to={shortenName(data.migrationDestination,40)}/>
                </Box>
                <EmployeeView>
                    <Box width={150} pt={2}>
                        <Box pb={.5}>
                            <LinearProgress variant="determinate" value={data.weightedOverallProgress}
                                            color={'primary'}/>
                        </Box>
                        {Math.ceil(data.weightedOverallProgress)}% (w) | {Math.ceil(data.overallProgress)}% (o)
                    </Box>
                </EmployeeView>
                <ContactView>
                    <Box width={220} pt={2}>
                        <Box pb={.5}>
                            <LinearProgress variant="determinate" value={data.overallProgress} color={'primary'}/>
                        </Box>
                        {Math.ceil(data.overallProgress)}%
                    </Box>
                </ContactView>
            </Box>
            <Box pl={2} pb={2} pr={2} display={"flex"} alignItems={'space-between'}>
                <Box display={'flex'} alignItems={'flex-start'} flexGrow={1}>
                    <Box pr={1}>
                        <Chip size={"small"} variant={'outlined'}
                              label={xbytes(data.migrationDataAmount as number)}/>
                    </Box>
                    <Box>
                        <Chip size={"small"}
                              label={renderDealType(data.dealType, 'caption')}/>
                    </Box>
                </Box>

                <Box display={'flex'} alignItems={'center'} pr={2}>
                    <Box pr={1}>
                        <Typography variant={'caption'}>Est.:</Typography>
                    </Box>
                    <Path chipSize={'small'} from={shortenDate(data.project.projectTimeFrameFrom)}
                          to={shortenDate(data.project.projectTimeFrameTo)}/>
                </Box>
                <Box display={'flex'} alignItems={'center'}>
                    <Box pr={1}>
                        <Typography variant={'caption'}>Actual:</Typography>
                    </Box>
                    <Path chipSize={'small'} from={shortenDate(data.project.projectStartDate)}
                          to={shortenDate(data.project.projectEndDate)}/>
                </Box>

            </Box>
        </Card>
    </Box>
})


// ======================
// MigrationProjectMobileCard
// ======================

interface MigrationProjectMobileCardProps {
    data: PROJECT_TYPE
}

export const MigrationProjectMobileCard: React.FC<MigrationProjectMobileCardProps> = observer((props) => {

    const {data} = props;
    const styles = useMigrationProjectListStyles(props);

    return <Box pb={2}>
        <Card>
            <Box pl={2} pt={1} pb={1} className={styles.cardTitleBar}>
                <Typography variant={'overline'}>ID: {data.project.projectCode}</Typography>
            </Box>
            <Box pt={0} pr={2} pl={1} pb={1} className={styles.cardTitleBar}>
                <Box>
                    <Link to={`${APP_ROUTES.MIGRATION}/${data.projectId}`} className={styles.link}
                          data-testid={`${data.project.projectName}Details`}>
                        <Tooltip title={data.project.projectName}>

                            <Button><Typography variant={'h5'}
                                                align={'left'}>{shortenName(data.project.projectName, 20)}</Typography></Button>
                        </Tooltip>
                    </Link>
                </Box>
                <Box>
                    <StatusIcon status={data.project.status}/>
                </Box>
            </Box>
            <Box pl={2} pr={2} pb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Typography variant={'h6'}>{xbytes(data.migrationDataAmount as number)}</Typography>
                <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                    <Box display={'flex'} alignItems={'center'}>
                        <Box pr={1}>
                            <Typography variant={'caption'}>Est.:</Typography>
                        </Box>
                        <Path string from={shortenDate(data.project.projectTimeFrameFrom)}
                              to={shortenDate(data.project.projectTimeFrameTo)}/>
                    </Box>
                    <Box display={'flex'} alignItems={'center'}>
                        <Box pr={1}>
                            <Typography variant={'caption'}>Actual:</Typography>
                        </Box>
                        <Path string from={shortenDate(data.project.projectStartDate)}
                              to={shortenDate(data.project.projectEndDate)}/>
                    </Box>
                </Box>

                {/*<Box className={styles.path}>
                    <Typography variant={'h6'}>
                        Est. &nbsp;
                    </Typography>

                    <Typography variant={'h6'}>
                        {shortenDate(data.project.projectTimeFrameFrom)}
                        &nbsp;
                    </Typography>
                    <FaLongArrowAltRight/>
                    <Typography variant={'h6'}>
                        &nbsp;
                        {shortenDate(data.project.projectTimeFrameTo)}
                    </Typography>
                </Box>*/}

            </Box>
            <Box pl={2} pr={2} pb={2} className={styles.mobileCardProgressLine}>
                <EmployeeView>
                    <Box width={'100%'} className={styles.progressMeter}>
                        <LinearProgress variant="determinate" value={data.weightedOverallProgress} color={'primary'}/>
                        <Typography variant={'caption'}>
                            Progress: {Math.ceil(data.weightedOverallProgress)}% (w) | {Math.ceil(data.overallProgress)}%
                            (o)
                        </Typography>
                    </Box>
                </EmployeeView>
                <ContactView>
                    <Box width={'100%'} className={styles.progressMeter}>
                        <LinearProgress variant="determinate" value={data.overallProgress} color={'primary'}/>
                        <Typography variant={'caption'}>
                            Progress: {Math.ceil(data.overallProgress)}%
                        </Typography>
                    </Box>
                </ContactView>

            </Box>
            <Box>

            </Box>
            <Box pl={2} pr={2} pb={2} className={styles.cardTitleBar}>
                <EmployeeView>
                    <Chip label={renderDealType(data.dealType, 'caption')} size={'small'}/>
                </EmployeeView>
                <ContactView>
                    <Box width={5}/>
                </ContactView>
                <Box className={styles.path}>
                    <Box pr={.5}>
                        <Typography variant={'caption'}>
                            {shortenName(data.migrationSource, 20)}
                        </Typography>
                    </Box>

                    <FaLongArrowAltRight/>
                    <Box pl={.5}>
                        <Typography variant={'caption'}>
                            {shortenName(data.migrationDestination, 20)}
                        </Typography>
                    </Box>
                </Box>
            </Box>

        </Card>
    </Box>
})

// ======================
// ProjectTableFilterForm
// ======================

interface ProjectTableFilterFormProps<T> {
    tableState: TableState<T>

}

export const ProjectTableFilterForm: React.FC<ProjectTableFilterFormProps<PROJECT_FILTERS_TYPE>> = observer((props) => {

    const {tableState} = props;

    const {migrationProjectService} = useAppServices();

    const styles = useMigrationProjectDetailsStyles(props);

    const getInitialValues = () => {
        let initialValues: { engineer: { name: string, email: string }, projectStatus: { [key in MIGRATION_PROJECT_STATUS]?: boolean }, projectName: string }
        if (tableState.filterOn) {
            initialValues = {
                engineer: tableState.filterLabels.engineer,
                projectStatus: tableState.filterLabels.projectStatus,
                projectName: tableState.filterLabels.projectName
            }
        } else {
            initialValues = {
                engineer: null,
                projectStatus: {
                    [MIGRATION_PROJECT_STATUS.APPROVED]: true,
                    [MIGRATION_PROJECT_STATUS.IN_PROGRESS]: true,
                    [MIGRATION_PROJECT_STATUS.ON_HOLD]: true,
                    [MIGRATION_PROJECT_STATUS.COMPLETED]: true,
                },
                projectName: ''
            }
        }

        return initialValues;
    }

    const schema = yup.object({
        engineer: yup.object({
            name: yup.string(),
            email: yup.string()
        }).nullable().label('Employee Rep'),
        projectStatus: yup.object(
            {
                [MIGRATION_PROJECT_STATUS.APPROVED]: yup.boolean(),
                [MIGRATION_PROJECT_STATUS.IN_PROGRESS]: yup.boolean(),
                [MIGRATION_PROJECT_STATUS.ON_HOLD]: yup.boolean(),
                [MIGRATION_PROJECT_STATUS.COMPLETED]: yup.boolean(),
            }
        ).label('Project Status'),
        projectName: yup.string().notRequired()

    });

    const renderStatusCheckboxes = (values: FormikValues) => {
        return Object.values(MIGRATION_PROJECT_STATUS).filter(s => s !== MIGRATION_PROJECT_STATUS.PROPOSED).map(s => {
            const initialChecked = values.projectStatus[s]
            return <FormCheckboxItem key={s} label={renderStatusText(s)} name={`projectStatus.${s}`}
                                     defaultChecked={initialChecked}/>
        })
    }

    return <Formik initialValues={getInitialValues()} validationSchema={schema} onSubmit={async (values, actions) => {

        const valuesToFilter: PROJECT_FILTERS_TYPE = {
            engineer: values.engineer?.email || null,
            projectStatus: values.projectStatus,
            projectName: values.projectName
        }

        const filterLabelValues: { engineer: { name: string, email: string }, projectStatus: { [key in MIGRATION_PROJECT_STATUS]?: boolean }, projectName: string } = {
            engineer: values.engineer || null,
            projectStatus: values.projectStatus,
            projectName: values.projectName
        }

        await tableState.setFilterLabels(filterLabelValues);
        await tableState.setFilterValues(valuesToFilter);

        actions.setSubmitting(false)

    }}>
        {props => {
            return <Box>
                <Box pb={1}>
                    <FormTextField label={'Project Name'} name={'projectName'} variant={'standard'}/>
                </Box>
                <Box pb={4}>
                    <AutocompleteFormField name={'engineer'}
                                           autocompleteState={migrationProjectService.employeeAutocompleteState}
                                           label={'CDS Representative'}
                                           getOptionLabel={data => data.name}
                                           getOptionFormat={(item: EMPLOYEE_TYPE) => {
                                               return {
                                                   name: `${item.firstName} ${item.lastName}`,
                                                   email: item.email
                                               }
                                           }}
                    />
                </Box>

                <Box>
                    <Typography>Project Status:</Typography>
                    {renderStatusCheckboxes(props.values)}
                </Box>
                <Box className={styles.flexJustifyCenter} p={2}>
                    <Button color={'primary'} variant={'contained'} disableElevation
                            disabled={props.isSubmitting || !Object.values(props.values.projectStatus).find(v => v === true)}
                            onClick={(e) => {
                                e.preventDefault();
                                props.submitForm()
                            }}><Box pl={3} pr={3}>{'Apply Filters'}</Box></Button>
                </Box>
                <Box className={styles.flexJustifyCenter} pb={2}>
                    <Button color={'primary'} variant={'outlined'} disableElevation
                            disabled={props.isSubmitting || !tableState.filterOn}
                            onClick={(e) => {
                                e.preventDefault();
                                tableState.resetFilters()
                            }}><Box pl={3} pr={3}>{'Clear Filters'}</Box></Button>
                </Box>
            </Box>

        }}
    </Formik>
})

