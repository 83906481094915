import {ServerAPI} from "../ServerAPI";
import {APIError} from "../../../common/errors";
import {AxiosRequestConfig} from "axios";

export class MockAPI extends ServerAPI {

    apiCalls: Array<MockAPIConfig<any>> = [];

    mockApiCall(config: MockAPIConfig<any>){
        this.apiCalls.push(config)
    }

    async GET(url:string, opts:AxiosRequestConfig={}): Promise<any>{
        const filteredCallList = this.apiCalls.filter(call=>call.url===url && call.method==='GET')
        if (filteredCallList.length>0){
            let lastCallIndex = filteredCallList.length-1
            if (filteredCallList[lastCallIndex].returnStatus>200){
                throw new APIError('error', filteredCallList[lastCallIndex].returnStatus);
            } else{
                if (filteredCallList[lastCallIndex].onAPICalled){
                    let params = opts.params? opts.params: null;
                    filteredCallList[lastCallIndex].onAPICalled(filteredCallList[lastCallIndex].returnData, params)
                }

                return filteredCallList[lastCallIndex].returnData
            }

        } else {
            throw `GET api mock to ${url} not configured`
        }

    }

    async POST(url: string, data: any, opts:AxiosRequestConfig={}): Promise<any>{
        const filteredCallList = this.apiCalls.filter(call=>call.url===url && call.method==='POST')
        if (filteredCallList.length>0){
            let lastCallIndex = filteredCallList.length-1
            if (filteredCallList[lastCallIndex].returnStatus>200){
                throw new APIError('error', filteredCallList[lastCallIndex].returnStatus);
            } else{
                if (filteredCallList[lastCallIndex].onAPICalled){
                    let params = opts.params? opts.params: null;
                    filteredCallList[lastCallIndex].onAPICalled(filteredCallList[lastCallIndex].returnData, params, data)
                }
                return filteredCallList[lastCallIndex].returnData
            }
        } else {
            throw `POST api mock to ${url} not configured`
        }

    }

    async PUT(url: string, data: any, opts:AxiosRequestConfig={}): Promise<any>{
        const filteredCallList = this.apiCalls.filter(call=>call.url===url && call.method==='PUT')
        if (filteredCallList.length>0){
            let lastCallIndex = filteredCallList.length-1
            if (filteredCallList[lastCallIndex].returnStatus>200){
                throw new APIError('error', filteredCallList[lastCallIndex].returnStatus);
            } else{
                if (filteredCallList[lastCallIndex].onAPICalled){
                    let params = opts.params? opts.params: null;
                    filteredCallList[lastCallIndex].onAPICalled(filteredCallList[lastCallIndex].returnData, params, data)
                }
                return filteredCallList[lastCallIndex].returnData
            }
        } else {
            throw `PUT api mock to ${url} not configured`
        }

    }


}

export interface MockAPIConfig<T> {
    method: 'GET' | 'POST' | 'PUT',
    url : string, //match url
    returnStatus : number // 200 by default,
    returnData : T,
    onAPICalled ?: (dataReturned: T, params?: any, body?: any) => any
}



