import React from "react";
import {observer} from "mobx-react-lite";
import TableContainer, {TableContainerBaseProps} from "@material-ui/core/TableContainer";
import {
    Box,
    Button,
    Card, makeStyles,
    Paper,
    TableCell,
    TableFooter,
    TablePagination,
    TableRow, Theme,
    Typography, withStyles
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import {
    COLUMN_TYPE, FilterFormProps,
    PROJECT_ACTIVITIES_TYPE,
    PROJECT_LIST_TYPE, TABLE_DATA_TYPE,
} from "../../modules/migration/MigrationProjectTypes";
import {TableState} from "./TableState";
import {FixedSizeList as List} from 'react-window';
import {MdFilterList} from "react-icons/all";
import {parseISO} from "date-fns";
import {PopoverButton} from "../popover/PopoverButton";
import {blue} from "@material-ui/core/colors";

const useTableStyles = makeStyles((t: Theme) => ({
    flexJustifyCenter: {
        display: 'flex',
        justifyContent: 'center',
        padding: t.spacing(2)
    },
    filterButton: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingTop: t.spacing(1),
        paddingRight: t.spacing(1),
        paddingLeft: t.spacing(1)
    }
}))


// ======================
// PSTable
// ======================

interface PSTableProps<T> {
    tableData: TABLE_DATA_TYPE,
    columns: Array<COLUMN_TYPE>,
    rowComponent: React.FC<any>
    tableState: TableState<T>
    refreshFunction?: () => Promise<TABLE_DATA_TYPE>;
    densePadding?: boolean;
    noPagination?: boolean;
    containerComponent?: React.ElementType<TableContainerBaseProps>;
    filterForm?: React.FC<FilterFormProps<T>>
    rowProps?: object;
}

export const PSTable: React.FC<PSTableProps<unknown>> = observer((props) => {

    const TableCellNoBorder = withStyles({
        root: {
            borderBottom: "none"
        }
    })(TableCell);

    const {columns, tableData, tableState, refreshFunction, densePadding, noPagination, containerComponent, rowProps, ...p} = props;
    const RowComponent = props.rowComponent;

    const FilterForm = p.filterForm

    const styles = useTableStyles(props);

    const renderRows = () => {
        if (!tableData) {
            return null;
        } else {
            return tableData.items.map(data => {
                return <RowComponent data={data} key={tableData.items.indexOf(data)} {...rowProps}/>
            })
        }
    }

    const renderColumns = () => {
        return columns.map(column => {
            return <TableCell key={columns.indexOf(column)}>
                {column.label}
            </TableCell>
        })
    }

    return (<>
            <TableContainer component={containerComponent ? containerComponent : Paper}>
                <Box className={styles.filterButton}>
                    <PopoverButton disabled={!FilterForm} buttonColor={tableState.filterOn ? 'secondary' : 'default'}
                                   popoverState={tableState.filterPopoverState} buttonLabel={'Filter'}
                                   buttonIcon={<MdFilterList/>}>
                        {FilterForm && <FilterForm tableState={tableState}/>}
                    </PopoverButton>
                </Box>

                <Table size={densePadding ? 'small' : 'medium'}>
                    <TableHead>
                        <TableRow>
                            {renderColumns()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            tableData.items.length === 0 &&
                            <TableRow>
                                <TableCellNoBorder colSpan={columns.length}>
                                    <Typography className={styles.flexJustifyCenter} variant={'h5'}>
                                        No Table Data.
                                    </Typography>
                                </TableCellNoBorder>
                            </TableRow>

                        }
                        {renderRows()}
                    </TableBody>
                </Table>
                {!noPagination &&
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={tableData.pagination.total}
                    rowsPerPage={tableState.pageSize}
                    page={tableState.page}
                    onChangePage={(event: object, page: number) => {
                        tableState.changePage(page);
                        refreshFunction()
                    }}
                />
                }

            </TableContainer>

        </>
    )
})

export const createColumn = (name: string, label: string) => {
    return {name, label}
}

// ======================
// PSCardList
// ======================

interface PSCardListProps<T> {
    tableData: TABLE_DATA_TYPE,
    cardComponent: React.FC<any>,
    filterDataFunction?: (item: any) => boolean,
    cardProps?: object;
    tableState: TableState<T>;
    refreshFunction?: ()=>Promise<TABLE_DATA_TYPE>;
    filterForm?: React.FC<FilterFormProps<T>>
    noPagination?: boolean;
}

export const PSCardList: React.FC<PSCardListProps<unknown>> = observer((props) => {

    const {tableData, filterDataFunction, cardProps, noPagination, tableState, refreshFunction} = props;

    const CardComponent = props.cardComponent;

    const styles = useTableStyles(props);

    const FilterForm = props.filterForm;


    const renderCards = () => {
        if (!tableData || !tableData.items.length) {
            return <Box display={'flex'} justifyContent={'center'} alignItems={'center'}><Typography variant={'h5'} color={'textSecondary'}>No Table Data</Typography></Box>
        } else if (!!filterDataFunction) {
            return tableData.items.filter(filterDataFunction).map(data => {
                return <CardComponent data={data} key={tableData.items.indexOf(data)} {...cardProps}/>
            })
        } else {
            return tableData.items.map(data => {
                return <CardComponent data={data} key={tableData.items.indexOf(data)} {...cardProps}/>
            })
        }
    }

    return (<>
            <Box display={'flex'} justifyContent={'space-between'} pb={2}>
                <Box className={styles.filterButton}>
                    <PopoverButton disabled={!FilterForm} buttonColor={tableState.filterOn ? 'secondary' : 'default'}
                                   popoverState={tableState.filterPopoverState} buttonLabel={'Filter'}
                                   buttonIcon={<MdFilterList/>}>
                        {FilterForm && <FilterForm tableState={tableState}/>}
                    </PopoverButton>
                </Box>
                {!noPagination &&
                <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={tableData.pagination.total}
                    rowsPerPage={tableState.pageSize}
                    page={tableState.page}
                    onChangePage={(event: object, page: number) => {
                        tableState.changePage(page);
                        refreshFunction()
                    }}
                />
                }
            </Box>


            <Box>
                {renderCards()}
            </Box>
            {!noPagination &&
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={tableData.pagination.total}
                rowsPerPage={tableState.pageSize}
                page={tableState.page}
                onChangePage={(event: object, page: number) => {
                    tableState.changePage(page);
                    refreshFunction()
                }}
            />
            }
        </>
    )
})