// ======================
// QuestionnaireEditForm
// ======================

import {MIGRATION_PROJECT_QUESTIONNAIRE_TYPE, MIGRATION_PROJECT_SITE_TYPE} from "../../MigrationProjectTypes";
import React from "react";
import {observer} from "mobx-react-lite";
import * as yup from "yup";
import {FieldArray, Formik, FormikHelpers} from "formik";
import {format} from "date-fns";
import {
    AppBar,
    Box,
    Button,
    Card, Collapse,
    Grid,
    IconButton,
    InputAdornment,
    SvgIcon,
    Toolbar,
    Typography
} from "@material-ui/core";
import {FormCheckboxItem, FormDatePicker, FormSelect, FormTextField} from "../../../../common/form/FormComponents";
import {useAppServices} from "../../../app/AppStates";
import {FaAngleDown, FaAngleUp, IoMdClose, MdAdd, MdDelete} from "react-icons/all";
import {MigrationProjectService} from "../../MigrationProjectService";
import {ProjectSiteSections, useQuestionnaireStyles} from "./MigrationProjectQuestionnaireView";

interface QuestionnaireEditFormProps {
    data: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
    updateQuestionnaireFunction: (values: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE) => Promise<any>;
    closeEditView: () => void;
}

export const QuestionnaireEditForm: React.FC<QuestionnaireEditFormProps> = observer((props) => {
    const {data, updateQuestionnaireFunction, closeEditView} = props;

    const {migrationProjectService} = useAppServices();
    const initialValues: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE = data;

    const userInfoSchema = yup.object({
        name: yup.string().required('Enter name.'),
        email: yup.string().required('Enter email.'),
        phone: yup.string().nullable(),
    })

    const clientInfoSchema = yup.object({
        name: yup.string().nullable(),
        email: yup.string().nullable(),
        phone: yup.string().nullable(),
        company: yup.string().required('Enter company name.')
    })

    const sanConfigSchema = yup.object({
        aix: yup.mixed().nullable(),
        esx: yup.mixed().nullable(),
        hpux: yup.mixed().nullable(),
        linux: yup.mixed().nullable(),
        solaris: yup.mixed().nullable(),
        windows: yup.mixed().nullable()
    });

    const siteInfoSchema = yup.object().shape({
        description: yup.string().nullable(),
        customer: clientInfoSchema,
        location: yup.object().shape({
            source: yup.object().shape({
                address: yup.string().nullable(),
                onsite_contact: yup.object().shape({
                    name: yup.string().nullable(),
                    email: yup.string().nullable(),
                    phone: yup.string().nullable()
                }),
                escort_required: yup.boolean().nullable(),
                security_requirements: yup.string().nullable(),
                installation_permission: yup.string().nullable()
            })
        })
    })

    const additionalSiteSchema = yup.object().shape({
        source_storage: yup.object().shape({
            total_storage_involved: yup.number().required('Enter number of source storage.'),
            total_capacity: yup.number().required('Enter total source storage capacity in TiB.'),
            san_connectivity: yup.string().required('Enter connectivity type.'),
            iscsi: yup.object().nullable().shape({
                switch_report: yup.mixed(),
                array_performance_stats: yup.mixed(),
                host_performance_stats: yup.mixed(),
                adaptors: yup.array().of(
                    yup.object().shape({
                        make: yup.string().required('Enter make.'),
                        model: yup.string().required('Enter model.')
                    })
                )
            }),
            entries: yup.array().of(
                yup.object().shape({
                    make: yup.string().required('Enter source storage make.'),
                    model: yup.string().required('Enter source storage model.'),
                    targets: yup.string().required('Enter number of targets.'),
                    credentials: yup.boolean().nullable(),
                    source_storage_report: yup.mixed().nullable()
                })
            )
        }),
        source_fabric: yup.object().shape({
            vendor: yup.string().required('Enter source fabric vendor.'),
            model: yup.string(),
            link_speed: yup.number().required('Enter source fabric link speed in Gbps.').nullable(),
            vsan_enabled: yup.boolean().nullable(),
            credentials: yup.boolean().nullable(),
            port_zone: yup.boolean().nullable(),
            switch_report_file: yup.mixed().nullable(),
            fabric_restrictions: yup.string().nullable(),
        }),
        san_config: yup.object({
            cluster: sanConfigSchema,
            average_initiators: sanConfigSchema,
            total_hosts: sanConfigSchema,
            san_boot: sanConfigSchema,
            host_type_description: yup.string().nullable(),
            blade_server_notes: yup.string().nullable(),
            lpar_notes: yup.string().nullable(),

        }),
        destination: yup.object().shape({
            total_storage_involved: yup.number().required('Enter number of destination storage involved.'),
            entries: yup.array().of(
                yup.object().shape({
                    model: yup.string().required('Enter destination storage model.'),
                    credentials: yup.boolean().nullable()
                })
            )
        }),
        network: yup.object().shape({
            local: yup.object().shape({
                firewall_restrictions: yup.boolean().nullable(),
                firewall_rules_modifiable: yup.boolean().nullable(),
                network_security_notes: yup.string().nullable()
            }),
            remote: yup.object().shape({
                remote_access_availability: yup.string().nullable()
            })
        }),
        project_time_frame: yup.object().shape({
            must_start_date: yup.string().matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, "Must be in MM/DD/YYYY Format.").required('Enter required start date.'),
            must_complete_date: yup.string().matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, "Must be in MM/DD/YYYY Format.").required('Enter required complete date.'),
            expected_start_date: yup.string().matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, "Must be in MM/DD/YYYY Format.").required('Enter expected start date.'),
            expected_complete_date: yup.string().matches(/(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d/, "Must be in MM/DD/YYYY Format.").required('Enter expected complete date.'),
        }),
        site_info: siteInfoSchema
    })

    const validationSchema = yup.object({
        misc: yup.object({
            deal_identifier: yup.string().required('Enter Salesforce Deal Identifier.')
        }),
        user: userInfoSchema,
        network: yup.object({
            local: yup.object({
                firewall_restrictions: yup.boolean().nullable(),
                network_security_notes: yup.string().nullable()
            }),
            remote: yup.object({
                remote_access_availability: yup.string().nullable()
            })
        }),
        site_info: yup.object({
            customer: clientInfoSchema,
            location: yup.object({
                source: yup.object({
                    address: yup.string().nullable(),
                    onsite_contact: userInfoSchema,
                    escort_required: yup.boolean().nullable(),
                    security_requirements: yup.string().nullable(),
                    installation_permission: yup.string().nullable()
                })
            })
        }),
        san_config: yup.object({
            cluster: sanConfigSchema,
            average_initiators: sanConfigSchema,
            total_hosts: sanConfigSchema,
            san_boot: sanConfigSchema,
            host_type_description: yup.string().nullable(),
            blade_server_notes: yup.string().nullable(),
            lpar_notes: yup.string().nullable(),

        }),
        destination: yup.object({
            entries: yup.array().of(yup.object({
                model: yup.string().required('Enter model.'),
                credentials: yup.boolean().nullable()
            })),
            total_storage_involved: yup.number().required('Enter number of destination storage involved.'),
        }),
        source_fabric: yup.object().shape({
            vendor: yup.string().required('Enter source fabric vendor.'),
            model: yup.string(),
            link_speed: yup.number().required('Enter source fabric link speed in Gbps.'),
            vsan_enabled: yup.boolean().nullable(),
            credentials: yup.boolean().nullable(),
            port_zone: yup.boolean().nullable(),
            switch_report_file: yup.mixed().nullable(),
            fabric_restrictions: yup.string().nullable(),
        }),
        source_storage: yup.object().shape({
            total_storage_involved: yup.number().required('Enter number of source storage.'),
            total_capacity: yup.number().required('Enter total source storage capacity in TiB.'),
            san_connectivity: yup.string().required('Enter connectivity type.'),
            iscsi: yup.object().nullable().shape({
                switch_report: yup.mixed(),
                array_performance_stats: yup.mixed(),
                host_performance_stats: yup.mixed(),
                adaptors: yup.array().of(
                    yup.object().shape({
                        make: yup.string().required('Enter make.'),
                        model: yup.string().required('Enter model.')
                    })
                )
            }),
            entries: yup.array().of(
                yup.object().shape({
                    make: yup.string().required('Enter source storage make.'),
                    model: yup.string().required('Enter source storage model.'),
                    targets: yup.string().required('Enter number of targets.'),
                    credentials: yup.boolean().nullable(),
                    source_storage_report: yup.mixed().nullable()
                })
            )
        }),
        project_time_frame: yup.object().shape({
            must_start_date: yup.date().required('Enter required start date.').typeError('Enter a valid date.'),
            must_complete_date: yup.date().required('Enter required complete date.').typeError('Enter a valid date.'),
            expected_start_date: yup.date().required('Enter expected start date.').typeError('Enter a valid date.'),
            expected_complete_date: yup.date().required('Enter expected complete date.').typeError('Enter a valid date.'),
        }),
        additional_site_info: yup.array().of(additionalSiteSchema)
    })

    const onSubmit = async (values: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE, actions: FormikHelpers<MIGRATION_PROJECT_QUESTIONNAIRE_TYPE>) => {
        const additional_site_info = values.additional_site_info || [];
        let formattedValues = formatDataForSubmission(values);
        const formattedAdditionalSites = additional_site_info.map((s, i) => {
            return formatDataForSubmission(s)
        })

        const valuesForSubmission: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE = {
            user: values.user,
            additional_site_info: formattedAdditionalSites,
            misc: values.misc,
            ...formattedValues
        }

        await updateQuestionnaireFunction(valuesForSubmission)
        actions.setSubmitting(false)
        closeEditView()

    }

    return <Box>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {props => {
                return <Box>
                    <Box>
                        <UserInfoSection/>
                    </Box>
                    <Box pt={2}>
                        <ProjectSiteEditSections migrationProjectService={migrationProjectService}
                                                 values={props.values}
                                                 initialValues={initialValues}
                                                 setFieldValue={props.setFieldValue}
                        />
                    </Box>

                    <AdditionalSitesSection migrationProjectService={migrationProjectService}
                                            item={props.values}
                                            setFieldValue={props.setFieldValue}
                    />
                    <Box>
                        <MiscSection/>
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} pt={4}>
                        <Button color={'primary'} variant={'contained'} disableElevation
                                disabled={props.isSubmitting}
                                onClick={async (e) => {
                                    e.preventDefault();
                                    //console.log(props.values)
                                    const validationErrors = await props.validateForm(props.values);
                                    if (Object.keys(validationErrors).length > 0) {
                                        //console.log(validationErrors)
                                        migrationProjectService.dialogService.addAlertDialog('Form Field Error', `Please review the form for errors.`).then(
                                            value => {
                                                if (value) {
                                                    migrationProjectService.openAll();
                                                    window.scrollTo(0, 0)
                                                }
                                            })
                                    }
                                    await props.submitForm();
                                }}>
                            <Box pl={5} pr={5}>{'Save'}</Box>
                        </Button>
                    </Box>
                </Box>
            }}
        </Formik>
    </Box>
})

// ======================
// UserInfoSection
// ======================

interface UserInfoSectionProps {
}

export const UserInfoSection: React.FC<UserInfoSectionProps> = observer((props) => {

    return (
        <Box>
            <Typography variant={'h5'}>Contact Information</Typography>
            <Box pt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <FormTextField label={'Your Name'} name={'user.name'} required/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextField label={'Your Email'} name={'user.email'} required/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextField label={'Your Phone'} name={'user.phone'}/>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
})

// ======================
// ProjectSiteSections
// ======================

interface ProjectSiteEditSectionsProps {
    values: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
    initialValues: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    migrationProjectService: MigrationProjectService;
    additionalSiteIndex?: number;
    handleDelete?: (index: number) => void;
}

export const ProjectSiteEditSections: React.FC<ProjectSiteEditSectionsProps> = observer((props) => {
    const {additionalSiteIndex} = props;
    const index = additionalSiteIndex + 1 || 0;

    const open = props.migrationProjectService.openStates[index];
    const styles = useQuestionnaireStyles(props);
    return <Card variant={'outlined'}>
        <AppBar position={'static'} elevation={0}
                onClick={() => props.migrationProjectService.toggleOpenState(index)}
                className={styles.collapsibleAppBar}>
            <Toolbar>
                <Box width={'100%'} display={'flex'} justifyContent={'space-between'} p={1} alignItems={'center'}>
                    <Typography variant={'h6'}>
                        {additionalSiteIndex >= 0 ? `Site ${additionalSiteIndex + 2}` : 'Primary Site'}
                    </Typography>
                    <SvgIcon htmlColor={'rgb(0,0,0,.6)'}>
                        {open ? <FaAngleUp/> : <FaAngleDown/>}
                    </SvgIcon>
                </Box>
            </Toolbar>
        </AppBar>
        <Collapse in={open}>
            <Box p={2}>
                {!!index &&
                <Box display={'flex'} pb={2} justifyContent={'flex-end'} className={styles.projectSiteDeleteButton}>
                    <Button variant={'outlined'} color={"inherit"} startIcon={<MdDelete/>}
                            onClick={() => props.handleDelete(props.additionalSiteIndex)}>Delete</Button>
                </Box>
                }
                <Box>
                    <DeploymentSiteSection additionalSiteIndex={additionalSiteIndex}/>
                </Box>
                <Box>
                    <SourceStorageEditSection item={props.values} initialValues={props.initialValues}
                                              setFieldValue={props.setFieldValue} additionalSiteIndex={additionalSiteIndex}/>
                </Box>
                <Box>
                    <SourceFabricEditSection item={props.values} additionalSiteIndex={additionalSiteIndex}/>
                </Box>
                <Box>
                    <HostsAndVolumesEditSection additionalSiteIndex={additionalSiteIndex}/>
                </Box>
                <Box>
                    <DestinationStorageEditSection item={props.values} additionalSiteIndex={additionalSiteIndex}/>
                </Box>
                <Box>
                    <NetworkSection additionalSiteIndex={additionalSiteIndex}/>
                </Box>
                <Box>
                    <TimeFrameSection additionalSiteIndex={additionalSiteIndex}/>
                </Box>
            </Box>

        </Collapse>

    </Card>
})

// ======================
// SourceStorageSection
// ======================

interface SourceStorageEditSectionProps {
    item: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
    initialValues: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
    setFieldValue: Function;
    additionalSiteIndex?: number;

}


// ======================
// IscsiFields
// ======================

interface IscsiFieldsProps {
    item: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
    initialValues: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
    setFieldValue: Function;
    additionalSiteIndex?: number;

}

export const IscsiFields: React.FC<IscsiFieldsProps> = observer((props) => {
    const {item, initialValues, setFieldValue, additionalSiteIndex} = props;
    const {dialogService} = useAppServices();


    const connectivity = additionalSiteIndex === undefined ? item.source_storage.san_connectivity : item.additional_site_info[additionalSiteIndex].source_storage.san_connectivity;
    const iscsiInitialValues = additionalSiteIndex === undefined ? item.source_storage.iscsi : item.additional_site_info[additionalSiteIndex].source_storage.iscsi;
    React.useEffect(() => {
        if (connectivity === 'FC') {
            setFieldValue(`${additionalSiteIndex >=0 ? 'additional_site_info' : ''}${additionalSiteIndex >= 0 ? `[${additionalSiteIndex}]` : ''}source_storage.iscsi`, null)
        }
        if (connectivity === 'iSCSI') {
            if (!iscsiInitialValues){
                setFieldValue(`${additionalSiteIndex >=0 ? 'additional_site_info' : ''}${additionalSiteIndex >= 0 ? `[${additionalSiteIndex}]` : ''}source_storage.iscsi`, {
                    switch_report: null,
                    host_performance_stats: null,
                    array_performance_stats: null,
                    adaptors: [{
                        make: '',
                        model: ''
                    }]}
                )

            }
        }
    }, [connectivity])

/*    const values = {
        switch_report: null,
            host_performance_stats: null,
        array_performance_stats: null,
        adaptors: [{
        make: '',
        model: ''
    }]
    }*/

    if (connectivity === "iSCSI") {
        return <Box pb={4}>
            <Box pb={2}>
                <FieldArray
                    name={`${additionalSiteIndex >=0 ? 'additional_site_info' : ''}${additionalSiteIndex >= 0 ? `[${additionalSiteIndex}]` : ''}source_storage.iscsi.adaptors`}
                    render={({push, remove}) => {
                        const handleDelete = async (index: number) => {
                            const confirmed = await dialogService.addConfirmDialog('Are you sure you want to delete this iSCSI adaptor?', null, false);
                            if (confirmed) {
                                remove(index)
                            }
                        }

                        const handleAdd = () => {
                            push({
                                make: '',
                                model: '',
                            })
                        }
                        return (<Grid container spacing={3}>
                                {additionalSiteIndex === undefined && item.source_storage.iscsi?.adaptors.map((entry, index) => {
                                    return <IscsiEditFields index={index} handleDelete={handleDelete}/>
                                })}
                                {additionalSiteIndex >=0 && item.additional_site_info[additionalSiteIndex].source_storage.iscsi?.adaptors.map((entry, index) => {
                                    return <IscsiEditFields index={index} handleDelete={handleDelete}
                                                            additionalSiteIndex={additionalSiteIndex}/>
                                })}
                                <Grid item xs={12} md={4}>
                                    <Card variant={'outlined'}>
                                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}
                                             height={250}>
                                            <Box textAlign={'center'}>
                                                <Typography variant={'body1'}><b>Add An Adaptor</b></Typography>
                                                <IconButton onClick={handleAdd}><SvgIcon><MdAdd/></SvgIcon></IconButton>
                                            </Box>
                                        </Box>
                                    </Card>
                                </Grid>
                            </Grid>
                        )
                    }}/>
            </Box>

        </Box>
    } else {
        return null;
    }
})

// ======================
// IscsiEditFields
// ======================

interface IscsiEditFieldsProps {

    additionalSiteIndex?: number;
    index: number;
    handleDelete: (index: number) => void;
}

export const IscsiEditFields: React.FC<IscsiEditFieldsProps> = observer((props) => {
    const {additionalSiteIndex, index, handleDelete} = props;

    const additionalIndex = props.additionalSiteIndex;

    return <Grid item xs={12} md={4} key={index}>
        <Card variant={'outlined'}>
            <Box p={2} height={250}>
                <Box display={'flex'} justifyContent={'space-between'}
                     alignItems={'center'}>
                    <Typography variant={"subtitle2"}>iSCSI
                        Adaptor {index + 1}</Typography>
                    <IconButton
                        onClick={() => handleDelete(index)}><SvgIcon><IoMdClose/></SvgIcon></IconButton>
                </Box>

                <Box pb={2} pt={2}>
                    <FormTextField label={'Make  '}
                                   name={`${additionalIndex >=0 ? 'additional_site_info' : ''}${additionalIndex >=0 ? `[${additionalSiteIndex}]` : ''}source_storage.iscsi.adaptors[${index}].make`}
                                   required
                    />
                </Box>
                <Box pb={2}>
                    <FormTextField label={'Model  '}
                                   name={`${additionalIndex >=0 ? 'additional_site_info' : ''}${additionalIndex  >=0 ? `[${additionalSiteIndex}]` : ''}source_storage.iscsi.adaptors[${index}].model`}
                                   required
                    />
                </Box>
            </Box>
        </Card>
    </Grid>

})

export const SourceStorageEditSection: React.FC<SourceStorageEditSectionProps> = observer((props) => {
    const {item, initialValues, setFieldValue} = props;
    const index = props.additionalSiteIndex;

    return (<Box pt={5}>
            <Typography variant={'h5'}>Source Storage</Typography>
            <Box pt={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <FormTextField type={'number'} label={'Total Capacity To Be Migrated'}
                                       required
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}source_storage.total_capacity`}
                                       InputProps={{
                                           endAdornment: (
                                               <InputAdornment position={"end"}>
                                                   TiB
                                               </InputAdornment>
                                           )
                                       }}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSelect
                            name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}source_storage.san_connectivity`}
                            label={'SAN Connectivity'} selectionList={['iSCSI', 'FC']}
                            required/>
                    </Grid>
                </Grid>
            </Box>
            <Box pt={4}>
                <IscsiFields item={item} initialValues={initialValues} setFieldValue={setFieldValue} additionalSiteIndex={props.additionalSiteIndex}/>
                <SourceStorageCards item={item} additionalSiteIndex={props.additionalSiteIndex}/>
            </Box>
        </Box>
    )
})

// ======================
// SourceStorageCards
// ======================

interface SourceStorageCardsProps {
    item: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
    additionalSiteIndex?: number;

}

export const SourceStorageCards: React.FC<SourceStorageCardsProps> = observer((props) => {
    const {item, additionalSiteIndex} = props;
    const {dialogService} = useAppServices();

    return <FieldArray name={`${additionalSiteIndex >=0 ? 'additional_site_info' : ''}${additionalSiteIndex >= 0 ? `[${additionalSiteIndex}]` : ''}source_storage.entries`}
                       render={({push, remove}) => {
                           const handleDelete = async (index: number) => {
                               const confirmed = await dialogService.addConfirmDialog('Are you sure you want to delete this Source Storage?', null, false);
                               if (confirmed) {
                                   remove(index)
                               }
                           }

                           const handleAdd = () => {
                               push({
                                   make: '',
                                   model: '',
                                   targets: '',
                                   credentials: null,
                                   storage_report_file: null
                               })
                           }
                           return (<Grid container spacing={3}>
                                   {additionalSiteIndex === undefined && item.source_storage.entries.map((entry, index) => {
                                       return <SourceStorageCardFields item={item} index={index} handleDelete={handleDelete}/>
                                   })}
                                   {additionalSiteIndex >=0 && item.additional_site_info[additionalSiteIndex].source_storage.entries.map((entry, index) => {
                                       return <SourceStorageCardFields item={item} additionalSiteIndex={props.additionalSiteIndex} index={index} handleDelete={handleDelete}/>
                                   })}
                                   <Grid item xs={12} md={4}>
                                       <Card variant={'outlined'}>
                                           <Box display={'flex'} justifyContent={'center'} alignItems={'center'}
                                                height={380}>
                                               <Box textAlign={'center'}>
                                                   <Typography variant={'body1'}><b>Add A Source</b></Typography>
                                                   <IconButton
                                                       onClick={handleAdd}><SvgIcon><MdAdd/></SvgIcon></IconButton>
                                               </Box>
                                           </Box>
                                       </Card>
                                   </Grid>
                               </Grid>
                           )
                       }}/>
})

// ======================
// SourceStorageCardFields
// ======================

interface SourceStorageCardFieldsProps {
    item: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
    additionalSiteIndex?: number;
    index: number;
    handleDelete: (index: number) => void;
}

export const SourceStorageCardFields: React.FC<SourceStorageCardFieldsProps> = observer((props) => {
    const {item, additionalSiteIndex, index, handleDelete} = props;
    const entry = additionalSiteIndex ? item.additional_site_info[additionalSiteIndex].source_storage.entries[index]: item.source_storage.entries[index];
    return <Grid item xs={12} md={4}>
        <Card variant={'outlined'}>
            <Box p={2} height={380}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant={"subtitle2"}>Source
                        Storage {index + 1}</Typography>
                    <IconButton
                        onClick={() => handleDelete(index)}><SvgIcon><IoMdClose/></SvgIcon></IconButton>
                </Box>

                <Box pb={2} pt={2}>
                    <FormTextField label={'Make  '}
                                   name={`${additionalSiteIndex >=0 ? 'additional_site_info' : ''}${additionalSiteIndex >=0 ? `[${additionalSiteIndex}]` : ''}source_storage.entries[${index}].make`}
                                   required
                    />
                </Box>
                <Box pb={2}>
                    <FormTextField label={'Model  '}
                                   name={`${additionalSiteIndex  >=0? 'additional_site_info' : ''}${additionalSiteIndex >=0 ? `[${additionalSiteIndex}]` : ''}source_storage.entries[${index}].model`}
                                   disabled={entry ? !entry.make : !entry}
                                   required
                    />
                </Box>
                <Box pb={2}>
                    <FormTextField label={'Number Of Target Ports'}
                                   name={`${additionalSiteIndex >=0 ? 'additional_site_info' : ''}${additionalSiteIndex >=0 ? `[${additionalSiteIndex}]` : ''}source_storage.entries[${index}].targets`}
                                   type={'number'}
                                   disabled={entry ? !entry.make : !entry}
                                   required
                    />
                </Box>
                <Box pb={2}>
                    <FormCheckboxItem label={'Access Credentials Available'}
                                      name={`${additionalSiteIndex >=0 ? 'additional_site_info' : ''}${additionalSiteIndex >=0 ? `[${additionalSiteIndex}]` : ''}source_storage.entries[${index}].credentials`}
                    />
                </Box>
            </Box>
        </Card>
    </Grid>
})

// ======================
// SourceFabricEditSection
// ======================

interface SourceFabricEditSectionProps {
    item: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
    additionalSiteIndex?: number;

}

export const SourceFabricEditSection: React.FC<SourceFabricEditSectionProps> = observer((props) => {
    const {item} = props;
    const index = props.additionalSiteIndex;

    return (
        <Box pt={5}>
            <Typography variant={'h5'}>FC Switches and Fabrics Information</Typography>
            <Box pt={2}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <FormSelect
                            name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}source_fabric.vendor`}
                            selectionList={['Cisco', 'Brocade']}
                            label={'Vendor  '}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextField
                            name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}source_fabric.model`}
                            label={'Model  '}
                            disabled={!item.source_fabric.vendor}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextField
                            name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}source_fabric.link_speed`}
                            label={'Link Speed'}
                            type={'number'} required InputProps={{
                            endAdornment: (
                                <InputAdornment position={"end"}>
                                    Gbps
                                </InputAdornment>
                            )
                        }}
                            disabled={!item.source_fabric.vendor}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormCheckboxItem label={'Virtual Fabric / VSAN Enabled'}
                                          name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}source_fabric.vsan_enabled`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormCheckboxItem label={'Port Zone Used'}
                                          name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}source_fabric.port_zone`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormCheckboxItem label={'Switch Credentials Available'}
                                          name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}source_fabric.credentials`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField label={'Number of Licensed Free Switch Ports Per Fabric'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}source_fabric.fabric_restrictions`}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
})

// ======================
// HostsAndVolumesEditSection
// ======================

interface HostsAndVolumesEditSectionProps {
    additionalSiteIndex?: number;

}

export const HostsAndVolumesEditSection: React.FC<HostsAndVolumesEditSectionProps> = observer((props) => {
    const {additionalSiteIndex} = props;
    const index = props.additionalSiteIndex;

    return (
        <Box pt={5}>
            <Box pb={2}>
                <Typography variant={'h5'}>Hosts and Volumes to be Migrated</Typography>
            </Box>
            <Grid container spacing={2}>
                <SANConfigCard type={'windows'} additionalSiteIndex={additionalSiteIndex}/>
                <SANConfigCard type={'linux'} additionalSiteIndex={additionalSiteIndex}/>
                <SANConfigCard type={'aix'} additionalSiteIndex={additionalSiteIndex}/>
                <SANConfigCard type={'hpux'} additionalSiteIndex={additionalSiteIndex}/>
                <SANConfigCard type={'esx'} additionalSiteIndex={additionalSiteIndex}/>
                <SANConfigCard type={'solaris'} additionalSiteIndex={additionalSiteIndex}/>
                <Grid item xs={12}>
                    <Box pb={2}>
                        <FormTextField label={'Additional Description on Host OS and Types In-Scope'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}san_config.host_type_description`}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormTextField label={'Identify Vendor & Model of Blade Servers If In-Scope'}
                                   name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}san_config.blade_server_notes`}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormTextField label={'If LPARs In-Scope, Number of LPARs In-Scope to Migrate'}
                                   name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}san_config.lpar_notes`}
                    />
                </Grid>
            </Grid>
        </Box>
    )
})

// ======================
// SANConfigCard
// ======================

interface SANConfigCardProps {
    type: 'windows' | 'linux' | 'aix' | 'hpux' | 'esx' | 'solaris';
    additionalSiteIndex?: number;

}

export const SANConfigCard: React.FC<SANConfigCardProps> = observer((props) => {
    const {type} = props;
    const index = props.additionalSiteIndex;


    const titles = {
        windows: 'Windows',
        linux: 'Linux',
        aix: 'AIX',
        hpux: 'HP-UX',
        esx: 'ESX',
        solaris: 'Solaris'
    }

    return <Grid item xs={6} md={2}>
        <Box pb={4}>
            <Card variant={'outlined'}>
                <Box p={2}>
                    <Box pb={3}>
                        <Typography variant={'subtitle2'}>{titles[type]}</Typography>
                    </Box>
                    <Box pb={3}>
                        <FormTextField label={'Number of Hosts'} type={'number'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}san_config.total_hosts.${type}`}/>
                    </Box>
                    <Box pb={2}>
                        <FormTextField label={'Avg. Initiators'} type={'number'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}san_config.average_initiators.${type}`}/>
                    </Box>
                    <FormCheckboxItem label={'Cluster'}
                                      name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}san_config.cluster.${type}`}/>
                    <FormCheckboxItem label={'Boot from SAN'}
                                      name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}san_config.san_boot.${type}`}/>
                </Box>
            </Card>
        </Box>
    </Grid>
})

// ======================
// DestinationStorageEditSection
// ======================

interface DestinationStorageEditSectionProps {
    item: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE,
    additionalSiteIndex?: number;

}

export const DestinationStorageEditSection: React.FC<DestinationStorageEditSectionProps> = observer((props) => {
    const {item, additionalSiteIndex} = props;

    return (
        <Box pt={5}>
            <Box pb={2}>
                <Typography variant={'h5'}>Destination Storage</Typography>
            </Box>
            <Grid container spacing={2}>
                <DestinationStorageCardsSection item={item} additionalSiteIndex={additionalSiteIndex}/>
            </Grid>
        </Box>
    )
})

// ======================
// DestinationStorageCardsSection
// ======================

interface DestinationStorageCardsSectionProps {
    item: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE,
    additionalSiteIndex?: number;

}

export const DestinationStorageCardsSection: React.FC<DestinationStorageCardsSectionProps> = observer((props) => {

    const {item, additionalSiteIndex} = props;
    const {dialogService} = useAppServices();

    return <FieldArray name={`${additionalSiteIndex >=0 ? 'additional_site_info' : ''}${additionalSiteIndex >= 0 ? `[${additionalSiteIndex}]` : ''}destination.entries`}
                       render={({push, remove}) => {
                           const handleDelete = async (index: number) => {
                               const confirmed = await dialogService.addConfirmDialog('Are you sure you want to delete this Destination Storage?', null, false);
                               if (confirmed) {
                                   remove(index)
                               }
                           }

                           const handleAdd = () => {
                               push({
                                   model: '',
                                   credentials: null,
                               })
                           }
                           return (<>
                               {additionalSiteIndex === undefined && item.destination.entries.map((entry, index) => {
                                   return <DestinationStorageCardFields handleDelete={handleDelete} item={item} index={index}/>
                               })}
                               {additionalSiteIndex >=0 && item.additional_site_info[additionalSiteIndex].destination.entries.map((entry, index)=> {
                                   return <DestinationStorageCardFields handleDelete={handleDelete} item={item} index={index} additionalSiteIndex={additionalSiteIndex}/>
                               })}
                               <Grid item xs={12} md={4}>
                                   <Card variant={'outlined'}>
                                       <Box display={'flex'} justifyContent={'center'} alignItems={'center'}
                                            height={250}>
                                           <Box textAlign={'center'}>
                                               <Typography variant={'body1'}><b>Add A Destination</b></Typography>
                                               <IconButton onClick={handleAdd}><SvgIcon><MdAdd/></SvgIcon></IconButton>
                                           </Box>
                                       </Box>
                                   </Card>
                               </Grid>

                           </>)
                       }
                       }
    />


})

// ======================
// DestinationStorageCardFields
// ======================

interface DestinationStorageCardFieldsProps{
    item: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
    additionalSiteIndex?: number;
    index: number;
    handleDelete: (index: number) => void;
}

export const DestinationStorageCardFields: React.FC<DestinationStorageCardFieldsProps> = observer((props)=>{
    const {item, additionalSiteIndex, index, handleDelete} = props;
    return <Grid item xs={12} md={4}>
        <Card variant={'outlined'}>
            <Box p={2} height={250}>
                <Box display={'flex'} justifyContent={'space-between'}
                     alignItems={'center'} pb={2}>
                    <Typography variant={"subtitle2"}>Destination
                        Storage {index + 1}</Typography>
                    <IconButton
                        onClick={() => handleDelete(index)}><SvgIcon><IoMdClose/></SvgIcon></IconButton>
                </Box>
                <Box pb={2}>
                    <FormTextField label={'Model  '}
                                   name={`${additionalSiteIndex >=0 ? 'additional_site_info' : ''}${additionalSiteIndex >= 0 ? `[${additionalSiteIndex}]` : ''}destination.entries[${index}].model`}
                                   required
                    />
                </Box>
                <Box pb={2}>
                    <FormCheckboxItem label={'Access Credentials Available'}
                                      name={`${additionalSiteIndex >=0 ? 'additional_site_info' : ''}${additionalSiteIndex >= 0 ? `[${additionalSiteIndex}]` : ''}destination.entries[${additionalSiteIndex}].credentials`}

                    />
                </Box>
            </Box>
        </Card>
    </Grid>
})


// ======================
// NetworkSection
// ======================

interface NetworkSectionProps {
    additionalSiteIndex?: number;

}

export const NetworkSection: React.FC<NetworkSectionProps> = observer((props) => {
    const index = props.additionalSiteIndex;

    return (
        <Box pt={5}>
            <Box>
                <Typography variant={'h5'}>Network Access</Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <FormCheckboxItem label={'Local Area Network Has Firewall Restriction'}
                                      name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}network.local.firewall_restrictions`}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormCheckboxItem label={'Firewall Rules Modifiable'}
                                      name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}network.local.firewall_rules_modifiable`}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField label={'Additional Descriptions on Security Restrictions'}
                                   name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}network.local.network_security_notes`}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField label={'Secure Remote Access Availability'}
                                   name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}network.remote.remote_access_availability`}
                    />
                </Grid>
            </Grid>
        </Box>
    )
})

// ======================
// TimeFrameSection
// ======================

interface TimeFrameSectionProps {
    additionalSiteIndex?: number;
}

export const TimeFrameSection: React.FC<TimeFrameSectionProps> = observer((props) => {
    const index = props.additionalSiteIndex;

    return (
        <Box pt={5}>
            <Box pb={2}>
                <Typography variant={'h5'}>Migration Time Frame</Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                    <FormDatePicker label={'Required Start Date'}
                                    name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}project_time_frame.must_start_date`}
                                    required
                                    disablePast
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormDatePicker label={'Required End Date'}
                                    name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}project_time_frame.must_complete_date`}
                                    disablePast
                                    required
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormDatePicker label={'Expected Start Date'}
                                    name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}project_time_frame.expected_start_date`}
                                    disablePast
                                    required
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormDatePicker label={'Expected End Date'}
                                    name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}project_time_frame.expected_complete_date`}
                                    disablePast
                                    required
                    />
                </Grid>
            </Grid>
        </Box>
    )
})

// ======================
// DeploymentSiteSection
// ======================

interface DeploymentSiteSectionProps {
    additionalSiteIndex?: number;
}

export const DeploymentSiteSection: React.FC<DeploymentSiteSectionProps> = observer((props) => {

    const index = props.additionalSiteIndex;
    
    return (
        <Box pt={5}>
            <Box pb={2}>
                <Typography variant={'h5'}>Deployment Site Information</Typography>
            </Box>
            <Box pb={2}>
                <Typography variant={'subtitle2'}>Customer Contact Information</Typography>
            </Box>
            <Box pb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <FormTextField label={'Company'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}site_info.customer.company`}
                                       required/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormTextField label={'Name'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}site_info.customer.name`}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormTextField label={'Phone'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}site_info.customer.phone`}/>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormTextField label={'Email'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}site_info.customer.email`}/>
                    </Grid>
                </Grid>
            </Box>

            <Box pb={2}>
                <Typography variant={'subtitle2'}>On-Site Contact Information</Typography>
            </Box>
            <Box pb={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <FormTextField label={'Name'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}site_info.location.source.onsite_contact.name`}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextField label={'Phone'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}site_info.location.source.onsite_contact.phone`}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormTextField label={'Email'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}site_info.location.source.onsite_contact.email`}/>
                    </Grid>
                </Grid>
            </Box>
            <Box pb={2}>
                <Typography variant={'subtitle2'}>Location Information</Typography>
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormTextField label={'Address'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}site_info.location.source.address`}
                        />
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <FormTextField label={'Required Security Credentials'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}site_info.location.source.security_requirements`}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormCheckboxItem label={'Escort Required'}
                                          name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}site_info.location.source.escort_required`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField label={'Installation Permission'}
                                       name={`${index >=0 ? 'additional_site_info' : ''}${index >= 0 ? `[${index}]` : ''}site_info.location.source.installation_permission`}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
})

// ======================
// AdditionalSitesSection
// ======================

interface AdditionalSitesSectionProps {
    item: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    migrationProjectService: MigrationProjectService;
}

export const AdditionalSitesSection: React.FC<AdditionalSitesSectionProps> = observer((props) => {
    const {dialogService} = useAppServices();
    const {item, setFieldValue, migrationProjectService} = props;
    const additional_site_info = item.additional_site_info ? item.additional_site_info : [];

    const initialValues: MIGRATION_PROJECT_SITE_TYPE = {
        source_storage: {
            total_storage_involved: 1,
            total_capacity: 0,
            san_connectivity: '',
            entries: [{
                make: '',
                model: '',
                targets: '',
                credentials: null,
                storage_report_file: null
            }],
            iscsi: {
                switch_report: null,
                host_performance_stats: null,
                array_performance_stats: null,
                adaptors: []
            }
        },
        source_fabric: {
            model: '',
            vendor: '',
            port_zone: null,
            link_speed: null,
            credentials: null,
            vsan_enabled: null,
            fabric_restrictions: null,
            switch_report_file: null
        },
        san_config: {
            cluster: {
                aix: null,
                esx: null,
                hpux: null,
                linux: null,
                solaris: null,
                windows: null,
            },
            host_type_description: null,
            total_hosts: {
                aix: 0,
                esx: 0,
                hpux: 0,
                linux: 0,
                solaris: 0,
                windows: 0,
            },
            lpar_notes: '',
            san_boot: {
                aix: null,
                esx: null,
                hpux: null,
                linux: null,
                solaris: null,
                windows: null,
            },
            average_initiators: {
                aix: 0,
                esx: 0,
                hpux: 0,
                linux: 0,
                solaris: 0,
                windows: 0,
            },
            blade_server_notes: null
        },
        destination: {
            total_storage_involved: 1,
            entries: [{
                model: '',
                credentials: null
            }]
        },
        network: {
            local: {
                firewall_restrictions: null,
                firewall_rules_modifiable: null,
                network_security_notes: null
            },
            remote: {
                remote_access_availability: null
            }
        },
        project_time_frame: {
            must_start_date: format(new Date(), 'MM/dd/yyyy'),
            must_complete_date: format(new Date(), 'MM/dd/yyyy'),
            expected_start_date: format(new Date(), 'MM/dd/yyyy'),
            expected_complete_date: format(new Date(), 'MM/dd/yyyy')
        },
        site_info: {
            customer: {
                name: null,
                email: null,
                phone: null,
                company: ''
            },
            location: {
                source: {
                    address: null,
                    onsite_contact: {
                        name: null,
                        email: null,
                        phone: null
                    },
                    escort_required: null,
                    security_requirements: null,
                    installation_permission: null
                },
            }
        }
    }

    return <Box>
        <FieldArray name={'additional_site_info'} render={({push, remove}) => {
            const handleDelete = async (index: number) => {
                const confirmed = await dialogService.addConfirmDialog('Are you sure you want to delete this Deployment Site?', null, false);
                if (confirmed) {
                    remove(index);
                    migrationProjectService.deleteOpenState(index + 1);

                }
            }

            const handleAdd = (index: number) => {
                migrationProjectService.addOpenState(index);
                migrationProjectService.openStates.forEach((s, i) => {
                    if (i !== index) {
                        migrationProjectService.setClosed(i)
                    }
                })


                push(initialValues)
                window.scrollTo(0, 0)
            }
            return (<Box>
                    <Box pb={2}>
                        {additional_site_info.map((entry, index) => {
                            return (
                                <ProjectSiteEditSections values={item}
                                                         initialValues={initialValues}
                                                         setFieldValue={setFieldValue}
                                                         migrationProjectService={migrationProjectService}
                                                         additionalSiteIndex={index}
                                                         handleDelete={handleDelete}
                                />

                            )
                        })}
                    </Box>
                    <Card variant={'outlined'}>
                        <Box p={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <Box textAlign={'center'}>
                                <Typography variant={'body1'}><b>Add Deployment Site</b></Typography>
                                <IconButton
                                    onClick={() => handleAdd(migrationProjectService.openStatesLength)}><SvgIcon><MdAdd/></SvgIcon></IconButton>
                            </Box>
                        </Box>
                    </Card>

                </Box>

            )
        }}/>


    </Box>
})

// ======================
// MiscSection
// ======================

interface MiscSectionProps {
}

export const MiscSection: React.FC<MiscSectionProps> = observer((props) => {

    return (
        <Box pt={5}>
            <Box pb={2}>
                <Typography variant={'h5'}>Misc. Information</Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormTextField label={'SalesForce Deal Identifier'} name={'misc.deal_identifier'} required/>
                </Grid>
            </Grid>
        </Box>
    )
})

const formatDataForSubmission = (values: MIGRATION_PROJECT_SITE_TYPE) => {
    values.source_storage.entries.map(entry => {
        if (!entry.credentials) {
            entry.credentials = null;
        }
    })

    values.source_storage.total_storage_involved = values.source_storage.entries.length;
    values.destination.total_storage_involved = values.source_storage.entries.length;

    values.destination.entries.map(entry => {
        if (!entry.credentials) {
            entry.credentials = null;
        }
    })

    if (typeof values.project_time_frame.must_start_date !== 'string') {
        values.project_time_frame.must_start_date = format(values.project_time_frame.must_start_date as Date, 'MM/dd/yyyy')
    }
    if (typeof values.project_time_frame.must_complete_date !== 'string') {
        values.project_time_frame.must_complete_date = format(values.project_time_frame.must_complete_date as Date, 'MM/dd/yyyy')
    }
    if (typeof values.project_time_frame.expected_start_date !== 'string') {
        values.project_time_frame.expected_start_date = format(values.project_time_frame.expected_start_date as Date, 'MM/dd/yyyy')
    }
    if (typeof values.project_time_frame.expected_complete_date !== 'string') {
        values.project_time_frame.expected_complete_date = format(values.project_time_frame.expected_complete_date as Date, 'MM/dd/yyyy')
    }

    return values;
}