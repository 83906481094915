import {MIGRATION_PROJECT_PHASE, PHASE_VALUE_TYPE, PROJECT_TYPE, WAFFLE_VALUE} from "../MigrationProjectTypes";
import React from "react";
import {observer} from "mobx-react-lite";
import {useMigrationProjectDetailsStyles} from "./MigrationProjectStyles";
import {useIsDesktop} from "../../layout/MainLayout";
import {Box, Card, Divider, Grid, useTheme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {StatusIcon} from "../MigrationProjectCommon";
import {ResponsiveWaffle} from "@nivo/waffle";
import {ContactView, EmployeeView} from "../../auth/EmployeeView";
import {amber, deepOrange, orange, yellow} from "@material-ui/core/colors";

// ======================
// PsOnlyProjectStage
// ======================

interface PsOnlyProjectStageProps{
    data: PROJECT_TYPE,
}

export const PsOnlyProjectStage: React.FC<PsOnlyProjectStageProps> = observer((p)=>{
    const styles = useMigrationProjectDetailsStyles(p);
    const {data} = p;
    const waffleValues = [
        {
            "id": MIGRATION_PROJECT_PHASE.KICKOFF,
            "label": "PS Delivery",
            "value": data.progress && data.progressWeight ? getWeightedValue(data.progress.kickOff, 1) : 0,
            "color": "#468df3"
        },
        ]
    return <>
        <Box className={styles.projectStageTitle} pt={4}>
            <Typography variant={'h4'} className={styles.title}>Project Stage </Typography>
        </Box>
        <EmployeeView>
            <MeterCard waffleValues={waffleValues}
                       title={`Overall Progress: ${Math.ceil(data.weightedOverallProgress)}%`}/>
        </EmployeeView>
        <ContactView>
            <MeterCard waffleValues={waffleValues} title={`Overall Progress: ${Math.ceil(data.weightedOverallProgress)}%`}/>
        </ContactView>
    </>
})

interface ProjectStageProps {
    data: PROJECT_TYPE,
}

export const ProjectStage: React.FC<ProjectStageProps> = observer((props) => {
    const styles = useMigrationProjectDetailsStyles(props);
    const {data} = props;
    const isDesktop = useIsDesktop();

    const weightedWaffleValues = [
        {
            "id": MIGRATION_PROJECT_PHASE.KICKOFF,
            "label": "Kick-Off",
            "value": data.progress && data.progressWeight ? getWeightedValue(data.progress.kickOff, data.progressWeight.kickOff) : 0,
            "color": "#468df3"
        },
        {
            "id": MIGRATION_PROJECT_PHASE.CONFIG,
            "label": "Configuration",
            "value": data.progress && data.progressWeight ? getWeightedValue(data.progress.config, data.progressWeight.config) : 0,
            "color": "#ba72ff"
        },
        {
            "id": MIGRATION_PROJECT_PHASE.MIGRATION,
            "label": "Migration",
            "value": data.progress && data.progressWeight ? getWeightedValue(data.progress.migration, data.progressWeight.migration) : 0,
            "color": "#a1cfff"
        },
        {
            "id": MIGRATION_PROJECT_PHASE.CUTOVER,
            "label": "Cutover",
            "value": data.progress && data.progressWeight ? getWeightedValue(data.progress.cutover, data.progressWeight.cutover) : 0,
            "color": "#a1cfff"
        }
    ]

    const waffleValues = [
        {
            "id": MIGRATION_PROJECT_PHASE.KICKOFF,
            "label": "Kick-Off",
            "value": data.progress && data.progressWeight ? getPercentage(data.progress.kickOff) : 0,
            "color": "#468df3"
        },
        {
            "id": MIGRATION_PROJECT_PHASE.CONFIG,
            "label": "Configuration",
            "value": data.progress && data.progressWeight ? getPercentage(data.progress.config) : 0,
            "color": "#ba72ff"
        },
        {
            "id": MIGRATION_PROJECT_PHASE.MIGRATION,
            "label": "Migration",
            "value": data.progress && data.progressWeight ? getPercentage(data.progress.migration) : 0,
            "color": "#a1cfff"
        },
        {
            "id": MIGRATION_PROJECT_PHASE.CUTOVER,
            "label": "Cutover",
            "value": data.progress && data.progressWeight ? getPercentage(data.progress.cutover) : 0,
            "color": "#a1cfff"
        }
    ]

    return (<>
            <Box className={styles.projectStageTitle} pt={4}>
                <Typography variant={'h4'} className={styles.title}>Project Stage </Typography>
            </Box>
            <EmployeeView>
                <MeterCard waffleValues={weightedWaffleValues}
                           title={`Weighted: ${Math.ceil(data.weightedOverallProgress)}% | Overall: ${Math.ceil(data.overallProgress)}%`}/>
            </EmployeeView>
            <ContactView>
                <MeterCard waffleValues={waffleValues} title={`Overall Progress: ${Math.ceil(data.overallProgress)}%`}/>
            </ContactView>
        </>
    )
})

// ======================
// MeterCard
// ======================

interface MeterCardProps {
    waffleValues: Array<WAFFLE_VALUE>,
    title: string
}

export const MeterCard: React.FC<MeterCardProps> = observer((props) => {
    const isDesktop = useIsDesktop();
    const styles = useMigrationProjectDetailsStyles(props);
    const t = useTheme();

    const {waffleValues, title} = props;

    const colors = {
        kickOff: amber['100'],
        config: amber['300'],
        migration: orange['300'],
        cutover: deepOrange['400']
    }
    const getColors = (colors: any) => (phase: any) => colors[phase.id]

    const getLegend = () => {
        if (waffleValues.length > 1){
            return [
                {
                    anchor: 'bottom-left',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 15,
                    itemsSpacing: 0,
                    itemWidth: 75,
                    itemHeight: 15,
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    itemTextColor: '#999',
                    symbolSize: 10,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000',
                                itemBackground: '#f7fafb'
                            }
                        }
                    ]
                }
            ]
        }
        return []
    }

    return (
        <Box pt={3}>
            <Card>
                <Box p={2}>
                    <Typography variant={'h5'}>
                        {title}
                    </Typography>
                </Box>
                <Divider/>
                <Box className={styles.overallProgressMeter}>
                    <Box height={150} width={'80%'}>
                        <ResponsiveWaffle
                            data={waffleValues}
                            total={100}
                            rows={isDesktop ? 5 : 5}
                            columns={isDesktop ? 80 : 40}
                            //@ts-ignore
                            colors={getColors(colors)}
                            fillDirection="left"
                            margin={{top: 0, right: 20, bottom: 40, left: 20}}
                            borderColor={{from: 'color', modifiers: [['darker', 0.3]]}}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={11}
                            tooltip={(props) => (
                                <Box><Typography color={'primary'}>{props.label}: {Math.ceil(props.value)}%</Typography></Box>)}
                            //@ts-ignore
                            legends={getLegend()}/>
                    </Box>
                </Box>

            </Card>
        </Box>
    )
})


const getWeightedValue = (data: PHASE_VALUE_TYPE, weight: number) => {
    return (data.currentValue / data.totalValue) * 100 * weight
}

const getPercentage = (data: PHASE_VALUE_TYPE) => {
    return (data.currentValue / data.totalValue) * 25
}

