import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {
    Box,
    Button,
    Card,
    Drawer,
    Tab,
    Tabs,
    Typography,
    Link,
    MenuList,
    MenuItem,
    SvgIcon,
    useTheme, Theme
} from "@material-ui/core";
import {MigrationProjectDetailsState} from "./MigrationProjectDetailsState";
import {useAppServices} from "../../app/AppStates";
import {
    MIGRATION_PROJECT_QUESTIONNAIRE_TYPE, MIGRATION_PROJECT_STATUS,
    MigrationProjectDealType, NEW_PROJECT_FORM_INITIAL_VALUES_TYPE,
    NEW_PROJECT_FORM_VALUES_TYPE,
    PROJECT_TYPE,
} from '../MigrationProjectTypes';
import {parse} from "date-fns";
import xbytes from "xbytes";
import {MigrationProjectForm, useDialogStyles} from "../MigrationProjectDialog";
import {makeStyles} from "@material-ui/core/styles";
import {MuiTabContent} from "../../core/tab/Tabs";
import {grey} from "@material-ui/core/colors";
import {ProjectQuestionnaireView} from "./Questionnaire/MigrationProjectQuestionnaireView";
import {PopoverState} from "../../../common/popover/PopoverService";
import {PopoverIconButton} from "../../../common/popover/PopoverButton";
import {FiTrash2, MdCheck, MdMenu} from "react-icons/all";
import {useIsDesktop} from "../../layout/MainLayout";
import {APP_ROUTES} from "../../app/AppRoutes";
import { useHistory } from 'react-router-dom';
import {PSProjectStatus} from '../../../../../server/src/modules/projects/entities/PSProject';

const useMigrationProjectSettingsStyles = makeStyles((t:Theme)=>({
    settingsFrame: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    tabs: {
        display: 'flex',
        justifyContent:'flex-start',
        padding:0
    },

    editDetailsFrame: {
        display: 'flex'
    },

    settingsMobileTitle: {
        display:'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    settingsMobile: {
        display: 'flex',
        flexDirection: 'column'
    },
    noData:{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    deleteButton:{
        backgroundColor: t.palette.error.main,
        color: '#fff'
    }

}))

// ======================
// MigrationProjectSettings
// ======================

interface MigrationProjectSettingsProps {
    detailsState: MigrationProjectDetailsState,
    data: PROJECT_TYPE,
}

export const MigrationProjectSettings: React.FC<MigrationProjectSettingsProps> = observer((props) => {

    const {detailsState, data } = props;

    const styles = useMigrationProjectSettingsStyles(props);

    const history = useHistory();

    const isDesktop = useIsDesktop();

    const theme = useTheme();

    const handleChange = (value: number) => (event: React.ChangeEvent<{}>) => {
        detailsState.setSettingsTab(value);
    }

    const handleDelete = async()=> {
        const deleted = await detailsState.deleteProject();
        if (deleted)
        {history.replace(APP_ROUTES.MIGRATION)
            }
    }

    return (<>

        <Box pt={4} className={isDesktop ? styles.settingsFrame : styles.settingsMobile}>
            {isDesktop &&
            <SettingsTitleBar setTab={handleChange}/>
            }
            {!isDesktop &&
                <SettingsMobileTitleBar setTab={handleChange} popoverState={detailsState.settingsMenuPopover}/>
                }
            <Box width={'100%'}>
                <MuiTabContent value={detailsState.settingsTab} index={0}>
                    <EditProjectDetailsSection updateDetailsFunction={(values:NEW_PROJECT_FORM_VALUES_TYPE)=>detailsState.updateProjectInfo(values)} data={data}/>
                </MuiTabContent>
                <MuiTabContent value={detailsState.settingsTab} index={1}>
                    {data.project.questionnaireId &&
                    <ProjectQuestionnaireView fetchDataFunction={()=>detailsState.fetchQuestionnaireInfo()} data={detailsState.questionnaire} updateQuestionnaireFunction={(values: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE)=>detailsState.updateQuestionnaireInfo(values)} />
                    }
                    {!data.project.questionnaireId &&
                    <Box>
                            <Typography variant={'h4'}>Questionnaire </Typography>
                        <Box pt={6}>
                            <Card elevation={0}>
                                <Box p={2} className={styles.noData}>
                                    <Typography variant={'h6'}>No questionnaire data.</Typography>

                                </Box>
                            </Card>
                        </Box>
                    </Box>
                    }
                </MuiTabContent>
                <MuiTabContent value={detailsState.settingsTab} index={2}>
                    <Box>
                        <Typography variant={'h4'}>Delete Project</Typography>
                        <br/>
                        <Typography variant={'body1'}>Permanently delete your project? This cannot be undone.</Typography>
                        <Box display={'flex'} justifyContent={'flex-start'} pt={2}>
                            <Button onClick={handleDelete} startIcon={<FiTrash2/>} className={styles.deleteButton} variant={'contained'} disableElevation> Delete Project</Button>
                        </Box>
                    </Box>
                </MuiTabContent>
            </Box>

        </Box>
    </>
    )

})

// ======================
// SettingsTitleBar
// ======================

interface SettingsTitleBarProps{
    setTab: (value: number) => (event: React.ChangeEvent<{}>) => void;
}

export const SettingsTitleBar: React.FC<SettingsTitleBarProps> = observer((props)=>{
    const { setTab } = props;
    return (
    <Box>
        <Box>
            <Typography variant={'h4'}>Details</Typography>
        </Box>
        <Box width={200} pt={2} pr={3}>
            <MenuList color={grey['200']}>
                <MenuItem disableGutters onClick={setTab(0)}>Edit Project Details</MenuItem>
                <MenuItem disableGutters onClick={setTab(1)}>View Questionnaire</MenuItem>
                <MenuItem disableGutters onClick={setTab(2)}>Delete Project</MenuItem>
            </MenuList>
        </Box>

    </Box>
    )
})

// ======================
// SettingsMobileTitleBar
// ======================

interface SettingsMobileTitleBarProps {
    setTab: (value: number) => (event: React.ChangeEvent<{}>) => void;
    popoverState: PopoverState;
}

export const SettingsMobileTitleBar: React.FC<SettingsMobileTitleBarProps> = observer((props)=>{

    const { setTab, popoverState } = props;

    const styles = useMigrationProjectSettingsStyles(props);

    return (
        <Box className={styles.settingsMobileTitle} pb={3}>
            <Box>
                <Typography variant={'h4'}>Details</Typography>
            </Box>
            <Box>
                <PopoverIconButton popoverState={popoverState} label={'Settings Menu'} icon={<MdMenu/>} children={
                    <Box>
                        <MenuList color={grey['200']}>
                            <MenuItem onClick={setTab(0)}>Edit Project Details</MenuItem>
                            <MenuItem onClick={setTab(1)}>View Questionnaire</MenuItem>
                            <MenuItem onClick={setTab(2)}>Delete Project</MenuItem>

                        </MenuList>
                    </Box>
                }/>

            </Box>


        </Box>
    )
})

// ======================
// EditProjectDetailsSection
// ======================

interface EditProjectDetailsSectionProps {
    updateDetailsFunction: (values:NEW_PROJECT_FORM_VALUES_TYPE)=>Promise<any>;
    data: PROJECT_TYPE,
    isProposal?: boolean,
}

export const EditProjectDetailsSection: React.FC<EditProjectDetailsSectionProps> = observer((props) => {

    const {updateDetailsFunction, data, isProposal} = props;

    const styles = useMigrationProjectSettingsStyles(props);

    const title= isProposal ? 'Edit Proposal Details' : 'Edit Project Details'

    const projectEmployees = data.project.people.filter(person => person.userType === 'Employee').map(person => {
        return {name: person.fullName, id: person.employeeId}
    })
    const projectContacts = data.project.people.filter(person => person.userType === 'Contact').map(person => {
        return {name: person.fullName, id: person.contactId, account: person.accountName}
    })

    let dealType: string;
    switch (data.dealType) {
        case MigrationProjectDealType.GENERAL_PS:
            dealType = 'General PS / Support'
            break;
        case MigrationProjectDealType.OTHER:
            dealType = 'Other'
            break;
        case MigrationProjectDealType.PURE_BUNDLE:
            dealType = 'Pure Storage Bundle'
            break;
        default:
            dealType = 'Other';
            break;
    }

    const mmChannel = data.project.mmChannel ? `https://messenger.cdsi.us.com/ps/channels/${data.project.mmChannel}` : null;

    const initialValues: NEW_PROJECT_FORM_INITIAL_VALUES_TYPE = {
        migrationSource: data.migrationSource,
        migrationDestination: data.migrationDestination,
        dataAmount: xbytes.parseString(xbytes(data.migrationDataAmount as number)).value,
        //@ts-ignore
        dataUnit: xbytes.parseString(xbytes(data.migrationDataAmount)).unit,
        salesForceId: data.project.salesForceId,
        projectName: data.project.projectName,
        projectDescription: data.project.projectDescription,
        projectTimeFrameFrom: parse(data.project.projectTimeFrameFrom, 'yyyy-MM-dd', new Date()),
        projectTimeFrameTo: parse(data.project.projectTimeFrameTo, 'yyyy-MM-dd', new Date()),
        projectStartDate: data.project.projectStartDate ? parse(data.project.projectStartDate,'yyyy-MM-dd', new Date()) : null,
        projectEndDate: data.project.projectEndDate ? parse(data.project.projectEndDate,'yyyy-MM-dd', new Date()) : null,
        projectEmployees: projectEmployees,
        projectContacts: projectContacts,
        dealType: data.dealType ? dealType : null,
        mmChannel: mmChannel,
        psOnly: !!data.psOnly,
        onHold: data.project.status === MIGRATION_PROJECT_STATUS.ON_HOLD,

    };

    return (<Box width={'100%'}>

            <Typography variant={'h4'}>{title}</Typography>

            <Box className={styles.editDetailsFrame} width={'100%'}>
            <MigrationProjectForm initialValues={initialValues}
                                  submitFunction={(values: NEW_PROJECT_FORM_VALUES_TYPE) => updateDetailsFunction(values)}
                                  postSubmitRoute={`/projects/migration/${data.projectId}`} submitButtonText={'Save'}
                                  isProposal={isProposal}/>
        </Box>
        </Box>
    )
})

