import {makeStyles, Theme} from "@material-ui/core";
import {grey, pink} from "@material-ui/core/colors";

export const useMigrationProjectDetailsStyles = makeStyles((t: Theme) => ({

    projectTitleBar: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    chipBox: {
        background: grey['300'],
        borderRadius: 16,
        paddingLeft: t.spacing(1.5),
        paddingRight: t.spacing(1.5),
        paddingTop: t.spacing(1),
        paddingBottom: t.spacing(1),
        fontSize: '0.8125rem',
        display:'inline-flex',
        justifyContent:'center',
        alignItems: 'center'

    },
    path: {
        display: 'flex',
        alignItems: 'center'
    },
    flexCenter: {
        display: 'flex',
        alignItems: 'center',
    },

    flexJustifyCenter: {
        display: 'flex',
        justifyContent: 'center'
    },

    titleBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },

    activityTitleBar: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    smallAvatar: {
        height: 30,
        width: 30
    },
    xsAvatar: {
        height: 20,
        width: 20
    },
    confidentialChip: {
        background: pink['500'],
        color: grey['50']
    },
    mobileProjectTitleBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    projectStageTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    projectChecklistTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    phaseTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        paddingLeft: t.spacing(2),
        paddingTop: t.spacing(2),
        paddingRight: t.spacing(2)
    },
    progressMeter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        paddingRight: t.spacing(2),
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1.2
    },
    number: {
        display: 'flex',
        alignItems: 'flex-end',
        paddingRight: t.spacing(2)
    },
    mobileRepChips: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: t.spacing(2)
    },
    progressMeterTitle: {
        paddingRight: 175
    },
    cardSubtitle: {
        paddingLeft: t.spacing(2),
        paddingTop: t.spacing(2)
    },
    progressSlider: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    dataOverline: {
        lineHeight: 1.2
    },
    repChip: {
        marginRight: 2
    },
    numberField: {
        maxWidth: 80,
        marginRight: 8,
        marginLeft: 8
    },
    textField: {
        maxWidth: 100,
        marginRight: 8,
        marginLeft: 8,
    },
    progressInput: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },

    activitiesCardContent: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
    },

    addActivityButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    formFields: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    progressMeterButtons: {
        marginRight: t.spacing(2)
    },
    overallProgressMeter: {
        display: 'flex',
        justifyContent: 'center'
    },
    noWrapButton: {
        display: 'flex',
        flexWrap: 'nowrap'
    },
    avatarNameDisplay: {
        display: 'flex',
        alignItems: 'center'
    },
    timesheetTotalHours: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    moreInfoTitles: {
        lineHeight: 1.5
    },
    projectCodeOverline: {
        lineHeight: 1
    },
    tabIcons: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    spaceBetweenItems: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    noQuestionnaireData:{
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    activityDeleteButton: {
        color: t.palette.error.main
    }

}))