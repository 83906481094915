/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppView
 */
import * as React from 'react';
//import {} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import {APP_ROUTES} from './AppRoutes';
import {Route, Redirect, Switch, Link} from 'react-router-dom';
import {useAppServices} from './AppStates';
import {MainFrameLayout} from '../layout/MainLayout';
import {AuthenticatedViews} from '../auth/AuthenticatedViews';
import {AppBar, CssBaseline, Box} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {AppTheme, DarkModeTheme} from './AppTheme';
import {AuthCallbackView, LoginView} from "../auth/LoginScreen";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {DashboardView} from "../dashboard/DashboardView";
import {MigrationProjectView} from "../migration/MigrationProjectView";
import {MigrationProjectDialogContent} from "../migration/MigrationProjectDialog";
import {ReportsMainView} from "../reports/ReportsMainView";
import {NewReportDialogView} from "../reports/ReportsGenerateDialog";

interface AppViewProps {
}

export const AppView: React.FC<AppViewProps> = observer(({...p}) => {
    const {authService} = useAppServices();
    return <>
        <Switch>
            <Redirect exact from={'/'} to={APP_ROUTES.MIGRATION}/>
            <Route path={APP_ROUTES.LOGIN}>
                <LoginView/>
            </Route>
            <Route path={APP_ROUTES.AUTH_CALLBACK}>
                <AuthCallbackView/>
            </Route>
            <AuthenticatedViews>
                <MainFrameLayout>
                    <Route path={APP_ROUTES.MAIN_BASE}>
                        <MigrationProjectView/>
                    </Route>
                    <Route path={APP_ROUTES.HOME}>
                        <MigrationProjectView/>
                    </Route>
                    <Route path={APP_ROUTES.MIGRATION}>
                        <MigrationProjectView/>
                    </Route>
                    <Route path={APP_ROUTES.REPORTS}>
                        <ReportsMainView/>
                    </Route>
                    <Route path={APP_ROUTES.MIGRATION_FORM}>
                        <MigrationProjectDialogContent/>
                    </Route>
                    <Route path={APP_ROUTES.GENERATE_REPORT_FORM}>
                        <NewReportDialogView/>
                    </Route>
                </MainFrameLayout>

            </AuthenticatedViews>

        </Switch>
    </>;
});

