import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {MigrationProjectDetailsState} from "./MigrationProjectDetailsState";
import {
    Box,
    Button,
    Card,
    Chip,
    ChipProps,
    Dialog, Fab,
    Grid,
    IconButton,
    SvgIcon,
    Theme, Tooltip,
    Typography
} from "@material-ui/core";
import * as yup from "yup";
import {Formik} from "formik";
import {FormTextField} from "../../../common/form/FormComponents";
import {FiTrash2, MdAdd, MdClose, MdRefresh} from "react-icons/all";
import {PSCardList} from "../../../common/table/PSTable";
import {useInitData, useServerDataWithLoadingList} from "../../core/data/DataLoaderHooks";
import {SHIPMENT_INFO_TYPE, SHIPMENT_STATUS, SHIPMENT_TYPE} from "../MigrationProjectTypes";
import {EmployeeView} from "../../auth/EmployeeView";
import Link from "@material-ui/core/Link";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {amber, grey, lightBlue, lightGreen, red} from "@material-ui/core/colors";
import {formatDateString} from "./MigrationProjectActivity";
import {format, formatDistanceToNow} from "date-fns";
import {useIsDesktop} from "../../layout/MainLayout";

const useShipmentStyles = makeStyles((t: Theme) => ({
    buttonLink: {
        textDecoration: 'underline',
        '&:hover': {
            color: lightBlue['500'],
        },
    },
    link: {
        color: 'black',
        '&:hover': {
            color: lightBlue['500'],
        },
    },
    iframe: {
        height: '100%',
        width: '100%'
    },
    deleteButton: {
        color: t.palette.error.main
    }
}))


// ======================
// MigrationProjectShipmentsView
// ======================

interface MigrationProjectShipmentsViewProps {
    detailsState: MigrationProjectDetailsState;
}

export const MigrationProjectShipmentsView: React.FC<MigrationProjectShipmentsViewProps> = observer((props) => {
    const {detailsState} = props;

    const isDesktop = useIsDesktop();

    return <Box pt={4}>
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h4'}>Shipments</Typography>
            <Box display={'flex'} height={'100%'} alignItems={'center'}>
                <Box pr={2}>
                    <ResyncShipmentsButton isDesktop={isDesktop} detailsState={detailsState}/>
                </Box>

                <ShipmentSearchButton detailsState={detailsState} isDesktop={isDesktop}/>
            </Box>
        </Box>
        <Box>
            <ShipmentsTable detailsState={detailsState} isDesktop={isDesktop}/>
        </Box>
    </Box>
})

// ======================
// ResyncShipmentsButton
// ======================

interface ResyncShipmentsButtonProps {
    isDesktop: boolean;
    detailsState: MigrationProjectDetailsState;
}

export const ResyncShipmentsButton: React.FC<ResyncShipmentsButtonProps> = observer((props) => {
    const {detailsState, isDesktop} = props;
    const resync = async () => {
        const resynced = await detailsState.resyncShipmentRecords();
        if (resynced) {
            await detailsState.fetchShipments();
        }
    }

    if (isDesktop) {
        return <Button variant={'outlined'}
                       color={'primary'}
                       onClick={resync}
                       startIcon={<MdRefresh/>}>
            Re-Sync
        </Button>
    }

    return <Tooltip title={'Resync Shipments'}>
        <IconButton color={'primary'}
                    onClick={resync}>
            <SvgIcon>
                <MdRefresh/>
            </SvgIcon>
        </IconButton>
    </Tooltip>


})

// ======================
// ShipmentSearchBox
// ======================

interface ShipmentSearchBoxProps {
    detailsState: MigrationProjectDetailsState;
    isDesktop: boolean;
}

export const ShipmentSearchButton: React.FC<ShipmentSearchBoxProps> = observer((props) => {
    const {detailsState, isDesktop} = props;

    const [open, setOpen] = useState(false);

    const schema = yup.object({
        shipmentId: yup.number()
            .required('Enter an ID number.')
            .typeError('ID Must be a number.')
    })

    const initialValues: { shipmentId: number } = {
        shipmentId: undefined
    }


    return <EmployeeView>
        {isDesktop &&
        <Button onClick={() => setOpen(true)} startIcon={<MdAdd/>} variant={'contained'}
                color={'primary'} disableElevation>Add Shipment</Button>
        }
        {!isDesktop &&
        <Tooltip title={'Add Shipment Record'}>
            <Fab onClick={() => setOpen(true)}

                 color={'primary'}>
                <SvgIcon>
                    <MdAdd/>
                </SvgIcon>
            </Fab>
        </Tooltip>

        }

        <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={() => setOpen(false)}>
            <Card>
                <Box p={2}>
                    <Typography variant={'h6'}>Add Shipment Record</Typography>
                    <Typography variant={'body1'}>Enter a shipment ID number to add shipment record from
                        BSI.</Typography>
                    <Box pt={4}>
                        <Formik initialValues={initialValues} validationSchema={schema}
                                onSubmit={async (values, actions) => {
                                    const added = await detailsState.addNewShipmentRecord(values.shipmentId);
                                    if (added) {
                                        setOpen(false);
                                        await detailsState.fetchShipments();

                                    }
                                }}>
                            {props => {
                                return <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                    <FormTextField label={'Shipment ID'} name={'shipmentId'}/>
                                    <Box pl={2}>
                                        <Button disabled={props.isSubmitting}
                                                disableElevation
                                                variant={'contained'}
                                                color={'primary'}
                                                type={'submit'}
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    await props.submitForm()
                                                }
                                                }>Add</Button>
                                    </Box>

                                </Box>
                            }}
                        </Formik>
                    </Box>

                </Box>

            </Card>
        </Dialog>
    </EmployeeView>
})

// ======================
// ShipmentsTable
// ======================

interface ShipmentsTableProps {
    detailsState: MigrationProjectDetailsState;
    isDesktop: boolean
}

export const ShipmentsTable: React.FC<ShipmentsTableProps> = observer((props) => {
    const {detailsState, isDesktop} = props;

    useInitData({
        init: async () => await detailsState.fetchShipments(),
        //poll: () => detailsState.fetchShipments(),
        //pollInterval: 30
    })

    return useServerDataWithLoadingList(detailsState.shipmentsList, data => {
        if (!data.length) {
            return <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
                <Typography variant={'h6'} color={'textSecondary'}>
                    No Records Added Yet.
                </Typography>
            </Box>
        }
        return <Box pt={4}>
            {data.map(d => {
                return <ShipmentCard data={d} isDesktop={isDesktop} detailsState={detailsState}/>
            })}
        </Box>
    })
})

// ======================
// ShipmentCard
// ======================

interface ShipmentCardProps {
    data: SHIPMENT_TYPE;
    isDesktop: boolean;
    detailsState: MigrationProjectDetailsState;
}

export const ShipmentCard: React.FC<ShipmentCardProps> = observer((props) => {
    const {data, isDesktop, detailsState} = props;
    const styles = useShipmentStyles(props);

    const bsiLink = `https://bsi.cdsi.us.com/BSI-WEB/#/inventory/shipments/details/${data.shipmentId}`;

    return <Box pb={2}>
        <Card>
            <Box display={'flex'} justifyContent={'space-between'} pt={2} pr={2} pl={2}>
                <Box>
                    <BsiShipmentLink isDesktop={isDesktop}
                                     link={bsiLink}
                                     name={`${data.shipmentInfo.account.companyAka} Shipment ${data.shipmentId}`}/>
                    <Typography variant={'body1'}>{data.shipmentInfo.barcode}</Typography>
                </Box>
                <Box display={'flex'}>
                    <ShipmentStatusChip status={data.shipmentStatus}/>
                </Box>
            </Box>
            <Box p={2}>
                <Box display={'flex'} pb={2}>
                    <Typography variant={'body1'}>{data.shipmentInfo.description}</Typography>
                </Box>
                <Grid container spacing={2} justify={'space-between'} alignItems={'flex-end'}>
                    <Grid item xs={10}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Box>
                                    <Typography variant={'caption'}>Ship To: &nbsp;</Typography>
                                    <Chip label={data.shipmentInfo.location.name}/>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box>
                                    <Typography variant={'caption'}>Shipped: &nbsp;</Typography>
                                    <Chip
                                        label={formatDistanceToNow(data.shipmentInfo.shipped, {addSuffix: true})}/>
                                </Box>
                            </Grid>

                            <Grid item>
                                <Box pr={2}>
                                    <Typography variant={'caption'}>Updated: &nbsp;</Typography>
                                    <Chip label={formatDateString(data.updatedAt)}/>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Box display={'flex'} justifyContent={'flex-end'}>
                            <DeleteShipmentButton detailsState={detailsState} shipmentId={data.shipmentId}/>
                        </Box>
                    </Grid>
            </Grid>
    </Box>

</Card>
</
    Box >
})

const getBoxBase = (t: Theme) => {
    return {
        color: '#fff',
        fontWeight: 700,
    }

}

const useShipmentStatusStyles = makeStyles((t: Theme) => ({

    boxBase: getBoxBase(t),

    icon: {
        color: '#fff'
    },
    readyForShipment: {
        ...getBoxBase(t),
        backgroundColor: lightBlue['500']

    },
    shipped: {
        ...getBoxBase(t),
        backgroundColor: red['300']
    },

    partiallyReturned: {
        ...getBoxBase(t),
        backgroundColor: amber['400'],
    },

    completed: {
        ...getBoxBase(t),
        backgroundColor: lightGreen['500']
    },
    preparation: {
        ...getBoxBase(t),
        backgroundColor: grey['500']
    },

    cancelled: {
        ...getBoxBase(t),
        backgroundColor: red['500']
    }

}))

// ======================
// ShipmentStatusChip
// ======================

interface ShipmentStatusChipProps {
    status: SHIPMENT_STATUS
}

export const ShipmentStatusChip: React.FC<ShipmentStatusChipProps & Partial<ChipProps>> = observer((props) => {
    const {status} = props;
    const styles = useShipmentStatusStyles(props);

    let className;

    if (status === SHIPMENT_STATUS.Completed) {
        className = styles.completed;
    } else if (status === SHIPMENT_STATUS.PartiallyReturned) {
        className = styles.partiallyReturned;
    } else if (status === SHIPMENT_STATUS.ReadyForShipment) {
        className = styles.readyForShipment;
    } else if (status === SHIPMENT_STATUS.Shipped) {
        className = styles.shipped
    } else if (status === SHIPMENT_STATUS.Preparation) {
        className = styles.preparation
    } else {
        className = styles.cancelled
    }
    return <Chip className={className} {...props} label={status.toUpperCase()}/>
})

// ======================
// BsiShipmentLink
// ======================

interface BsiShipmentLinkProps {
    link: string;
    isDesktop: boolean
    name: string;
}

export const BsiShipmentLink: React.FC<BsiShipmentLinkProps> = observer((props) => {
    const {link, isDesktop, name} = props;
    const [open, setOpen] = useState(false);
    const styles = useShipmentStyles(props);

    if (isDesktop) {
        return <>
            <Link onClick={() => setOpen(true)}
                  variant={'button'}>
                {name}
            </Link>
            {open && <Dialog open={open} onClose={() => setOpen(false)}
                             fullWidth maxWidth={'xl'}>
                <Box height={'90vh'}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={2}>
                        <Typography variant={'h6'}>{name}</Typography>
                        <IconButton onClick={() => setOpen(false)}>
                            <SvgIcon>
                                <MdClose/>
                            </SvgIcon>
                        </IconButton>
                    </Box>
                    <iframe src={link} title={name} className={styles.iframe}/>
                </Box>
            </Dialog>}
        </>
    }
    return <Link href={link} target={'_blank'}
                 variant={'button'}>
        {name}
    </Link>
})

// ======================
// DeleteShipmentButton
// ======================

interface DeleteShipmentButtonProps {
    detailsState: MigrationProjectDetailsState;
    shipmentId: number
}

export const DeleteShipmentButton: React.FC<DeleteShipmentButtonProps> = observer((props) => {
    const {detailsState, shipmentId} = props;
    const styles = useShipmentStyles(props)

    const deleteShipment = async () => {
        await detailsState.deleteShipmentRecord(shipmentId)
    }
    return <Tooltip title={'Delete Shipment Record'}>
        <IconButton onClick={deleteShipment} className={styles.deleteButton}>
            <SvgIcon>
                <FiTrash2/>
            </SvgIcon>
        </IconButton>
    </Tooltip>
})