// ======================
// MigrationProjectView
// ======================


import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Link, Route, Switch} from "react-router-dom";
import {APP_ROUTES} from "../app/AppRoutes";
import {createColumn, PSTable} from "../../common/table/PSTable";
import {ContactView, EmployeeView} from "../auth/EmployeeView";
import {useAppServices} from "../app/AppStates";
import {Box, Button, Fab, IconButton, SvgIcon, TableCell, TableRow, Theme, Tooltip} from "@material-ui/core";
import {AiOutlineDownload, FiTrash2, IoMdAdd} from "react-icons/all";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {useInitData, useServerData} from "../core/data/DataLoaderHooks";
import {LoadingScreen} from "../../common/splash/SplashScreen";
import {REPORT_TYPE} from "./report_types";
import {formatDateString} from "../migration/migrationProjectDetails/MigrationProjectActivity";
import {getReportTypeLabel} from "./ReportsGenerateDialog";
import {TableState} from "../../common/table/TableState";

const useReportListStyles = makeStyles((t: Theme) => ({
    buttonLink: {
        display: 'flex',
        flexWrap: 'nowrap',
        textDecoration: 'none'
    },
}))

// ======================
// ReportsListMainView
// ======================

interface ReportsMainViewProps {
}

export const ReportsMainView: React.FC<ReportsMainViewProps> = observer((props) => {

    return <>
        <Switch>
            <Route path={APP_ROUTES.REPORTS} exact>
                <ReportsListView/>
            </Route>
        </Switch>
    </>
})

// ======================
// ReportsListView
// ======================

interface ReportsListViewProps {
}

export const ReportsListView: React.FC<ReportsListViewProps> = observer((props) => {
    const styles = useReportListStyles(props);

    return <Box>
        <EmployeeView>
            <Box pb={2} display={'flex'} justifyContent={'space-between'}>
                <Box>
                    <Typography variant={'h4'}>
                        Reports
                    </Typography>
                </Box>
                <Box>
                    <Link to={APP_ROUTES.GENERATE_REPORT_FORM} className={styles.buttonLink}>
                        <Button color={'primary'} startIcon={<IoMdAdd/>}>generate report</Button>
                    </Link>
                </Box>
            </Box>
            <Box>
                <ReportsTable/>
            </Box>
        </EmployeeView>
        <ContactView>
            <Box pb={2} display={'flex'} justifyContent={'space-between'}>
                <Box>
                    <Typography variant={'h4'}>
                        Error
                    </Typography>
                    <Typography variant={'body1'}>
                        You are not authorized to view this page.
                    </Typography>
                </Box>
            </Box>
        </ContactView>

    </Box>

})

// ======================
// ReportsTable
// ======================

interface ReportsTableProps {
}

export const ReportsTable: React.FC<ReportsTableProps> = observer((props) => {

    const {reportsService} = useAppServices();

    const poll = () => reportsService.fetchReports();

    useInitData({
        poll: poll,
        pollInterval: 1
    })

    const columns = [
        createColumn('name', 'Name'),
        createColumn('type', 'Type'),
        createColumn('createdAt', 'Created On'),
        createColumn('author', 'Author'),
        createColumn('actions', 'Actions'),
    ]
    return useServerData(reportsService.reports, <LoadingScreen/>, data => {
        return <PSTable tableData={data} columns={columns}
                        rowComponent={ReportsTableRow}
                        tableState={reportsService.reportsTableState}
                        refreshFunction={()=>reportsService.fetchReports()}/>
    })
})

// ======================
// ReportsTableRow
// ======================

interface ReportsTableRowProps {
    data: REPORT_TYPE
}

export const ReportsTableRow: React.FC<ReportsTableRowProps> = observer((props) => {
    const {data} = props;

    const {reportsService, dialogService} = useAppServices();

    const downloadReport = async () => {
        await reportsService.downloadFile(data.id, data.filename)
    };

    const deleteReport = async()=> {
        const confirmed = await dialogService.addConfirmDialog('Are you sure you want to delete this report?', null, false);
        if (confirmed){
            await reportsService.deleteReport(data.id);
            await dialogService.addAlertDialog('Success!', 'Report deleted.');
            await reportsService.fetchReports()
        }
    }

    return <TableRow>
        <TableCell>
            {data.name}
        </TableCell>
        <TableCell>
            {getReportTypeLabel(data.reportType)}
        </TableCell>
        <TableCell>
            {formatDateString(data.createdAt)}
        </TableCell>
        <TableCell>
            {data.createdBy.fullName}
        </TableCell>
        <TableCell>
            <Box display={'flex'}>
                <Tooltip title={'Download Report'}>
                    <IconButton color={'primary'}
                                onClick={downloadReport}><SvgIcon><AiOutlineDownload/></SvgIcon></IconButton>
                </Tooltip>
                <Tooltip title={'Delete Report'}>
                    <IconButton onClick={deleteReport}>
                        <SvgIcon color={'error'}>
                            <FiTrash2/>
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            </Box>

        </TableCell>
    </TableRow>
})
