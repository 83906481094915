/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AppStates
 */

import {AuthService} from '../auth/AuthService';
import {observer, useLocalStore} from 'mobx-react-lite';
import React, {createContext, useContext} from 'react';
import {ServerAPI} from '../core/ServerAPI';
import {AppGlobalService} from './AppGlobalService';
import {ProgressService} from '../core/progress/ProgressService';
import {DialogService} from '../core/dialog/DialogService';
import {MigrationProjectService} from "../migration/MigrationProjectService";
import {DropzoneService} from "../core/dropzone/DropzoneService";
import {ReportsService} from "../reports/ReportsService";
import {MockAPI} from "../core/test/MockAPI";

const initState = () => {
    const api                       = new ServerAPI();
    const mockAPI                   = new MockAPI();
    const appGlobalService          = new AppGlobalService();
    const dialogService             = new DialogService();
    const progressService           = new ProgressService(dialogService);
    const authService               = new AuthService(api);
    const migrationProjectService   = new MigrationProjectService(dialogService, api, progressService);
    const dropzoneService           = new DropzoneService(api, dialogService)
    const reportsService            = new ReportsService(api, dialogService, progressService)
    return {
        authService, appGlobalService, progressService, dialogService, migrationProjectService, dropzoneService, reportsService
    };
};


export type AppServicesType = ReturnType<typeof initState>;
export const appStateContext = createContext<AppServicesType | null>(null);


export const useAppServices = () => {
    const services = useContext(appStateContext);
    if (!services)
    {
        throw new Error('not inside app services provider');
    }
    return services;
};


// ======================
// AppServicesProvider
// ======================
interface AppServicesProviderProps {
}

export const AppServicesProvider: React.FC<AppServicesProviderProps> = observer(({...p}) => {
    const store = useLocalStore(initState);
    return <appStateContext.Provider value={store}>
        {p.children}
    </appStateContext.Provider>;
});


