import React from 'react';
import {CssBaseline} from '@material-ui/core';
import {BrowserRouter,Route} from 'react-router-dom';
import 'typeface-roboto';
import {LightModeTheme, AppTheme, DarkModeTheme} from './AppTheme';
import {AppServicesProvider, useAppServices} from './AppStates';
import {AppView} from './AppView';
import {AppInitialization} from './AppInititialization';
import {AppBackgroundViews} from './AppBackgroundViews';
import {QueryParamProvider} from 'use-query-params';
import {Helmet} from 'react-helmet';


const App: React.FC = () => {
    return (
        <AppServicesProvider>
            <BrowserRouter>
                <QueryParamProvider ReactRouterRoute={Route}>
                    <AppInitialization>
                        <AppTheme>
                            <Helmet>
                                <title>PS Portal</title>
                            </Helmet>
                            <CssBaseline/>
                            <AppView/>
                            <AppBackgroundViews/>
                        </AppTheme>

                    </AppInitialization>
                </QueryParamProvider>
            </BrowserRouter>
        </AppServicesProvider>
    );
};


export default App;
