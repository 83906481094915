import React, {useState} from 'react';
import {observer} from "mobx-react-lite";
import {
    Box,
    Button,
    Card,
    Typography,
    Grid,
    SvgIcon,
    Link,
    IconButton,
    Theme,
    useTheme,
    Tooltip,
    MenuList,
    MenuItem,
    Dialog,
    AppBar,
    Toolbar,
    Container,
    InputAdornment, Tabs, Tab, Divider
} from "@material-ui/core";
import * as yup from "yup";
import {useAppServices} from "../../../app/AppStates";
import {
    DOWNLOAD_FILE_TYPE,
    DROPZONE_FILE_RESPONSE_TYPE,
    DROPZONE_FILE_TYPE,
    MIGRATION_PROJECT_QUESTIONNAIRE_TYPE,
    MigrationQDestinationEntry,
    MigrationQDestinationInfo, MigrationQIscsiAdaptorInfo,
    MigrationQMiscInfo,
    MigrationQNetworkInfo,
    MigrationQSANConfigInfo,
    MigrationQSiteInfo,
    MigrationQSourceFabricInfo,
    MigrationQSourceStorageEntry,
    MigrationQSourceStorageInfo,
    MigrationQSourceStorageIscsiInfo,
    MigrationQTimeFrameInfo, MigrationQUserInfo,
    NEW_PROJECT_FORM_VALUES_TYPE,
    PROJECT_TYPE
} from "../../MigrationProjectTypes";
import {makeStyles} from "@material-ui/core/styles";
import {useInitData, useServerData} from "../../../core/data/DataLoaderHooks";
import {ServerData} from "../../../core/data/ServerData";
import {LoadingScreen} from "../../../../common/splash/SplashScreen";
import {DataItem} from "../../../../common/text/DataDisplay";
import {Path} from "../../../../common/path/Path";
import {
    FiMail,
    FiTrash2,
    GoCloudDownload,
    IoMdCheckmarkCircleOutline,
    IoMdClose, MdAdd,
    MdCheck, MdEdit,
    MdMailOutline,
    MdMenu, MdSave
} from "react-icons/all";
import xbytes from "xbytes";
import {useIsMobile} from "../../../layout/MainLayout";
import {PopoverIconButton} from "../../../../common/popover/PopoverButton";
import {PopoverState} from "../../../../common/popover/PopoverService";
import {useHistory} from 'react-router-dom';
import {APP_ROUTES} from "../../../app/AppRoutes";
import {SlideUpTransition} from "../../../../common/transitions/Transitions";
import {FieldArray, Formik, FormikHelpers} from "formik";
import {FormCheckboxItem, FormDatePicker, FormSelect, FormTextField} from "../../../../common/form/FormComponents";
import {QDropzone} from "../../../core/dropzone/DropzoneComponents";
import {format} from "date-fns";
import {ClipboardText} from "../../../../common/clipboard/ClipboardText";
import {MigrationProjectService} from "../../MigrationProjectService";
import {MuiTabContent} from "../../../core/tab/Tabs";
import {QuestionnaireEditForm} from "./MigrationProjectQuestionnaireEditForm";

export const useQuestionnaireStyles = makeStyles((t: Theme) => ({
    textFields: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    cards: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap'
    },
    approveButtonBox: {
        display: 'flex',
        justifyContent: 'center',
    },
    downloadButton: {
        "&:hover": {
            backgroundColor: '#eee',
            textDecoration: 'underline',
            cursor: 'pointer'

        },
        "&:active": {
            backgroundColor: '#ddd',
            cursor: 'pointer'

        }
    },
    downloadButtonSize: {
        "&:hover": {
            textDecoration: 'none',
            cursor: 'pointer'
        },
        "&:active": {
            textDecoration: 'none',
            cursor: 'pointer'

        }
    },
    noData: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    deleteButton: {
        backgroundColor: t.palette.error.main,
    },
    approveButton: {
        backgroundColor: '#4caf50'
    },
    collapsibleAppBar: {
        cursor: 'pointer',
        "&:hover": {
            background: `rgba(21, 101, 192, .8)`
        }
    },
    projectSiteDeleteButton: {
        color: t.palette.error.main,
        borderColor: t.palette.error.main
    }
}))

// ======================
// ProjectQuestionnaireView
// ======================

interface ProjectQuestionnaireViewProps {
    fetchDataFunction: () => Promise<any>;
    data: ServerData<MIGRATION_PROJECT_QUESTIONNAIRE_TYPE>,
    proposalId?: string
    setTabFunction?: () => void;
    popoverState?: PopoverState;
    updateQuestionnaireFunction: (values: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE) => Promise<any>;
}

export const ProjectQuestionnaireView: React.FC<ProjectQuestionnaireViewProps> = observer((props) => {

    const {fetchDataFunction, data, proposalId, setTabFunction, popoverState, updateQuestionnaireFunction} = props;

    const {migrationProjectService} = useAppServices();

    const styles = useQuestionnaireStyles(props);

    const [open, setOpen] = useState(false);

    const handleOpenDialog = () => {
        setOpen(true)
    }

    const handleCloseDialog = () => {
        setOpen(false)
    }

    const [editView, setEditView] = useState(false);

    const openEditView = () => {
        setEditView(true);
    }

    const closeEditView = () => {
        setEditView(false);
    }

    useInitData({
        poll: () => fetchDataFunction(),
        pollInterval: 1
    })

    return useServerData(data, <LoadingScreen/>, data => {
        return (
            <>
                {!proposalId &&
                <Box pb={3} className={styles.textFields}>
                    <Box flexGrow={1}>
                        <Typography variant={'h4'}>Questionnaire</Typography>
                    </Box>
                    {data.user &&
                    <Box display={'flex'}>
                        <Box pr={2}>
                            <Button color={'primary'} variant={'outlined'} onClick={handleOpenDialog}
                                    disableElevation
                                    startIcon={<SvgIcon><FiMail/></SvgIcon>}>View Email-Friendly Form</Button>
                        </Box>
                        <Box>
                            {!editView &&
                            <Button color={'primary'} variant={'contained'}
                                    disableElevation
                                    onClick={openEditView}
                                    startIcon={<SvgIcon><MdEdit/></SvgIcon>}>Edit</Button>
                            }
                            {editView &&
                            <Button color={'primary'} variant={'contained'}
                                    disableElevation
                                    onClick={closeEditView}
                            >Cancel</Button>
                            }

                        </Box>
                    </Box>
                    }
                </Box>

                }
                {proposalId &&
                <QuestionnaireHeader popoverState={popoverState}/>

                }
                {!editView &&
                <QuestionnaireDisplay data={data} proposalId={proposalId} setTabFunction={setTabFunction}/>
                }
                {editView &&
                <QuestionnaireEditForm data={data} updateQuestionnaireFunction={updateQuestionnaireFunction}
                                       closeEditView={closeEditView}/>
                }
                {data.user &&
                <EmailFriendlyDialog data={data} open={open} handleClose={handleCloseDialog}/>
                }

            </>
        )
    })

})

// ======================
// QuestionnaireDisplay
// ======================

interface QuestionnaireDisplayProps {
    data: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE,
    proposalId: string,
    setTabFunction: () => void;
}

export const QuestionnaireDisplay: React.FC<QuestionnaireDisplayProps> = observer((props) => {
    const {data, proposalId, setTabFunction} = props;

    const [tab, setTab] = useState(0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTab(newValue);
    };

    const styles = useQuestionnaireStyles(props);
    const {migrationProjectService} = useAppServices();
    const history = useHistory();

    const handleApproval = () => {
        migrationProjectService.approveProposedProject(proposalId).then((value) => {
            if (value === true) {
                setTabFunction()
            }
        });
    }

    const handleDelete = async () => {
        const deleted = await migrationProjectService.deleteProject(proposalId);
        if (deleted) {
            history.replace(APP_ROUTES.MIGRATION)
        }
    }

    if (!data.user) {
        return (
            <Box>
                <Box pt={6}>
                    <Card elevation={0}>
                        <Box p={2} className={styles.noData}>
                            <Typography variant={'h6'}>No questionnaire data.</Typography>

                        </Box>
                    </Card>
                </Box>
            </Box>
        )
    } else {
        return (
            <>
                <ContactInfoSection data={data.user}/>
                <Card elevation={0}>
                    <Tabs value={tab} onChange={handleTabChange}>
                        <Tab label={'Primary Site'}/>
                        {data.additional_site_info ? data.additional_site_info.map((s, i) => {
                            return <Tab label={`Site ${i + 2}`}/>
                        }) : null}
                    </Tabs>
                    <Divider/>
                    <MuiTabContent value={tab} index={0}>
                        <ProjectSiteSections migrationProjectService={migrationProjectService} data={data}/>
                    </MuiTabContent>
                    {data.additional_site_info ? data.additional_site_info.map((s, i) => {
                        return <MuiTabContent value={tab} index={i + 1}>
                            <ProjectSiteSections data={s} migrationProjectService={migrationProjectService}/>
                        </MuiTabContent>
                    }) : null}
                </Card>

                <Box pb={3}>
                    <MiscInfoSection data={data.misc}/>
                </Box>
                {proposalId &&
                <Box pb={6} className={styles.approveButtonBox}>
                    <Box pr={1}>
                        <Button onClick={handleApproval} variant={'contained'} color={'primary'}
                                className={styles.approveButton}
                                disableElevation startIcon={<SvgIcon><IoMdCheckmarkCircleOutline/></SvgIcon>}>
                            <Box color={'#fff'}>Approve</Box>
                        </Button>
                    </Box>
                    <Box pl={1}>
                        <Button onClick={handleDelete} variant={'contained'} disableElevation
                                startIcon={<SvgIcon htmlColor={'#ffffff'}><FiTrash2/></SvgIcon>}
                                className={styles.deleteButton}>
                            <Box color={'#fff'}>Delete</Box>
                        </Button>
                    </Box>

                </Box>
                }
            </>)
    }


})

// ======================
// ProjectSiteSections
// ======================

interface ProjectSiteSectionsProps {
    migrationProjectService: MigrationProjectService;
    data: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE,


}

export const ProjectSiteSections: React.FC<ProjectSiteSectionsProps> = observer((props) => {
    const {migrationProjectService, data} = props;

    return <Box p={2}>
        <Box pb={3}>
            <DeploymentSiteInfoSection data={data.site_info}/>
        </Box>
        <Box pb={3}>
            <SourceStorageSection data={data.source_storage}
                                  downloadFunction={(uuid: string, fileName: string, fileType: string) => migrationProjectService.downloadQuestionnaireFiles(uuid, fileName, fileType)}/>
        </Box>
        <Box pb={3}>
            <SourceFabricSection data={data.source_fabric}
                                 downloadFunction={(uuid: string, fileName: string, fileType: string) => migrationProjectService.downloadQuestionnaireFiles(uuid, fileName, fileType)}/>
        </Box>
        <Box pb={3}>
            <HostsAndVolumesSection data={data.san_config}/>
        </Box>
        <Box pb={3}>
            <DestinationStorageSection data={data.destination}/>
        </Box>
        <Box pb={3}>
            <NetworkAccessSection data={data.network}/>
        </Box>
        <Box pb={3}>
            <MigrationTimeFrameSection data={data.project_time_frame}/>
        </Box>
    </Box>
})

// ======================
// ContactInfoSection
// ======================

interface ContactInfoSectionProps {
    data: MigrationQUserInfo;
}

export const ContactInfoSection: React.FC<ContactInfoSectionProps> = observer((props) => {
    const {data} = props;
    const styles = useQuestionnaireStyles(props);

    return <Box pb={3}>
        <Box pb={2}>
            <Typography variant={'h6'}>Contact Info</Typography>
        </Box>
        <Card elevation={0}>
            <Box className={styles.textFields} p={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <DataItem label={'Name'} data={data.name} pr={1} pb={2}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DataItem label={'Email'} data={data.email} pr={1} pb={2}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DataItem label={'Phone'} data={data.phone ? data.phone : '–'} pb={2}/>
                    </Grid>
                </Grid>
            </Box>
        </Card>

    </Box>
})

// ======================
// SourceStorageSection
// ======================

interface SourceStorageSectionProps {
    data: MigrationQSourceStorageInfo,
    downloadFunction: (uuid: string, fileName: string, fileType: string) => any;
}

export const SourceStorageSection: React.FC<SourceStorageSectionProps> = observer((props) => {
    const {data, downloadFunction} = props;
    return (
        <Box>
            <Box pb={2}>
                <Box pb={2}>
                    <Typography variant={'h6'}>Source Storage</Typography>
                </Box>
                <Card elevation={0}>
                    <Box p={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <DataItem label={'Total Capacity'} data={`${data.total_capacity} TiB`}/>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <DataItem label={'SAN Connectivity'}
                                          data={data.san_connectivity ? data.san_connectivity : "-"}/>
                            </Grid>
                        </Grid>
                    </Box>
                </Card>
            </Box>
            {data.iscsi &&
            <IscsiSection data={data.iscsi} downloadFunction={downloadFunction}/>
            }
            <Box>
                <Grid container spacing={3}>
                    {data.entries.map((entry, index) => {
                        return <SourceStorageCard data={entry}
                                                  index={index + 1}
                                                  single={data.entries.length === 1}
                                                  downloadFunction={downloadFunction}
                        />
                    })}
                </Grid>
            </Box>
        </Box>
    )
})

// ======================
// IscsiSection
// ======================

interface IscsiSectionProps {
    data: MigrationQSourceStorageIscsiInfo;
    downloadFunction: (uuid: string, fileName: string, fileType: string) => any;
}

export const IscsiSection: React.FC<IscsiSectionProps> = observer((props) => {
    const {data, downloadFunction} = props;

    return <Box pb={2}>
        <Box pb={2}>
            <Grid container spacing={2}>
                {data.adaptors.map((a, i) => {
                    return <IscsiAdaptorCard data={a} index={i + 1} single={data.adaptors.length === 1}/>
                })
                }
            </Grid>

        </Box>
        <Card elevation={0}>

            <Box p={2}>
                <Typography variant={'overline'}>Source Array Performance Stats</Typography>
                {data.array_performance_stats &&
                data.array_performance_stats.map((d) => {
                    return <DownloadFileSection data={d}
                                                downloadFunction={(uuid: string, fileName: string, fileType: string) => downloadFunction(uuid, fileName, fileType)}/>
                })
                }
                {!data.array_performance_stats &&
                <Typography variant={'body1'}>–</Typography>
                }
            </Box>
            <Box p={2}>
                <Typography variant={'overline'}>Host Performance Stats</Typography>
                {data.host_performance_stats &&
                data.host_performance_stats.map((d) => {
                    return <DownloadFileSection data={d}
                                                downloadFunction={(uuid: string, fileName: string, fileType: string) => downloadFunction(uuid, fileName, fileType)}/>
                })
                }
                {!data.host_performance_stats &&
                <Typography variant={'body1'}>–</Typography>
                }
            </Box>
            <Box p={2}>
                <Typography variant={'overline'}>Switch Report(s)</Typography>
                {data.switch_report &&
                data.switch_report.map((d) => {
                    return <DownloadFileSection data={d}
                                                downloadFunction={(uuid: string, fileName: string, fileType: string) => downloadFunction(uuid, fileName, fileType)}/>
                })
                }
                {!data.switch_report &&
                <Typography variant={'body1'}>–</Typography>
                }

            </Box>
        </Card>
    </Box>
})

// ======================
// IscsiAdaptorCard
// ======================

interface IscsiAdaptorCardProps {
    data: MigrationQIscsiAdaptorInfo,
    index: number,
    single: boolean,
}

export const IscsiAdaptorCard: React.FC<IscsiAdaptorCardProps> = observer((props) => {
    const {single, index, data} = props;
    return <>
        {single &&
        <Grid item xs={12}>
            <Card elevation={0}>
                <Box p={2}>
                    <Typography variant={'subtitle2'}>iSCSI Adaptor</Typography>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={3}>
                            <DataItem label={'Make'} data={data.make}/>
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <DataItem label={'Model'} data={data.model}/>

                        </Grid>
                    </Grid>
                </Box>

            </Card>
        </Grid>
        }
        {!single &&
        <Grid item xs={6} md={4}>
            <Card elevation={0}>
                <Box p={2}>
                    <Typography variant={'subtitle2'}>iSCSI Adaptor {index}</Typography>
                    <DataItem label={'Make'} data={data.make} pb={2} pt={2}/>
                    <DataItem label={'Model'} data={data.model} pb={2}/>
                </Box>

            </Card>
        </Grid>
        }
    </>
})


// ======================
// SourceStorageCard
// ======================

interface SourceStorageCardProps {
    data: MigrationQSourceStorageEntry,
    index: number,
    single: boolean,
    downloadFunction: (uuid: string, fileName: string, fileType: string) => any;
}

export const SourceStorageCard: React.FC<SourceStorageCardProps> = observer((props) => {
    const {data, index, single, downloadFunction} = props;

    return (<>
            {single &&
            <Grid item xs={12}>
                <Card elevation={0}>
                    <Box p={2}>
                        <Typography variant={'subtitle2'}>Source Storage</Typography>
                        <br/>
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={3}>
                                <DataItem label={'Make'} data={data.make}/>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <DataItem label={'Model'} data={data.model}/>

                            </Grid>
                            <Grid item xs={6} md={3}>
                                <DataItem label={'Ports'} data={data.targets}/>

                            </Grid>
                            <Grid item xs={6} md={3}>
                                <DataItem label={'Credentials'} data={data.credentials ? 'Yes' : 'No'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant={'overline'}>Storage Report File</Typography>
                                {data.storage_report_file &&
                                <DownloadFileSection data={data.storage_report_file}
                                                     downloadFunction={(uuid: string, fileName: string, fileType: string) => downloadFunction(uuid, fileName, fileType)}/>
                                }
                                {!data.storage_report_file &&
                                <Typography variant={'body1'}>–</Typography>
                                }
                            </Grid>
                        </Grid>
                    </Box>

                </Card>
            </Grid>
            }
            {!single &&
            <Grid item xs={6} md={4}>
                <Card elevation={0}>
                    <Box p={2}>
                        <Typography variant={'subtitle2'}>Source Storage {index}</Typography>
                        <DataItem label={'Make'} data={data.make} pb={2} pt={2}/>
                        <DataItem label={'Model'} data={data.model} pb={2}/>
                        <DataItem label={'Ports'} data={data.targets} pb={2}/>
                        <DataItem label={'Credentials'} data={data.credentials ? 'Yes' : 'No'}/>
                        <Box pt={2}>
                            <Typography variant={'overline'}>Storage Report File</Typography>
                            {data.storage_report_file &&
                            <DownloadFileSection data={data.storage_report_file}
                                                 downloadFunction={(uuid: string, fileName: string, fileType: string) => downloadFunction(uuid, fileName, fileType)}/>
                            }
                            {!data.storage_report_file &&
                            <Typography variant={'body1'}>–</Typography>
                            }
                        </Box>

                    </Box>

                </Card>
            </Grid>
            }
        </>
    )
})

// ======================
// SourceFabricSection
// ======================

interface SourceFabricSectionProps {
    data: MigrationQSourceFabricInfo,
    downloadFunction: (uuid: string, fileName: string, fileType: string) => any,
}

export const SourceFabricSection: React.FC<SourceFabricSectionProps> = observer((props) => {
    const styles = useQuestionnaireStyles(props);
    const {data, downloadFunction} = props;
    return (
        <Box>
            <Box pb={2}>
                <Typography variant={'h6'}>FC Switches and Fabric</Typography>
            </Box>
            <Card elevation={0}>
                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <DataItem label={'Vendor'} data={data.vendor}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DataItem label={'Model'} data={data.model}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DataItem label={'Link Speed'} data={`${data.link_speed} Gbps`}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DataItem label={'Virtual Fabric / VSAN Enabled'} data={data.vsan_enabled ? 'Yes' : 'No'}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DataItem label={'Port Zone Used'} data={data.port_zone ? 'Yes' : 'No'}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <DataItem label={'Switch Credentials'} data={data.credentials ? 'Yes' : 'No'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <DataItem label={'Number Of Licensed Free Switch Ports Per Fabric'}
                                      data={data.fabric_restrictions ? data.fabric_restrictions : 'N/A'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'overline'}>Switch Report File</Typography>
                            {data.switch_report_file &&
                            <DownloadFileSection data={data.switch_report_file} downloadFunction={downloadFunction}/>
                            }
                            {!data.switch_report_file &&
                            <Typography variant={'body1'}>–</Typography>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Box>
    )
})

// ======================
// HostsAndVolumesSection
// ======================

interface HostsAndVolumesSectionProps {
    data: MigrationQSANConfigInfo
}

export const HostsAndVolumesSection: React.FC<HostsAndVolumesSectionProps> = observer((props) => {
    const {data} = props;
    const hosts = Object.keys(data.total_hosts);

    const titles: { [key: string]: string } = {
        windows: 'Windows',
        linux: 'Linux',
        aix: 'AIX',
        hpux: 'HP-UX',
        esx: 'ESX',
        solaris: 'Solaris'
    }

    return (<>
            <Box pb={2}>
                <Typography variant={'h6'}>Hosts and Volumes</Typography>
            </Box>
            <Box pb={2}>
                <Grid container spacing={2}>
                    {hosts.map(host => {
                        if (data.total_hosts[host] > 0) {
                            return (
                                <Grid item xs={6} md={4}>
                                    <Card elevation={0}>
                                        <Box p={2}>
                                            <Box pb={2}>
                                                <Typography variant={'subtitle2'}>{titles[host]}</Typography>
                                            </Box>
                                            <DataItem label={'Hosts'} data={data.total_hosts[host]} pb={2}/>
                                            <DataItem label={'Avg. Initiators'} data={data.average_initiators[host]}
                                                      pb={2}/>
                                            <DataItem label={'Cluster'} data={data.cluster[host] ? 'Yes' : 'No'}
                                                      pb={2}/>
                                            <DataItem label={'Boot from SAN'}
                                                      data={data.san_boot[host] ? 'Yes' : 'No'}/>
                                        </Box>
                                    </Card>
                                </Grid>
                            )
                        }
                    })}
                </Grid>
            </Box>
            <Card elevation={0}>
                <Box p={2}>
                    <Box pb={2}>
                        <DataItem label={'Additional Host OS and Types Description'}
                                  data={data.host_type_description ? data.host_type_description : '–'}/>
                    </Box>
                    <Box pb={2}>
                        <DataItem label={'Blade Servers Vendor & Model'}
                                  data={data.blade_server_notes ? data.blade_server_notes : '–'}/>
                    </Box>
                    <Box>
                        <DataItem label={'LPARs In-Scope'} data={data.lpar_notes ? data.lpar_notes : '–'}/>
                    </Box>
                </Box>
            </Card>

        </>
    )
})

// ======================
// DestinationStorageSection
// ======================

interface DestinationStorageSectionProps {
    data: MigrationQDestinationInfo
}

export const DestinationStorageSection: React.FC<DestinationStorageSectionProps> = observer((props) => {
    const {data} = props;

    return (
        <Box>
            <Box pb={2}>
                <Typography variant={'h6'}>Destination Storage</Typography>
            </Box>
            <Box>
                <Grid container spacing={3}>
                    {data.entries.map(entry => {
                        return <DestinationStorageCard data={entry} index={data.entries.indexOf(entry) + 1}
                                                       single={data.entries.length === 1}/>
                    })}
                </Grid>
            </Box>
        </Box>
    )
})

// ======================
// DestinationStorageCard
// ======================

interface DestinationStorageCardProps {
    data: MigrationQDestinationEntry,
    index: number,
    single: boolean
}

export const DestinationStorageCard: React.FC<DestinationStorageCardProps> = observer((props) => {
    const {data, index, single} = props;

    return (<>
            {single &&
            <Grid item xs={12} md={12}>
                <Card elevation={0}>
                    <Box p={2}>
                        <Typography variant={'subtitle2'}>Destination Storage</Typography>
                        <br/>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <DataItem label={'Model'} data={data.model}/>
                            </Grid>
                            <Grid item xs={6}>
                                <DataItem label={'Credentials'} data={data.credentials ? 'Yes' : 'No'}/>
                            </Grid>
                        </Grid>
                    </Box>

                </Card>
            </Grid>
            }
            {!single &&
            <Grid item xs={6} md={4}>
                <Card elevation={0}>
                    <Box p={2}>
                        <Typography variant={'subtitle2'}>Destination Storage {index}</Typography>
                        <DataItem label={'Model'} data={data.model} pb={2}/>
                        <DataItem label={'Credentials'} data={data.credentials ? 'Yes' : 'No'}/>
                    </Box>

                </Card>
            </Grid>
            }
        </>
    )
})

// ======================
// NetworkAccessSection
// ======================

interface NetworkAccessSectionProps {
    data: MigrationQNetworkInfo
}

export const NetworkAccessSection: React.FC<NetworkAccessSectionProps> = observer((props) => {
    const {data} = props;
    return (
        <Box>
            <Box pb={2}>
                <Typography variant={'h6'}>Network Access</Typography>
            </Box>
            <Card elevation={0}>
                <Box p={2}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <DataItem label={'Local Firewall Restriction'}
                                      data={data.local.firewall_restrictions ? "Yes" : "No"} pb={2}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DataItem label={'Modifiable Firewall/Security'}
                                      data={data.local.firewall_rules_modifiable ? "Yes" : "No"} pb={2}/>
                        </Grid>
                        {data.local.network_security_notes &&
                        <Grid item xs={12}>
                            <DataItem label={'Additional Security Notes'} data={data.local.network_security_notes}
                                      pb={2}/>
                        </Grid>
                        }
                        <Grid item xs={12}>
                            <DataItem label={'Remote Access Availability'}
                                      data={data.remote.remote_access_availability ? data.remote.remote_access_availability : 'N/A'}/>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Box>
    )
})

// ======================
// MigrationTimeFrameSection
// ======================

interface MigrationTimeFrameSectionProps {
    data: MigrationQTimeFrameInfo
}

export const MigrationTimeFrameSection: React.FC<MigrationTimeFrameSectionProps> = observer((props) => {

    const {data} = props;
    return (
        <Box>
            <Box pb={2}>
                <Typography variant={'h6'}>Migration Time Frame</Typography>
            </Box>
            <Card elevation={0}>
                <Box p={2}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <Box pb={2}>
                                <Typography variant={'overline'}>Required Time Frame</Typography>
                                <Path from={data.must_start_date as string} to={data.must_complete_date as string}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box pb={2}>
                                <Typography variant={'overline'}>Expected Time Frame</Typography>
                                <Path from={data.expected_start_date as string}
                                      to={data.expected_complete_date as string}/>
                            </Box>

                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Box>
    )
})

// ======================
// DeploymentSiteInfoSection
// ======================

interface DeploymentSiteInfoSectionProps {
    data: MigrationQSiteInfo
}

export const DeploymentSiteInfoSection: React.FC<DeploymentSiteInfoSectionProps> = observer((props) => {
    const {data} = props;

    return (
        <Box>
            <Box pb={2}>
                <Typography variant={'h6'}>Deployment Site Info</Typography>
            </Box>
            <Card elevation={0}>
                <Box pl={2} pt={2} pr={2}>
                    <Box pb={2}>
                        <Typography variant={'subtitle2'}>Customer Contact</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataItem label={'Company'} data={data.customer.company} pb={2}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataItem label={'Name'} data={data.customer.name ? data.customer.name : '–'} pb={2}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataItem label={'Phone'} data={data.customer.phone ? data.customer.phone : '–'} pb={2}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataItem label={'Email'} data={data.customer.email ? data.customer.email : '–'} pb={2}/>
                        </Grid>
                    </Grid>
                </Box>
                <Box pl={2} pt={2} pr={2}>
                    <Box pb={2}>
                        <Typography variant={'subtitle2'}>On-Site Contact</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataItem label={'Name'} data={data.customer.name ? data.customer.name : '–'} pb={2}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataItem label={'Phone'} data={data.customer.phone ? data.customer.phone : '–'} pb={2}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataItem label={'Email'} data={data.customer.email ? data.customer.email : '–'} pb={2}/>
                        </Grid>
                    </Grid>
                </Box>
                <Box pl={2} pt={2} pr={2}>
                    <Box pb={2}>
                        <Typography variant={'subtitle2'}>Site Details</Typography>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataItem label={'Address'}
                                      data={data.location.source.address ? data.location.source.address : '–'} pr={2}
                                      pb={2}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataItem label={'Credentials Required'}
                                      data={data.location.source.security_requirements ? data.location.source.security_requirements : 'N/A'}
                                      pb={2}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataItem label={'Escort Required'}
                                      data={data.location.source.escort_required ? "Yes" : 'No'} pb={2}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <DataItem label={'Installation Permission'}
                                      data={data.location.source.installation_permission ? data.location.source.installation_permission : '–'}
                                      pb={2}/>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Box>
    )
})

// ======================
// MiscInfoSection
// ======================

interface MiscInfoSectionProps {
    data: MigrationQMiscInfo
}

export const MiscInfoSection: React.FC<MiscInfoSectionProps> = observer((props) => {

    const {data} = props;
    return (
        <Box>
            <Box pb={2}>
                <Typography variant={'h6'}>Additional Information</Typography>
            </Box>
            <Card elevation={0}>
                <Box p={2}>
                    <DataItem label={'Salesforce Deal Identifier'}
                              data={data.deal_identifier ? data.deal_identifier : '–'}/>
                </Box>
            </Card>
        </Box>
    )
})

// ======================
// DownloadFileSection
// ======================

interface DownloadFileSectionProps {
    data: Array<DOWNLOAD_FILE_TYPE> | DOWNLOAD_FILE_TYPE
    downloadFunction: (uuid: string, fileName: string, fileType: string) => any
}

export const DownloadFileSection: React.FC<DownloadFileSectionProps> = observer((props) => {
    const {data, downloadFunction} = props;
    if (Array.isArray(data)) {
        return <Grid container spacing={3}>
            {data.map((file: DOWNLOAD_FILE_TYPE) => {
                return <Grid item xs={12} sm={4}><DownloadFileCard file={file}
                                                                   downloadFunction={downloadFunction}/></Grid>
            })}
        </Grid>
    } else if (data.uuid) {
        return <Box pr={3}><Grid item xs={12} sm={4}><DownloadFileCard file={data} downloadFunction={downloadFunction}/></Grid></Box>
    } else {
        return <Typography variant={'body1'}>–</Typography>

    }
})

// ======================
// DownloadFileCard
// ======================

interface DownloadFileCardProps {
    file: DOWNLOAD_FILE_TYPE
    downloadFunction: (uuid: string, fileName: string, fileType: string) => any,

}

export const DownloadFileCard: React.FC<DownloadFileCardProps> = observer((props) => {
    const styles = useQuestionnaireStyles(props);
    const {file, downloadFunction} = props;
    return <Box>
        <Card variant={'outlined'} className={styles.downloadButton}
              onClick={() => downloadFunction(file.uuid, file.fileName, file.fileType)}>
            <Box p={2} display={'flex'} alignItems={'center'}>
                <Box pr={2}>
                    <SvgIcon>
                        <GoCloudDownload/>
                    </SvgIcon>
                </Box>
                <Box flexGrow={1}>
                    <Link>
                        <Typography variant={'body1'}>{truncateFileName(file.fileName, 30)}</Typography>
                    </Link>
                    <Typography variant={'body2'} color={'textSecondary'}>{xbytes(file.fileSize)}</Typography>

                </Box>
            </Box>
        </Card>
    </Box>
})

// ======================
// QuestionnaireHeader
// ======================

interface QuestionnaireHeaderProps {
    approveProject?: () => void,
    deleteProject?: () => void,
    popoverState: PopoverState
}

export const QuestionnaireHeader: React.FC<QuestionnaireHeaderProps> = observer((props) => {

    const {approveProject, deleteProject, popoverState} = props;

    const styles = useQuestionnaireStyles(props);
    const isMobile = useIsMobile();

    return <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} pb={4}>
        <Box>
            <Typography variant={'h4'}>Questionnaire </Typography>
        </Box>
        {!isMobile &&
        <Box display={'flex'}>
            <Box pr={1}>
                <Button onClick={approveProject} variant={'contained'} className={styles.approveButton} disableElevation
                        startIcon={<SvgIcon htmlColor={'#ffffff'}><IoMdCheckmarkCircleOutline/></SvgIcon>}>
                    <Box color={'#fff'}>Approve</Box>
                </Button>
            </Box>
            <Box pl={1}>
                <Button onClick={deleteProject} variant={'contained'} disableElevation className={styles.deleteButton}
                        startIcon={<SvgIcon htmlColor={'#ffffff'}><FiTrash2/></SvgIcon>}>
                    <Box color={'#fff'}>Delete</Box>
                </Button>
            </Box>
        </Box>
        }
        {isMobile &&
        <Box>
            <PopoverIconButton popoverState={popoverState} label={'Actions'} icon={<MdMenu/>} noGutter>
                <Box pl={2} pt={2}>
                    <Typography variant={'h6'}>Actions</Typography>
                </Box>

                <MenuList>
                    <MenuItem onClick={approveProject}><SvgIcon fontSize={'small'}><IoMdCheckmarkCircleOutline/>
                    </SvgIcon> &nbsp; Approve Project</MenuItem>
                    <MenuItem onClick={deleteProject}><SvgIcon fontSize={'small'}><FiTrash2/></SvgIcon> &nbsp; Delete
                        Project</MenuItem>
                </MenuList>
            </PopoverIconButton>
        </Box>
        }
    </Box>
})

// ======================
// EmailFriendlyDialog
// ======================

interface EmailFriendlyDialogProps {
    data: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
    open: boolean;
    handleClose: () => void;
}

export const EmailFriendlyDialog: React.FC<EmailFriendlyDialogProps> = observer((props) => {
    const {open, data, handleClose} = props;


    return <Dialog open={open} maxWidth={'lg'} onClose={handleClose}>
        <ClipboardText text={<EmailFriendlyTextDisplay data={data}/>} id={'questionnaire'}
                       title={'Email-Friendly Form'}/>
    </Dialog>
})

// ======================
// EmailFriendlyTextDisplay
// ======================

interface EmailFriendlyTextDisplayProps {
    data: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
}

export const EmailFriendlyTextDisplay: React.FC<EmailFriendlyTextDisplayProps> = observer((props) => {

    const {data} = props;

    const additionalSiteInfo = data.additional_site_info || [];

    return <Container maxWidth={'lg'}>
        <Box pt={2} pb={10}>
            <Typography variant={'h4'}>Questionnaire: {data.site_info.customer.company}</Typography>
            <br/>
            <Typography variant={'h6'}>Contact Info</Typography>
            <Typography variant={'body1'}>Name: {data.user.name}</Typography>
            <Typography variant={'body1'}>Email: {data.user.email}</Typography>
            <Typography variant={'body1'}>Phone: {data.user.phone}</Typography>
            <br/>
            <ProjectSiteTextDisplay data={data}/>
            <br/>
            {additionalSiteInfo.map((s, i)=> {
                return <ProjectSiteTextDisplay data={s} additionalSiteIndex={i} />
            })}
            <Typography variant={'h6'}>Additional Information</Typography>
            <Typography variant={'body1'}>Salesforce Deal Identifier: {data.misc.deal_identifier}</Typography>

        </Box>
    </Container>
});

// ======================
// ProjectSiteTextDisplay
// ======================

interface ProjectSiteTextDisplayProps{
    data: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE;
    additionalSiteIndex?: number;
}

export const ProjectSiteTextDisplay: React.FC<ProjectSiteTextDisplayProps> = observer((props)=>{
    const {data, additionalSiteIndex} = props;
    const getIndex = (array: Array<any>, entry: any) => {
        return array.indexOf(entry) + 1
    }

    const displayBool = (bool: boolean) => {
        return bool ? "Yes" : "No"
    }

    const hosts = Object.keys(data.san_config.total_hosts);

    const titles: { [key: string]: string } = {
        windows: 'Windows',
        linux: 'Linux',
        aix: 'AIX',
        hpux: 'HP-UX',
        esx: 'ESX',
        solaris: 'Solaris'
    }

    return <>
        <Typography variant={'h5'}>{additionalSiteIndex >= 0 ? `Site ${additionalSiteIndex+2}` : `Primary Site`}</Typography>
        <br/>
        <Typography variant={'body1'}><b>Site Information</b></Typography>
        <Typography variant={'body1'}>Address: {data.site_info.location.source.address}</Typography>
        <Typography variant={'body1'}>Credentials
            Required: {data.site_info.location.source.security_requirements}</Typography>
        <Typography variant={'body1'}>Escort
            Required: {displayBool(data.site_info.location.source.escort_required)}</Typography>
        <Typography variant={'body1'}>Installation
            Permission: {data.site_info.location.source.installation_permission}</Typography>
        <br/>
        <Typography variant={'h6'}>Source Storage</Typography>
        <Typography variant={'body1'}>Total Capacity: {data.source_storage.total_capacity}TiB</Typography>
        <Typography variant={'body1'}>SAN Connectivity: {data.source_storage.san_connectivity}</Typography>
        <br/>
        {data.source_storage.iscsi &&
        data.source_storage.iscsi.adaptors.map((d, i) => {
            return <>
                <Typography variant={'body1'}><b>iSCSI Adaptor {i + 1}</b></Typography>
                <Typography variant={'body1'}>Make: {d.make}</Typography>
                <Typography variant={'body1'}>Model: {d.model}</Typography>
                <br/>
            </>
        })

        }
        {data.source_storage.entries.map(entry => {
            return <>
                <Typography variant={'body1'}><b>Source
                    Storage {getIndex(data.source_storage.entries, entry)}</b></Typography>
                <Typography variant={'body1'}>Make: {entry.make}</Typography>
                <Typography variant={'body1'}>Model: {entry.model}</Typography>
                <Typography variant={'body1'}>Ports: {entry.targets}</Typography>
                <Typography variant={'body1'}>Credentials: {displayBool(entry.credentials)}</Typography>
                <br/>
            </>
        })}
        <Typography variant={'h6'}>FC Switches and Fabric</Typography>
        <Typography variant={'body1'}>Vendor: {data.source_fabric.vendor}</Typography>
        <Typography variant={'body1'}>Model: {data.source_fabric.model}</Typography>
        <Typography variant={'body1'}>Link Speed: {data.source_fabric.link_speed}</Typography>
        <Typography variant={'body1'}>Virtual Fabric / VSAN
            Enabled: {displayBool(data.source_fabric.vsan_enabled)}</Typography>
        <Typography variant={'body1'}>Port Zone Used: {displayBool(data.source_fabric.port_zone)}</Typography>
        <Typography variant={'body1'}>Switch
            Credentials: {displayBool(data.source_fabric.credentials)}</Typography>
        <Typography variant={'body1'}>Number of Licensed Free Switch Ports Per
            Fabric: {data.source_fabric.fabric_restrictions}</Typography>
        <br/>
        <Typography variant={'h6'}>Hosts and Volumes</Typography>
        {hosts.map(host => {
            if (data.san_config.total_hosts[host] > 0) {
                return <>
                    <Typography variant={'body1'}><b>{titles[host]}</b></Typography>
                    <Typography variant={'body1'}>Hosts: {data.san_config.total_hosts[host]}</Typography>
                    <Typography variant={'body1'}>Avg.
                        Initiators: {data.san_config.average_initiators[host]}</Typography>
                    <Typography
                        variant={'body1'}>Cluster: {displayBool(data.san_config.cluster[host])}</Typography>
                    <Typography variant={'body1'}>Boot from
                        SAN: {displayBool(data.san_config.san_boot[host])}</Typography>
                    <br/>
                </>
            }
        })
        }
        <Typography variant={'body1'}>Additional Host OS and Types
            Description: {data.san_config.host_type_description}</Typography>
        <Typography variant={'body1'}>Blade Servers Vendor &
            Model: {data.san_config.blade_server_notes || "N/A"}</Typography>
        <Typography variant={'body1'}>LPARs In Scope: {data.san_config.lpar_notes || "N/A"}</Typography>
        <br/>
        <Typography variant={'h6'}>Destination Storage</Typography>
        {data.destination.entries.map(entry => {
            return <>
                <Typography variant={'body1'}><b>Destination
                    Storage {getIndex(data.destination.entries, entry)}</b></Typography>
                <Typography variant={'body1'}>Model: {entry.model}</Typography>
                <Typography variant={'body1'}>Credentials: {displayBool(entry.credentials)}</Typography>
                <br/>
            </>
        })
        }
        <Typography variant={'h6'}>Network Access</Typography>
        <Typography variant={'body1'}>Local Firewall
            Restriction: {data.network.local.firewall_restrictions}</Typography>
        <Typography variant={'body1'}>Modifiable
            Firewall/Security: {data.network.local.firewall_rules_modifiable}</Typography>
        <Typography variant={'body1'}>Remote Access
            Availability: {data.network.remote.remote_access_availability}</Typography>
        <br/>
        <Typography variant={'h6'}>Migration Time Frame</Typography>
        <Typography variant={'body1'}>Required Time
            Frame: {data.project_time_frame.must_start_date} to {data.project_time_frame.must_complete_date}</Typography>
        <Typography variant={'body1'}>Expected Time
            Frame: {data.project_time_frame.expected_start_date} to {data.project_time_frame.expected_complete_date}</Typography>
        <br/>
        <Typography variant={'h6'}>Deployment Site</Typography>
        <Typography variant={'body1'}><b>Customer Contact</b></Typography>
        <Typography variant={'body1'}>Company: {data.site_info.customer.company}</Typography>
        <Typography variant={'body1'}>Name: {data.site_info.customer.name}</Typography>
        <Typography variant={'body1'}>Email: {data.site_info.customer.email}</Typography>
        <Typography variant={'body1'}>Phone: {data.site_info.customer.phone}</Typography>
        <br/>
        <Typography variant={'body1'}><b>On-Site Contact</b></Typography>
        <Typography variant={'body1'}>Name: {data.site_info.location.source.onsite_contact.name}</Typography>
        <Typography variant={'body1'}>Email: {data.site_info.location.source.onsite_contact.email}</Typography>
        <Typography variant={'body1'}>Phone: {data.site_info.location.source.onsite_contact.phone}</Typography>
    </>
})


const truncateFileName = (fileName: string, numOfChars: number) => {
    const nameWithoutExtension = fileName.slice(0, fileName.indexOf('.'))
    const nameNoExLength = nameWithoutExtension.length;
    const nameLength = fileName.length;
    const extension = fileName.slice(fileName.indexOf('.'), nameLength);
    let newName = fileName;

    if (nameLength > numOfChars) {
        const diff = nameLength - numOfChars;

        const middle = Math.floor(nameNoExLength / 2)

        const startingIndex = middle - Math.ceil(diff / 2);

        const endingIndex = Math.floor(diff / 2) + middle + 3;

        newName = `${nameWithoutExtension.slice(0, startingIndex)}...${nameWithoutExtension.slice(endingIndex, nameNoExLength)}${extension}`
    }
    return newName;
}