// ======================
// MuiTabContent
// ======================

import React from "react";
import {observer} from "mobx-react-lite";
import {Box} from "@material-ui/core";

export interface MuiTabContentProps{
    value: number,
    index: number
}

export const MuiTabContent: React.FC<MuiTabContentProps> = observer((props)=>{
    return <Box width={'100%'}>
        {props.value===props.index && props.children}
    </Box>
})