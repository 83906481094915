// ======================
// ProposedMigrationProjectTable
// ======================

import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {useAppServices} from "../app/AppStates";
import {useIsDesktop} from "../layout/MainLayout";
import {useInitData, useServerData} from "../core/data/DataLoaderHooks";
import {LoadingScreen} from "../../common/splash/SplashScreen";
import {Box, Button, Card, Chip, IconButton, MenuItem, MenuList, SvgIcon, Tooltip} from "@material-ui/core";
import {PSCardList} from "../../common/table/PSTable";
import {PROJECT_TYPE} from "./MigrationProjectTypes";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {APP_ROUTES} from "../app/AppRoutes";
import {Path} from "../../common/path/Path";
import {FaLongArrowAltRight, FiTrash2, IoMdCheckmarkCircleOutline, MdMenu} from "react-icons/all";
import xbytes from "xbytes";
import {PopoverState} from "../../common/popover/PopoverService";
import {PopoverIconButton} from "../../common/popover/PopoverButton";
import {ContactView, EmployeeView} from "../auth/EmployeeView";
import {renderDealType, shortenDate, shortenName, useMigrationProjectListStyles} from "./MigrationProjectCommon";

interface ProposedMigrationProjectTableProps {
}

export const ProposedMigrationProjectTable: React.FC<ProposedMigrationProjectTableProps> = observer((props) => {

    const {migrationProjectService} = useAppServices();
    const isDesktop = useIsDesktop();

    useInitData({
        poll: () => migrationProjectService.fetchProposedProjects(),
        pollInterval: 1
    })

    return useServerData(migrationProjectService.proposedProjectsTableData, <LoadingScreen/>, data => {

        return <Box pt={2}>
            {isDesktop &&
            <PSCardList tableData={data}
                        cardComponent={ProposedMigrationProjectCard}
                        tableState={migrationProjectService.proposedProjectsTableState}
            />
            }
            {!isDesktop &&
            <PSCardList tableData={data} cardComponent={ProposedMigrationProjectMobileCard}
                        refreshFunction={() => migrationProjectService.fetchProposedProjects()}
                        tableState={migrationProjectService.proposedProjectsTableState}
            />

            }
        </Box>

    })
})

// ======================
// ProposedMigrationProjectCard
// ======================

interface ProposedMigrationProjectCardProps {
    data: PROJECT_TYPE
}

export const ProposedMigrationProjectCard: React.FC<ProposedMigrationProjectCardProps> = observer((props) => {
    const {data} = props;
    const {migrationProjectService} = useAppServices();
    const styles = useMigrationProjectListStyles(props);

    const approveProject = async () => await migrationProjectService.approveProposedProject(data.projectId)
    const deleteProject = async () => await migrationProjectService.deleteProject(data.projectId)

    return <Box pb={2}>
        <Card>
            <Box pt={1} pl={2}>
                <Box pl={1}>
                    <Typography variant={'overline'}>ID: {data.project.projectCode}</Typography>
                </Box>
            </Box>
            <Box pl={2} pr={2} pb={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <Box width={280}>
                    <Box pb={1}>
                        <Link to={`${APP_ROUTES.MIGRATION}/${data.projectId}`}
                              data-testid={`${data.project.projectName}Details`}>
                            <Tooltip title={data.project.projectName}>
                                <Button className={styles.button}>{shortenName(data.project.projectName, 30)}</Button>
                            </Tooltip>
                        </Link>
                    </Box>
                </Box>
                <Box width={320} display={'flex'} alignItems={'center'}>
                    <Path from={shortenName(data.migrationSource, 28)} to={shortenName(data.migrationDestination, 28)}/>
                </Box>
                <Box display={'flex'}>
                    <Tooltip title={'Approve Project'}>
                        <IconButton onClick={approveProject}>
                            <SvgIcon htmlColor={'#4caf50'}>
                                <IoMdCheckmarkCircleOutline/>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Delete Project'}>
                        <IconButton onClick={deleteProject} color={'inherit'}>
                            <SvgIcon color={'error'}>
                                <FiTrash2/>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            <Box pl={2} pb={2} pr={2} display={"flex"} justifyContent={'space-between'}>
                <Box>
                    <Chip size={"small"} variant={'outlined'}
                          label={xbytes(data.migrationDataAmount as number)}/>
                </Box>
                <Box>
                    <Chip size={'small'} label={<Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Typography variant={'caption'}>
                            {shortenDate(data.project.projectTimeFrameFrom)}
                        </Typography>
                        <FaLongArrowAltRight/>
                        <Typography variant={'caption'}>
                            {shortenDate(data.project.projectTimeFrameTo)}
                        </Typography>
                    </Box>}/>
                </Box>

            </Box>
        </Card>
    </Box>
})

// ======================
// ProposedMigrationProjectMobileCard
// ======================

interface ProposedMigrationProjectMobileCardProps {
    data: PROJECT_TYPE
}

export const ProposedMigrationProjectMobileCard: React.FC<ProposedMigrationProjectMobileCardProps> = observer((props) => {

    const {data} = props;
    const styles = useMigrationProjectListStyles(props);
    const {migrationProjectService} = useAppServices();

    const [popoverState, setPopoverState] = useState(new PopoverState(data.projectId))

    const approveProject = async () => await migrationProjectService.approveProposedProject(data.projectId)
    const deleteProject = async () => await migrationProjectService.deleteProject(data.projectId)

    return <Box pb={2}>
        <Card>
            <Box pl={2} pt={1} pb={1} className={styles.cardTitleBar}>
                <Typography variant={'overline'}>ID: {data.project.projectCode}</Typography>
                <Box pr={1}>
                    <PopoverIconButton popoverState={popoverState} label={'actions'} icon={<MdMenu/>} noGutter>
                        <Box pl={2} pt={2}>
                            <Typography variant={'h6'}>Actions</Typography>
                        </Box>

                        <MenuList>
                            <MenuItem onClick={approveProject}><SvgIcon fontSize={'small'}><IoMdCheckmarkCircleOutline/>
                            </SvgIcon> &nbsp; Approve Project</MenuItem>
                            <MenuItem onClick={deleteProject}><SvgIcon
                                fontSize={'small'}><FiTrash2/></SvgIcon> &nbsp; Delete Project</MenuItem>
                        </MenuList>
                    </PopoverIconButton>
                </Box>

            </Box>
            <Box pr={2} pl={1} pb={1} className={styles.cardTitleBar}>
                <Box>
                    <Link to={`${APP_ROUTES.MIGRATION}/${data.projectId}`} className={styles.link}>
                        <Tooltip title={data.project.projectName}>
                            <Button><Typography align={'left'}
                                                variant={'h5'}>{shortenName(data.project.projectName, 24)}</Typography></Button>
                        </Tooltip>
                    </Link>
                </Box>

            </Box>
            <Box pl={2} pr={2} pb={2} className={styles.cardTitleBar}>
                <Typography variant={'h6'}>{xbytes(data.migrationDataAmount as number)}</Typography>
                <Box className={styles.path}>
                    <Typography variant={'h6'}>
                        {shortenDate(data.project.projectTimeFrameFrom)}
                    </Typography>
                    <FaLongArrowAltRight/>
                    <Typography variant={'h6'}>
                        {shortenDate(data.project.projectTimeFrameTo)}
                    </Typography>
                </Box>

            </Box>
            <Box pl={2} pr={2} pb={1} className={styles.cardTitleBar}>
                <EmployeeView>
                    <Chip label={renderDealType(data.dealType, 'caption')} size={'small'}/>
                </EmployeeView>
                <ContactView>
                    <Box width={10}/>
                </ContactView>
                <Box className={styles.path}>
                    <Box pr={1}>
                        <Typography variant={'caption'}>
                            {shortenName(data.migrationSource, 20)}
                        </Typography>
                    </Box>

                    <FaLongArrowAltRight/>
                    <Box pl={1}>
                        <Typography variant={'caption'}>
                            {shortenName(data.migrationDestination, 20)}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Card>
    </Box>
})