/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: FormComponents
 */
import * as React from 'react';
import {
    Box,
    Checkbox,
    CheckboxProps, Chip,
    FilledTextFieldProps, InputAdornment, InputLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText, MenuItem,
    Paper, Select,
    TextField,
    TextFieldProps,
    makeStyles,
    Theme,
    Input, FormControl, OutlinedInput, FilledInput, FormHelperText, SelectProps, FormControlLabel, Typography,
} from '@material-ui/core';
import {FieldProps, useField} from 'formik';
import {observer} from 'mobx-react-lite';
import {FaUserCircle} from "react-icons/all";
import {
    MuiPickersUtilsProvider,
    DatePicker,
    KeyboardDatePicker,
    KeyboardDateTimePicker,
    DateTimePickerProps, DatePickerProps
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { AutoCompleteFieldProps } from "../../modules/core/autocomplete/AutocompleteField";
import {Autocomplete} from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";


// ======================
// FormikTextField
// ======================
interface FormTextFieldProps {
    label: string,
    name: string,
    icon?: React.ReactElement,
}

export const FormTextField: React.FC<FormTextFieldProps & Partial<TextFieldProps> & Partial<FieldProps>> = observer(({...p}) => {
    const [field, meta] = useField(p.name);
    const error = meta.touched ? meta.error : null;
    //@ts-ignore
    return <TextField variant={'outlined'} fullWidth error={!!error} name={field.name} label={p.label}
                      helperText={error} {...p} {...field}
                      multiline={p.multiline}
                      InputProps={p.icon ? {
                          startAdornment: (
                              <InputAdornment position={"start"}>
                                  {p.icon}
                              </InputAdornment>
                          ),
                      } : null}
                      inputProps={
                          {'aria-label': p.label}
                      }
    />;
});

interface FormCheckboxItemProps {
    label: string;
    name: string;
    helperText?: React.ReactNode,
    checkBoxProps?: Partial<CheckboxProps>,
    defaultChecked?: boolean
}

export const FormCheckboxItem: React.FC<FormCheckboxItemProps & Partial<CheckboxProps> & Partial<FieldProps>> = observer(({label, helperText, ...p}) => {
    const [field, meta, helpers] = useField(p.name);
    const error = meta.touched ? meta.error : null;
    const {value} = meta;
    const {setValue} = helpers;
    const defaultChecked = p.defaultChecked ? p.defaultChecked : value;
    return <Box pt={1} pb={1}>
        <FormControlLabel
            id={p.name}
            control={<Checkbox
                inputProps={{'aria-labelledby': p.name, id:p.name}}
                {...p.checkBoxProps}
                name={field.name} {...field}
                checked={field.checked}
                defaultChecked={defaultChecked}
                onChange={(e) => {
                    //console.log(e.target.checked)
                    setValue(e.target.checked);
                    //field.onChange(e)

                }}
                value={value}
            />}
            label={<Typography variant={'body1'} color={'textPrimary'}>{label}</Typography>}
        />
    </Box>;
});

/*interface FormCheckboxItemProps {
    label: string;
    name: string;
    helperText?: React.ReactNode;
}

export const FormCheckboxItem: React.FC<FormCheckboxItemProps & Partial<CheckboxProps> & Partial<FieldProps>> = observer(({label, helperText, ...p}) => {
    const [field, meta, helpers] = useField(p.name);
    const error = meta.touched ? meta.error : null;
    const {value} = meta;
    const {setValue} = helpers;
    return <Box>
        <ListItem disableGutters>
            <ListItemIcon>
                <Checkbox
                    name={field.name} {...p} {...field}
                    onChange={(e) => setValue(e.target.checked)}
                    value={value}
                />
            </ListItemIcon>
            <ListItemText primary={label} secondary={helperText}/>
        </ListItem>
    </Box>;
});*/

// ======================
// FormDatePicker
// ======================

interface FormDatePickerProps {
    label: string,
    name: string,
}

export const FormDatePicker: React.FC<FormDatePickerProps & Partial<DatePickerProps>> = observer((props) => {
    const [field, meta, helpers] = useField(props.name);
    const error = meta.touched ? meta.error : null;
    const {value} = meta;
    const {setValue} = helpers;
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker label={props.label} error={!!error} value={value} helperText={error}
                                onChange={(date) => {
                                    setValue(date)
                                }}
                                variant={'inline'} autoOk inputVariant="outlined" format={'MM/dd/yy'}
                                {...props}
                                fullWidth
            />
        </MuiPickersUtilsProvider>
    )
})

// ======================
// FormDateTimePicker
// ======================

interface FormDateTimePickerProps {
    label: string,
    name: string,
    getErrorValue?: () => boolean;
}

export const FormDateTimePicker: React.FC<FormDateTimePickerProps & Partial<DateTimePickerProps>> = observer((props) => {
    const [field, meta, helpers] = useField(props.name);
    const error = meta.touched ? meta.error : null;
    const {value} = meta;
    const {setValue, setError} = helpers;

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker label={props.label} error={props.getErrorValue ? props.getErrorValue() : !!error}
                                    value={value} helperText={error} onChange={(date) => {
                setValue(date)
            }}
                                    variant={'inline'} autoOk inputVariant="outlined"
                                    format={'MM/dd/yy HH:mm'} {...props}
            />
        </MuiPickersUtilsProvider>
    )
})

// ======================
// FormSelect
// ======================

export const useSelectStyles = makeStyles((theme: Theme) => ({
        field: {
            //margin: theme.spacing(1),
            minWidth: 200,

        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        label: {
            paddingBottom: theme.spacing(2)
        },
        chip: {
            marginLeft: theme.spacing(1),
        }
    }),
);

interface FormSelectProps {
    label: string,
    name: string,
    selectionList: Array<any>,
    multiple?: boolean
    renderMethod?: (array: Array<any>) => any;
    chipRenderMethod?: (array: Array<any>) => (selected: any) => any;
    chipArray?: Array<any>;
}

export const FormSelect: React.FC<FormSelectProps & Partial<SelectProps>> = observer((props) => {
    const [field, meta, helpers] = useField({name: props.name, multiple: !!props.multiple});
    const error = meta.touched ? meta.error : null;
    const {value} = meta;
    const {setValue} = helpers;

    const styles = useSelectStyles(props);

    const renderChip = (selected: any) => {
        return <div>
            {(selected as string[]).map(value => (
                <Chip key={value} label={value} className={styles.chip}/>
            ))}
        </div>
    }

    const renderList = (list: Array<any>) => {
        return list.map(name => (
            <MenuItem key={name} value={name}>
                {name}
            </MenuItem>
        ))
    }

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        props.multiple ? setValue(event.target.value) : setValue(event.target.value as string)
    };

    return (
        <>
            <FormControl fullWidth>
                <InputLabel variant={'outlined'}>{props.label}</InputLabel>

                <Select multiple={props.multiple}
                        fullWidth {...props}
                        className={styles.field}
                        value={value}
                        onChange={handleChange}
                        renderValue={!props.multiple ? selected => selected : !!props.chipRenderMethod ? props.chipRenderMethod(props.chipArray) : renderChip}
                        variant={'outlined'}
                        error={!!error}
                        input={<OutlinedInput labelWidth={props.label.length * 8}/>}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        inputProps={{'aria-label':props.label,
                            'data-testid':props.label}}

                >

                    {!!props.renderMethod ? props.renderMethod(props.selectionList) : renderList(props.selectionList)}
                </Select>
                <FormHelperText error={!!error} variant={'outlined'}>{error}</FormHelperText>
            </FormControl>

        </>
    )
})

// ======================
// AutocompleteFormField
// ======================

interface AutocompleteFormFieldProps {
    name: string;
    filterOptions?: (options: Array<any>) => Array<any>;
}

export const AutocompleteFormField: React.FC<AutocompleteFormFieldProps & Partial<AutoCompleteFieldProps>> = observer((props) => {
    const [field, meta, helpers] = useField(props.name);
    const {value} = meta;
    const {setValue} = helpers;
    const {name, renderOption, getOptionFormat, autocompleteState, label, tableData, style, onChangeFunction, multiple, filterData, id, freeSolo, getOptionLabel, filterOptions, ...p} = props;

    const open = autocompleteState.open;
    const loading = open && autocompleteState.data.loading;

    const setAutoCompleteOpen = async (e: React.ChangeEvent<{}>) => {
        if (!open) {
            autocompleteState.open = true;
            if (!!autocompleteState.fetchDataFunction) {
                await autocompleteState.fetchDataFunction();
            }

        } else {
            autocompleteState.open = false;
            if (autocompleteState.data) {
                autocompleteState.data.resetData();
            }
        }
    }

    const handleInputChange = (e: React.ChangeEvent<{}>, value: any) => {
        autocompleteState.inputValue = value;
    }

    const handleChange = (e: React.ChangeEvent<{}>, value: any) => {
        multiple ? setValue(value as any[]) : setValue(value as string)
    }

    const getOptions = () => {
        let options = [];
        if (autocompleteState.data.ready) {
            if (Array.isArray(autocompleteState.data.data)) {
                if (!getOptionFormat) {
                    options = autocompleteState.data.data
                } else {
                    options = autocompleteState.data.data.map(getOptionFormat)
                }
            } else {
                if (!getOptionFormat) {
                    options = autocompleteState.data.data.items
                } else {
                    options = autocompleteState.data.data.items.map(getOptionFormat)
                }
            }

        }

        if (!!filterOptions) {
            options = filterOptions(options)
        }

        return options;

    }

    return <Autocomplete id={id}
                         open={autocompleteState.open}
                         onOpen={setAutoCompleteOpen}
                         onClose={setAutoCompleteOpen}
                         options={getOptions()}
                         loading={loading}
                         autoHighlight
                         freeSolo={freeSolo}
                         getOptionLabel={getOptionLabel ? getOptionLabel : multiple ? data => data as any[] : data => data as string}
                         style={style}
                         disableOpenOnFocus
                         onInputChange={handleInputChange}
                         onChange={handleChange}
                         inputValue={autocompleteState.inputValue}
                         value={multiple ? value as any[] : value as string}
                         multiple={multiple}
                         renderOption={renderOption}
                         renderInput={p => (
                             <TextField {...p} label={label} fullWidth
                                        InputProps={{
                                            ...p.InputProps,
                                            endAdornment: (
                                                <>
                                                    {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                                    {p.InputProps.endAdornment}
                                                </>
                                            ),
                                        }}/>
                         )}

    />;
})

