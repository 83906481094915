import * as React from 'react';
import {Box, CircularProgress, makeStyles, Theme, Typography} from "@material-ui/core";
import { observer } from 'mobx-react-lite';

const useLoadingStyles = makeStyles((t: Theme)=>({
    splash:{
        background: 'linear-gradient(0deg, #c5cae9 10%, #1e88e5 90%)',
        height: '100vh',
        width:'100vw',
        display: 'flex',
        justifyContent:'center'
    },
    load:{
        height: '50vh',
        width:'100%',
        display: 'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems: 'center'
    },
    fullScreenLoad:{
        height: '100vh',
        width:'100vw',
        display: 'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems: 'center'
    }
}))

// ======================
// SplashScreen
// ======================

interface SplashScreenProps{
}

export const SplashScreen: React.FC<SplashScreenProps> = observer((props)=>{
    const styles = useLoadingStyles(props);
    return <Box className={styles.splash}>
        <CircularProgress/>
    </Box>
})

// ======================
// LoadingScreen
// ======================

interface LoadingScreenProps{
}

export const LoadingScreen: React.FC<LoadingScreenProps> = observer((props)=>{

    const styles = useLoadingStyles(props);
    return <Box className={styles.load}>
        <CircularProgress/>
        <Box pt={3}>
            <Typography variant={'h5'}>Loading...</Typography>
        </Box>
    </Box>
})

// ======================
// FullScreenLoading
// ======================

interface FullScreenLoadingProps{
}

export const FullScreenLoading: React.FC<FullScreenLoadingProps> = observer((props)=>{
    const styles = useLoadingStyles(props);
    return <Box className={styles.fullScreenLoad}>
        <CircularProgress/>
        <Box pt={3}>
            <Typography variant={'h5'}>Loading...</Typography>
        </Box>
    </Box>
})