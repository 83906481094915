/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: AuthService
 */
import {computed, observable} from 'mobx';
import {APP_ROUTES} from '../app/AppRoutes';
import {History} from 'history';
import {ServerAPI} from '../core/ServerAPI';
import {isDevelopment} from '../../common/utils/util';

import {AppGlobalService, sleepMS} from "../app/AppGlobalService";
import { ServerData } from '../core/data/ServerData';
import {USER_TYPE} from "./AuthenticationTypes";
import {PopoverState} from "../../common/popover/PopoverService";
import {useLocation} from "react-router";

const LOCAL_STORAGE_API_KEY = '_auth-key'
const REDIRECT_PAGE = "_redirect-after-login"

export class AuthService {
    private api: ServerAPI;
    @observable private history: History;
    @observable authenticationResult: any = null;

    @observable currentUser = new ServerData<USER_TYPE>();
    @observable cdsUser: boolean = true;

    @observable userPopoverOpen: boolean = false;
    @observable userPopoverAnchor: any = null;

    @observable userPopover = new PopoverState('userPopover')

    @observable mobileMenuPopover = new PopoverState('menuPopover');

    @observable redirectPageAfterLogin: string = null;

    constructor(api: ServerAPI) {
        this.api = api;
        this.api.interceptError(401, async err => {
            console.log(err)
            await this.logout();
        });
    }

    @computed get authenticatedUser() {
        return this.authenticationResult ? this.authenticationResult.payload : null;
    }

    @computed get authenticated() {
        return !!this.authenticationResult;
    }

    async fetchUserInfo(){
        return await this.currentUser.loadDataFromAPI(this.api.GET('users/me'))
    }


    async redirectIfLoggedIn(currentPage: string) {
        console.log(`Redirecting. Authenticated: ${this.authenticated}`);
        this.history.replace(this.getRedirectRoute(currentPage));
    }

    async redirectOnLogIn(){
        const redirectPage = window.localStorage[REDIRECT_PAGE] ? window.localStorage[REDIRECT_PAGE] : APP_ROUTES.MIGRATION;
        console.log(`Redirecting to ${redirectPage}. Authenticated: ${this.authenticated}`);
        this.history.replace(redirectPage);
    }

    async redirectToBSILogin(){
        console.log('Redirecting to BSI Portal.');
        const callbackString = isDevelopment() ? btoa('http://localhost:3000/auth-callback') : btoa('https://ps.cdsi.us.com/auth-callback')
        const redirectPage = `https://bsi.cdsi.us.com/BSI-WEB/#/login?bsi-app-redirect=${callbackString}`
        window.location.href = redirectPage;
    }

    async authenticateWithToken(url: string){
        const key = 'bsi-auth-token'
        const urlParams = new URLSearchParams(url);
        const apiToken = atob(urlParams.get(key))
        window.localStorage.setItem(LOCAL_STORAGE_API_KEY, apiToken);
        this.api.setAPIToken(apiToken);
        this.authenticationResult=true;
        this.redirectOnLogIn()
    }

    getRedirectRoute(currentPage: string) {
        window.localStorage.setItem(REDIRECT_PAGE, currentPage)
        if (this.authenticated) {
            return currentPage;
        } else {
            return  APP_ROUTES.LOGIN;
        }
    }


    async logout(){
        this.authenticationResult=false;
        this.api.setAPIToken(null);
        window.localStorage.removeItem(LOCAL_STORAGE_API_KEY);
        this.redirectToBSILogin()
    }

    async backToBSI(){
        const bsiURL = 'https://bsi.cdsi.us.com/'
        window.location.href= bsiURL;
    }

    async initAuthentication(history: History, currentPage: string) {
        this.history = history;
        console.log('history set');
        const existingToken = window.localStorage.getItem(LOCAL_STORAGE_API_KEY);
        if (existingToken) {
            this.api.setAPIToken(existingToken);
            this.authenticationResult=true;
            this.redirectIfLoggedIn(currentPage)
        }
    }
}