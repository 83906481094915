/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: DialogService
 */
import {observable} from 'mobx';
import {v4 as uuid} from 'uuid';
import * as React from 'react';

export class DialogService {
    @observable alertDialogs   = observable.array<AlertDialogConfig>([]);
    @observable confirmDialogs = observable.array<ConfirmDialogConfig>([]);

    async addConfirmDialog(message: string = null, renderBody ?: () => React.ReactNode, autoConfirmationQuestionLine = true) {
        const id = uuid();

        let config = null;
        const confirmed  = await new Promise(resolve => {
            config = {
                id, message, renderBody, autoConfirmationQuestionLine,
                onClose: (confirmed: boolean) => resolve(confirmed),
            };
            this.confirmDialogs.push(config);
        });
        this.confirmDialogs.remove(this.confirmDialogs.find(a => a.id === id));
        return confirmed;
    }

    async addAlertDialog(title: string, message: string = null) {
        const id = uuid();

        let config = null;
        const ret  = await new Promise(resolve => {
            config = {
                id, message, title,
                onClose: (a: any) => resolve(a),
            };
            this.alertDialogs.push(config);
        });
        this.alertDialogs.remove(this.alertDialogs.find(a => a.id === id));
        return ret;
    }

    async addErrorAlertDialog(e: Error, waitTillClose = true) {
        const promise = this.addAlertDialog('Error', e.toString());
        if (waitTillClose)
        {
            return await promise;
        }

    }

    async catchAndAlertError<T>(promise: Promise<T>, waitTillClose = false): Promise<T> {
        try
        {
            return await promise;
        }
        catch (e)
        {
            await this.addErrorAlertDialog(e, waitTillClose);
            throw e;
        }
    }

}

export interface AlertDialogConfig {
    id: string;
    title: string;
    message: string;
    onClose?: (ret: any) => void;
}

export interface ConfirmDialogConfig {
    id: string;
    message?: string
    renderBody?: () => React.ReactNode,
    autoConfirmationQuestionLine: boolean,
    onClose?: (confirmed: boolean) => void;
}