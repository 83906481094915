import {computed, observable} from 'mobx';
import {AxiosResponse} from 'axios';

export class ServerData<T = any> {

    @observable dataLoaded            = false; // will set to true after the first time the data is loaded
    @observable data: T               = null;
    @observable error: Error          = null;
    @observable lastResetTime: number = null;

    @computed get loading() {
        return !this.dataLoaded;
    }

    @computed get ready() {
        return !this.loading && !this.inError;
    }

    @computed get inError() {
        return !!this.error;
    }

    resetData() {
        this.lastResetTime = new Date().getTime();
        this.data          = null;
        this.dataLoaded    = false;

    }

    setData(data: T) {
        this.data       = data;
        // mark data loaded
        this.dataLoaded = true;
        return this;
    }

    errorEncountered(err: Error) {
        if (this.error !== err)
        {
            this.error = err || null;
        }
    }

    async loadDataFromAPI(promise: Promise<T>) {
        // async loadDataFromAPI(promise: Promise<Class|AxiosResponse<Class>>) {
        if (promise)
        {
            const callTime = new Date().getTime();
            try
            {
                const res = await promise;

                // when data is returned, if the call time was before the reset time, don't set the data
                if (this.lastResetTime && callTime < this.lastResetTime)
                {
                    console.debug(`Will not set data to ServerData. Last Reset Time: ${this.lastResetTime}, Call Time: ${callTime}. Diff: ${this.lastResetTime -
                                                                                                                                            callTime}`);
                    return null;
                }

                let data = res ? res : null;
                // let data = isAxiosResponse(res) ? res.data : res;
                this.setData(data);
                this.errorEncountered(null);
                return res;
            }
            catch (err)
            {
                if (err)
                {
                    console.error('Error Encountered: ', err);
                    this.errorEncountered(err);
                    this.setData(null);
                }
            }
        }
        else
        {
            return promise;
        }
    }

}

const isAxiosResponse = (p: any): p is AxiosResponse => p.hasOwnProperty('status') && p.hasOwnProperty('data') && p.hasOwnProperty('statusText');
