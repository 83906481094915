import { observable } from "mobx";
import {ServerData} from "../data/ServerData";

export class AutocompleteState<T> {

    @observable data = new ServerData<T>();
    @observable open = false;
    @observable fetchDataFunction: ()=>Promise<any>;
    @observable inputValue: string = '';
    @observable selectedValue: string = '';

    id: string;

    @observable selectedValues: Array<any> = []

    constructor(id: string, fetchDataFunction?:  ()=>Promise<any>){
        this.fetchDataFunction = fetchDataFunction;
        this.id = id;
    }

    resetValue(){
        this.inputValue = '';
        this.selectedValue='';
    }




}