import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import { SomeForm } from '../randomScreen/FormikExample';
import Box from '@material-ui/core/Box';
import {useAppServices} from "../app/AppStates";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    Theme,
    useTheme,
    createStyles,
    Card,
    Grid,
    DialogContent,
    DialogActions,
    Button,
    DialogTitle,
    SvgIcon,
    DialogContentText, InputAdornment, Divider, CircularProgress
} from "@material-ui/core";
import {pink} from "@material-ui/core/colors";
import * as yup from "yup";
import {Form, Formik} from "formik";
import {FormTextField} from "../../common/form/FormComponents";
import {FaKey, FaUserCircle} from "react-icons/all";

// ======================
// LoginView
// ======================




const useLoginStyles = makeStyles((theme: Theme) => (
        {
            root: {
                background: 'linear-gradient(0deg, #c5cae9 10%, #1e88e5 90%)',
                height: '100vh',
                width:'100vw',
                display: 'flex'
            },

            card: {
                boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)'
            },

            login: {
                textAlign:'center'
            },
            center: {
                display: 'flex',
                justifyContent: 'center'
            }
        }
)
)


interface LoginViewProps{
}

export const LoginView: React.FC<LoginViewProps> = observer((props)=>{
    const { authService } = useAppServices();
    const styles = useLoginStyles(props);

    useEffect(() => {
            authService.redirectToBSILogin()
        }
    )

    return (<Box className={styles.root}>
        <Grid container justify={'center'} alignItems={'center'} direction={'row'}>
            <CircularProgress/>
            {/*<Grid item xs={8} sm={6} md={4} lg={3}>
                    <Card className={styles.card}>
                        <Box p={4}>
                        Redirecting...
                        </Box>
                    </Card>

            </Grid>*/}
        </Grid>
        </Box>

    )
})

// ======================
// AuthCallbackView
// ======================

interface AuthCallbackViewProps{
}

export const AuthCallbackView: React.FC<AuthCallbackViewProps> = observer((props)=>{
    const { authService, appGlobalService } = useAppServices();
    const styles = useLoginStyles(props);

    useEffect(() => {
            authService.authenticateWithToken(window.location.search)
        }
    )

    return (<Box className={styles.root}>
            <Grid container justify={'center'} alignItems={'center'} direction={'row'}>
                <CircularProgress/>
            </Grid>
        </Box>

    )
})




// ======================
// LoginForm
// ======================

interface LoginFormProps{
}

export const LoginForm: React.FC<LoginFormProps> = observer((props)=>{
    const { appGlobalService, authService } = useAppServices();

    const styles=useLoginStyles(props);
    const initialValues = {
        username       : '',
        password       : '',
    };


    const schema = yup.object({
        username        : yup.string().required('Enter username.').label('Username'),
        password        : yup.string().required('Enter password.').label('Password'),

    });

    return (
        <Formik initialValues={initialValues} validationSchema={schema} onSubmit={async (values, actions) => {
            // submit function here
            console.log(values.username);
        }}>
            {props => {
                return <>
                    <DialogTitle>CDS PS Portal</DialogTitle>
                    <Divider/>
                    <DialogContent>
                        <DialogContentText>
                            <Box pb={1} pt={1}>
                                Enter your BSI credentials.
                            </Box>
                        </DialogContentText>
                        <Form>
                            <Box pb={3}>
                                <FormTextField name={'username'} label={'Username'} icon={<FaUserCircle/>}/>
                            </Box>
                            <Box pb={3}>
                                <FormTextField type={'password'} name={'password'} label={'Password'} icon={<FaKey/>}/>
                            </Box>
                        </Form>
                    </DialogContent>
                    <DialogActions className={styles.center}>
                        <Box pb={3} >
                            <Button type={'submit'} variant={'contained'} onClick={e => props.submitForm()} color={'primary'} disableElevation>log in</Button>
                        </Box>
                    </DialogActions>
                </>;
            }}
        </Formik>
    )

})