// ======================
// ProjectStage
// ======================

import {MIGRATION_PROJECT_PHASE, PHASE_VALUE_TYPE, PHASE_VALUES_TYPE} from "../MigrationProjectTypes";
import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {useIsDesktop} from "../../layout/MainLayout";
import {Box, Button, Card, Divider, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {MigrationProjectDetailsState} from "./MigrationProjectDetailsState";
import {IoIosSave, IoMdCheckmark, MdEdit} from "react-icons/all";
import Slider from "@material-ui/core/Slider";
//@ts-ignore
//@ts-ignore

import {useMigrationProjectDetailsStyles} from "./MigrationProjectStyles";
import {DialogService} from "../../core/dialog/DialogService";

// ======================
// PsOnlyProjectChecklist
// ======================

interface PsOnlyProjectChecklistProps{
    detailsState: MigrationProjectDetailsState

}

export const PsOnlyProjectChecklist: React.FC<PsOnlyProjectChecklistProps> = observer((p)=>{
    const styles = useMigrationProjectDetailsStyles(p);
    const {detailsState} = p;
    let currentValues = detailsState.phaseValues;

    const initialValues={
        currentValue: 0,
        totalValue: 10,
        unit: 'Hours'
    };

    return <>
        <Box>
            <Box className={styles.projectChecklistTitle} pb={3} pt={4}>
                <Typography variant={'h4'} className={styles.title}>Project Checklist </Typography>
            </Box>
            <Box pb={3}>
                <Card>
                    <ProgressSlider name={'PS Delivery'} phase={MIGRATION_PROJECT_PHASE.KICKOFF}
                                    phaseValue={currentValues ? currentValues.kickOff : initialValues}
                                    setSliderValue={setSliderValue(MIGRATION_PROJECT_PHASE.KICKOFF, currentValues, detailsState)}
                                    editPhase={() => detailsState.editPhase(MIGRATION_PROJECT_PHASE.KICKOFF)}
                                    savePhase={detailsState.savePhase(MIGRATION_PROJECT_PHASE.KICKOFF)}
                                    phaseEditState={detailsState.phaseEditState}
                                    valueError={detailsState.getValueError()}
                                    unitError={detailsState.getUnitError()}
                                    totalError={detailsState.getTotalError()}
                                    phaseBeingEdited={detailsState.phaseBeingEdited}

                    />
                </Card>
            </Box>
        </Box>
        </>
})


// ======================
// ProjectChecklist
// ======================

interface ProjectChecklistProps {
    detailsState: MigrationProjectDetailsState
}

export const ProjectChecklist: React.FC<ProjectChecklistProps> = observer((props) => {
    const styles = useMigrationProjectDetailsStyles(props);
    const {detailsState} = props;

    let currentValues = detailsState.phaseValues;

    const initialValues={
        currentValue: 0,
        totalValue: 100,
        unit: '%'
    }

    return (<>
            <Box>
                <Box className={styles.projectChecklistTitle} pb={3} pt={4}>
                    <Typography variant={'h4'} className={styles.title}>Project Checklist </Typography>
                </Box>
                <Box pb={3}>
                    <Card>
                        <ProgressSlider name={'Kick-Off'} phase={MIGRATION_PROJECT_PHASE.KICKOFF}
                                        phaseValue={currentValues ? currentValues.kickOff : initialValues}
                                        setSliderValue={setSliderValue(MIGRATION_PROJECT_PHASE.KICKOFF, currentValues, detailsState)}
                                        editPhase={() => detailsState.editPhase(MIGRATION_PROJECT_PHASE.KICKOFF)}
                                        savePhase={detailsState.savePhase(MIGRATION_PROJECT_PHASE.KICKOFF)}
                                        phaseEditState={detailsState.phaseEditState}
                                        valueError={detailsState.getValueError()}
                                        unitError={detailsState.getUnitError()}
                                        totalError={detailsState.getTotalError()}
                                        phaseBeingEdited={detailsState.phaseBeingEdited}

                        />
                    </Card>
                </Box>
                <Box pb={3}>
                    <Card>
                        <ProgressSlider name={'Configuration'} phase={MIGRATION_PROJECT_PHASE.CONFIG}
                                        phaseValue={currentValues ? currentValues.config : initialValues}
                                        setSliderValue={setSliderValue(MIGRATION_PROJECT_PHASE.CONFIG, currentValues, detailsState)}
                                        editPhase={() => detailsState.editPhase(MIGRATION_PROJECT_PHASE.CONFIG)}
                                        savePhase={detailsState.savePhase(MIGRATION_PROJECT_PHASE.CONFIG)}
                                        phaseEditState={detailsState.phaseEditState}
                                        valueError={detailsState.getValueError()}
                                        unitError={detailsState.getUnitError()}
                                        totalError={detailsState.getTotalError()}
                                        phaseBeingEdited={detailsState.phaseBeingEdited}


                        />
                    </Card>
                </Box>
                <Box pb={3}>
                    <Card>
                        <ProgressSlider name={'Migration'} phase={MIGRATION_PROJECT_PHASE.MIGRATION}
                                        phaseValue={currentValues ? currentValues.migration : initialValues}
                                        setSliderValue={setSliderValue(MIGRATION_PROJECT_PHASE.MIGRATION, currentValues, detailsState)}
                                        editPhase={() => detailsState.editPhase(MIGRATION_PROJECT_PHASE.MIGRATION)}
                                        savePhase={detailsState.savePhase(MIGRATION_PROJECT_PHASE.MIGRATION)}
                                        phaseEditState={detailsState.phaseEditState}
                                        valueError={detailsState.getValueError()}
                                        unitError={detailsState.getUnitError()}
                                        totalError={detailsState.getTotalError()}
                                        phaseBeingEdited={detailsState.phaseBeingEdited}

                        />
                    </Card>
                </Box>
                <Box pb={3}>
                    <Card>
                        <ProgressSlider name={'Cutover'} phase={MIGRATION_PROJECT_PHASE.CUTOVER}
                                        phaseValue={currentValues ? currentValues.cutover : initialValues}
                                        setSliderValue={setSliderValue(MIGRATION_PROJECT_PHASE.CUTOVER, currentValues, detailsState)}
                                        editPhase={() => detailsState.editPhase(MIGRATION_PROJECT_PHASE.CUTOVER)}
                                        savePhase={detailsState.savePhase(MIGRATION_PROJECT_PHASE.CUTOVER)}
                                        phaseEditState={detailsState.phaseEditState}
                                        valueError={detailsState.getValueError()}
                                        unitError={detailsState.getUnitError()}
                                        totalError={detailsState.getTotalError()}
                                        phaseBeingEdited={detailsState.phaseBeingEdited}

                        />
                    </Card>
                </Box>

            </Box>

        </>
    )
})

// ======================
// ProgressSlider
// ======================

interface ProgressSliderProps {
    name: string,
    phase: MIGRATION_PROJECT_PHASE,
    phaseValue: PHASE_VALUE_TYPE,
    setSliderValue: (value: number) => any;
    editPhase: () => void;
    savePhase: (values: PHASE_VALUES_TYPE) => void;
    phaseEditState: PHASE_VALUES_TYPE;
    phaseBeingEdited: string;
    valueError: boolean;
    totalError: boolean;
    unitError: boolean;

}

export const ProgressSlider: React.FC<ProgressSliderProps> = observer((props) => {
    const styles = useMigrationProjectDetailsStyles(props);
    const { phaseValue, name, phase, editPhase, phaseEditState, savePhase, setSliderValue, valueError, unitError, totalError, phaseBeingEdited} = props;

    const isDesktop = useIsDesktop();

    const [ sliderValue, setSliderDisplayValue ] = useState(phaseValue.currentValue)

    const setDisplayValue = (e: object, value: any) =>{
        setSliderDisplayValue(value)
    }

    const setValue = (e: object, value: any) =>{
        setSliderValue(value).then((value:boolean)=>{
            if (value===false){
                setSliderDisplayValue(phaseValue.currentValue)
            }
        })
    }

    const setInputValue = async (e: any) => {
        phaseEditState[phase].currentValue = e.target.value
    }

    const setInputTotalValue = async (e: any) => {
        phaseEditState[phase].totalValue = e.target.value
    }

    const setUnitValue = async (e: any) => {
        phaseEditState[phase].unit = e.target.value
    }

    const setMarks = () => {
        let marks = [
            {
                value:0,
                label: 0
            },
            {
                value: phaseValue.totalValue,
                label: phaseValue.totalValue
            }
        ];
        return marks;
    }

    const disableSave = () =>{
        if (valueError || totalError || unitError){
            return true;
        } else{
            return false;
        }
    }

    const setComplete = (e: React.MouseEvent)=>{
        setSliderValue(phaseValue.totalValue).then((value:boolean)=>{
            if (value===true){
                setSliderDisplayValue(phaseValue.totalValue)
            }
        })
    }

    return <>
        <Box className={styles.phaseTitle} pb={2}>
            <Typography
                variant={'h5'}>{name}: {Math.ceil((phaseValue.currentValue / phaseValue.totalValue) * 100)}%</Typography>
            <Button size={isDesktop ? 'medium' : 'small'}
                    startIcon={<IoMdCheckmark/>} variant={'contained'}
                    color={'primary'}
                    onClick={setComplete}
                    disableElevation
                    disabled={phaseValue.currentValue === phaseValue.totalValue}
            >
                Complete
            </Button>


        </Box>
        <Divider/>
        <Box className={styles.progressSlider} pt={4} pb={4} pl={2} pr={2}>
            <Box width={400} className={styles.progressInput}>
                {!!phaseEditState && phaseBeingEdited === phase &&
                <Box className={styles.progressInput}>
                    <Button size={isDesktop ? 'medium' : 'small'}
                            startIcon={<IoIosSave/>} variant={'outlined'}
                            color={'primary'}
                            onClick={() => savePhase(phaseEditState)}
                            disableElevation
                            className={styles.progressMeterButtons}
                            disabled={disableSave()}
                    >
                        Save</Button>
                    <TextField margin={'none'}
                               className={styles.numberField}
                               onChange={setInputValue}
                               value={phaseEditState[phase].currentValue}
                               error={valueError}
                               label={'Value'}
                               variant={'outlined'}
                               inputProps={{'aria-label':'Value'}}

                    />
                    <Typography variant={'h6'}>/ </Typography>
                    <TextField margin={'none'}
                               className={styles.numberField}
                               onChange={setInputTotalValue}
                               value={phaseEditState[phase].totalValue}
                               error={totalError}
                               label={'Total'}
                               variant={'outlined'}
                               inputProps={{'aria-label':'Total'}}

                    />
                    <TextField margin={'none'}
                               className={styles.textField}
                               onChange={setUnitValue}
                               value={phaseEditState[phase].unit}
                               error={unitError}
                               label={'Unit'}
                               variant={'outlined'}
                               inputProps={{'aria-label':'Unit'}}
                    />

                </Box>
                }
                {phaseBeingEdited!==phase &&
                <Box width={400} className={styles.progressInput}>
                    <Button size={isDesktop ? 'medium' : 'small'}
                            startIcon={<MdEdit/>} variant={'outlined'}
                            color={'primary'}
                            onClick={() => editPhase()}
                            disableElevation
                            className={styles.progressMeterButtons}
                    >
                        Edit</Button>
                    <Typography
                        variant={'h6'}>{phaseValue.currentValue}/{phaseValue.totalValue} {phaseValue.unit} </Typography>

                </Box>
                }
            </Box>
            <Box pl={2} pr={2} width={isDesktop ? 800 : 1000} pt={3}>
                <Slider
                    defaultValue={0}
                    onChangeCommitted={setValue}
                    onChange={setDisplayValue}
                    step={1}
                    min={0}
                    max={phaseValue.totalValue}
                    value={sliderValue}
                    marks={setMarks()}
                    disabled={!!phaseEditState}
                    valueLabelDisplay={'auto'}
                    aria-label={name}
                />
            </Box>

        </Box>
    </>
})

const getValueOutOf25 = (phase: PHASE_VALUE_TYPE) =>{
    return (phase.currentValue/phase.totalValue)*25
}

const getPercentage = (phase: PHASE_VALUE_TYPE) => {
    return (phase.currentValue/phase.totalValue)*100
}

const setSliderValue = (phase:MIGRATION_PROJECT_PHASE, currentValues: PHASE_VALUES_TYPE, detailsState: MigrationProjectDetailsState) => async(value: number) => {

    if (!currentValues){
        currentValues = detailsState.getInitialProgress();
    }

    let message = `Are you sure you want to change the progress to ${value}/${currentValues[phase].totalValue} ${currentValues[phase].unit}?`
    if (value ===currentValues[phase].totalValue){
        message = `Are you sure you want to change the progress to ${value}${currentValues[phase].unit}? This will mark this phase as complete. If all phases are marked complete, this action cannot be undone.`
    }

    const confirmed = await detailsState.dialogService.addConfirmDialog(message, null, false)
    if (confirmed){
        currentValues[phase].currentValue = value;
        await detailsState.setPhaseValues(currentValues)
    }
    return confirmed;

}