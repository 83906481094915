import React from "react";
import {observer} from "mobx-react-lite";
import {useAppServices} from "../app/AppStates";
import * as yup from "yup";
import {Formik} from "formik";
import {Box, Button, Dialog, Divider, makeStyles, MenuItem, Theme, Typography} from "@material-ui/core";
import {SlideUpTransition} from "../../common/transitions/Transitions";
import {
    AutocompleteFormField,
    FormCheckboxItem,
    FormDatePicker,
    FormSelect,
    FormTextField
} from "../../common/form/FormComponents";
import {
    CONTACT_FORM_LABEL_TYPE,
    CONTACT_TYPE,
    EMPLOYEE_FORM_LABEL_TYPE,
    EMPLOYEE_TYPE,
    MigrationProjectDealType,
    NEW_PROJECT_FORM_INITIAL_VALUES_TYPE,
    NEW_PROJECT_FORM_VALUES_TYPE,
} from "./MigrationProjectTypes";

import {format} from "date-fns";
import xbytes from "xbytes";
import {BackButton} from "../../common/buttons/commonButtons";
import {APP_ROUTES} from "../app/AppRoutes";
import {useHistory} from "react-router-dom";
import {useIsDesktop} from "../layout/MainLayout";
import {Simulate} from "react-dom/test-utils";

// ======================
// NewMigrationProjectDialog
// ======================

export const useDialogStyles = makeStyles((t: Theme) => ({
    dialog: {
        padding: t.spacing(4),
    },
    chip: {
        marginLeft: t.spacing(1),
    },
    fields: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    fieldGroup: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    },
    submitButton: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}))

// ======================
// MigrationProjectDialogContent
// ======================


interface MigrationProjectDialogContentProps {
}

export const MigrationProjectDialogContent: React.FC<MigrationProjectDialogContentProps> = observer((props) => {
    const {migrationProjectService} = useAppServices();

    const isDesktop = useIsDesktop();

    const initialValues: NEW_PROJECT_FORM_INITIAL_VALUES_TYPE = {
        migrationSource: '',
        migrationDestination: '',
        dataAmount: 0,
        dataUnit: 'TB',
        salesForceId: '',
        projectName: '',
        projectDescription: '',
        projectTimeFrameFrom: new Date(),
        projectTimeFrameTo: new Date(),
        projectStartDate: null,
        projectEndDate: null,
        projectEmployees: [] as Array<EMPLOYEE_FORM_LABEL_TYPE>,
        projectContacts: [] as Array<CONTACT_FORM_LABEL_TYPE>,
        dealType: '',
        psOnly: false
    };

    return (
        <Box pr={isDesktop ? 10 : 0} pl={isDesktop ? 10 : 0} pb={6}>
            <BackButton backPageName={'All Projects'} backPagePath={APP_ROUTES.MIGRATION}/>
            <Typography variant={'h4'}>Add New Project</Typography>
            <MigrationProjectForm initialValues={initialValues}
                                  submitFunction={(values: NEW_PROJECT_FORM_VALUES_TYPE) => migrationProjectService.addNewProject(values)}
                                  postSubmitRoute={APP_ROUTES.MIGRATION} isProposal={true}
            />
        </Box>
    )
})

// ======================
// MigrationProjectForm
// ======================

interface MigrationProjectFormProps {
    initialValues: NEW_PROJECT_FORM_INITIAL_VALUES_TYPE,
    submitFunction: (values: NEW_PROJECT_FORM_VALUES_TYPE, successMessage?: boolean) => Promise<any>,
    postSubmitRoute?: string
    submitButtonText?: string
    isProposal?: boolean
    approvalFunction?: (projectId: string) => Promise<any>;
}

export const MigrationProjectForm: React.FC<MigrationProjectFormProps> = observer((props) => {

    const history = useHistory();

    const {initialValues, submitFunction, postSubmitRoute, submitButtonText, isProposal, approvalFunction} = props;
    const styles = useDialogStyles(props);
    const {migrationProjectService} = useAppServices();

    const isDesktop = useIsDesktop();

    const mmChannelSchema = yup.string().nullable().url().test(
        'includes-ps',
        'Enter a valid channel URL (includes "/ps/channels/).',
        (value) => value ? value.toString().includes('/ps/channels/') : true,
    );

    const schema = yup.object({
        projectName: yup.string().required('Enter project name.').label('Name'),
        projectDescription: yup.string().label('Description'),
        projectTimeFrameFrom: yup.date().required('Enter estimated start date.').label('Start Date').typeError('Enter a valid date.'),
        projectTimeFrameTo: yup.date().required('Enter estimated end date.').label('End Date').typeError('Enter a valid date.'),
        dataAmount: yup.number().required('Enter data amount.').label('Data Amount'),
        dataUnit: yup.string().required('Choose data unit.').label('Unit'),
        projectEmployees: yup.array().label('CDS Representatives'),
        projectContacts: yup.array().label('Partner Representatives'),
        migrationSource: yup.string().required('Enter migration source.').label('Source'),
        migrationDestination: yup.string().required('Enter migration destination.').label('Destination'),
        salesForceId: yup.string().nullable().label('Salesforce ID'),
        dealType: yup.string().required('Choose a deal type.').label('Deal Type'),
        projectStartDate: yup.date().nullable(),
        projectEndDate: yup.date().nullable(),
        mmChannel: mmChannelSchema,
        psOnly: yup.bool().default(false),
        onHold: yup.bool().default(false),
    });

    const dealTypes = [
        'General PS / Support', 'Pure Storage Bundle', 'Other']

    return (<Box width={'100%'} pb={6}>


            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={async (values, actions) => {
                const projectTimeFrameFrom = format(values.projectTimeFrameFrom, 'MM/dd/yyyy');
                const projectTimeFrameTo = format(values.projectTimeFrameTo, 'MM/dd/yyyy');

                let projectStartDate = undefined;
                let projectEndDate = undefined;
                if (values.projectStartDate) {
                    projectStartDate = format(values.projectStartDate, 'MM/dd/yyyy');
                }
                if (values.projectEndDate) {
                    projectEndDate = format(values.projectEndDate, 'MM/dd/yyyy');
                }
                const migrationDataAmount = xbytes.parseSize(`${values.dataAmount} ${values.dataUnit}`)
                const projectEmployees = values.projectEmployees.map((value) => value.id)
                const projectContacts = values.projectContacts.map(value => value.id)

                let dealType: string;
                switch (values.dealType) {
                    case 'General PS / Support':
                        dealType = MigrationProjectDealType.GENERAL_PS;
                        break;
                    case 'Other':
                        dealType = MigrationProjectDealType.OTHER;
                        break;
                    case 'Pure Storage Bundle':
                        dealType = MigrationProjectDealType.PURE_BUNDLE;
                        break;
                    default:
                        dealType = 'Other';
                        break;
                }

                let mmChannel = null;
                if (values.mmChannel) {
                    mmChannel = values.mmChannel.split('channels/')[1];
                }

                const formattedValues: NEW_PROJECT_FORM_VALUES_TYPE = {
                    migrationSource: values.migrationSource,
                    migrationDestination: values.migrationDestination,
                    migrationDataAmount: migrationDataAmount,
                    salesForceId: values.salesForceId,
                    projectName: values.projectName,
                    projectDescription: values.projectDescription,
                    projectTimeFrameFrom: projectTimeFrameFrom,
                    projectTimeFrameTo: projectTimeFrameTo,
                    projectStartDate: projectStartDate,
                    projectEndDate: projectEndDate,
                    projectEmployees: projectEmployees,
                    projectContacts: projectContacts,
                    dealType: dealType,
                    mmChannel: mmChannel,
                    psOnly: !!values.psOnly,
                    onHold: !!values.onHold,
                }

                //console.log(formattedValues)
                await submitFunction(formattedValues)

                actions.setSubmitting(false)
                if (!!postSubmitRoute) {
                    history.push(postSubmitRoute)
                }

            }}>
                {props => {
                    return <Box width={'100%'}>
                        <Box pt={3} display={'flex'} flexWrap={'wrap'} justifyContent={'space-between'}>
                            <Box pb={3} width={isDesktop ? 300 : '100%'}>
                                <FormTextField label={'Name'} name={'projectName'} required/>
                            </Box>
                            <Box pb={3} width={isDesktop ? 300 : '100%'}>
                                <FormTextField label={'Salesforce ID'} name={'salesForceId'}/>
                            </Box>
                        </Box>
                        <Box pb={3} width={isDesktop ? 300 : '100%'}>
                            <FormSelect label={isProposal ? 'Deal Type *' : 'Deal Type'} name={'dealType'}
                                        selectionList={dealTypes} required={isProposal}/>
                        </Box>
                        <Box pb={6}>
                            <FormTextField label={'Description'} name={'projectDescription'} multiline rows={3}
                                           variant={'outlined'}/>
                        </Box>
                        <Box display={'flex'} flexWrap={'wrap'} justifyContent={'flex-start'} pb={3} width={'100%'}>
                            <Box pb={3} pr={isDesktop ? 2 : 0} width={isDesktop ? 300 : '100%'}>
                                <FormDatePicker label={'Estimated Start Date*'} name={'projectTimeFrameFrom'}/>
                            </Box>
                            <Box pb={3} width={isDesktop ? 300 : '100%'}>
                                <FormDatePicker label={'Estimated End Date*'} name={'projectTimeFrameTo'}
                                                minDate={props.values.projectTimeFrameFrom}/>
                            </Box>
                        </Box>
                        <Box display={'flex'} flexWrap={'wrap'} justifyContent={'flex-start'} pb={3} width={'100%'}>
                            <Box pb={3} pr={isDesktop ? 2 : 0} width={isDesktop ? 300 : '100%'}>
                                <FormDatePicker label={'Actual Start Date'} name={'projectStartDate'}/>
                            </Box>
                            <Box pb={3} width={isDesktop ? 300 : '100%'}>
                                <FormDatePicker label={'Actual End Date'} name={'projectEndDate'}
                                                minDate={props.values.projectStartDate}/>
                            </Box>
                        </Box>
                        <Box display={'flex'} flexWrap={'wrap'}>
                            <Box pb={3} display={'flex'} flexWrap={'wrap'} justifyContent={'flex-start'} width={'100%'}>
                                <Box pb={3} pr={isDesktop ? 2 : 0} width={isDesktop ? 300 : '100%'}>
                                    <FormTextField label={'Source'} name={'migrationSource'} required={isProposal}/>
                                </Box>
                                <Box pb={3} width={isDesktop ? 300 : '100%'}>
                                    <FormTextField label={'Destination'} name={'migrationDestination'}
                                                   required={isProposal}/>
                                </Box>
                            </Box>
                            <Box display={'flex'} flexWrap={'wrap'} justifyContent={'flex-start'} width={'100%'}>
                                <Box pr={isDesktop ? 2 : 0} pb={3} width={isDesktop ? 300 : '100%'}>
                                    <FormTextField label={'Data Amount'} name={'dataAmount'} required={isProposal}/>
                                </Box>
                                <Box pb={2} width={isDesktop ? 300 : '100%'}>
                                    <FormSelect label={'Unit'} name={'dataUnit'}
                                                selectionList={['GB', 'GiB', 'TB', 'TiB', 'PB', 'PiB']}/>
                                </Box>
                            </Box>
                        </Box>
                        <Box display={'flex'} flexWrap={'wrap'} justifyContent={'flex-start'} width={'100%'} pb={4}>
                            <Box pb={3} pr={isDesktop ? 6 : 0} width={isDesktop ? 400 : '100%'}>
                                <AutocompleteFormField name={'projectEmployees'} multiple
                                                       autocompleteState={migrationProjectService.employeeAutocompleteState}
                                                       label={'CDS Representative'}
                                                       getOptionLabel={data => data.name}
                                                       getOptionFormat={(item: EMPLOYEE_TYPE) => {
                                                           return {
                                                               name: `${item.firstName} ${item.lastName}`,
                                                               id: item.employeeId
                                                           }
                                                       }}
                                />
                            </Box>
                            <Box width={isDesktop ? 400 : '100%'}>
                                <AutocompleteFormField name={'projectContacts'} multiple
                                                       autocompleteState={migrationProjectService.contactAutocompleteState}
                                                       label={'Partner Representative'}
                                                       renderOption={data => <Box width={'100%'}><Typography
                                                           variant={'subtitle2'}>{data.name}</Typography><Typography
                                                           variant={'body1'}>{data.account}</Typography><Typography
                                                           variant={'caption'}>{data.email}</Typography><Divider/></Box>}
                                                       getOptionLabel={data => data.name}
                                                       getOptionFormat={(item: CONTACT_TYPE) => {
                                                           return {
                                                               name: `${item.firstName} ${item.lastName}`,
                                                               id: item.contactId,
                                                               account: item.accountName,
                                                               email: item.email
                                                           }
                                                       }}/>
                            </Box>
                        </Box>
                        {initialValues.projectName &&
                            <Box>
                                <Box>
                                    <FormCheckboxItem label={'Project On-Hold'} name={'onHold'}/>
                                </Box>
                                <Box>
                                    <FormCheckboxItem label={'PS Only'} name={'psOnly'}/>
                                </Box>
                                <Box display={'flex'} pb={3}>
                                    <FormTextField label={'Mattermost Channel'} name={'mmChannel'}/>
                                </Box>
                            </Box>

                        }


                        <Box className={styles.submitButton}>
                            <Button color={'primary'} variant={'contained'} disableElevation
                                    type={'submit'}
                                    disabled={props.isSubmitting}
                                    onClick={async(e) => {
                                        e.preventDefault();
                                        await props.submitForm()
                                    }}><Box pl={3}
                                            pr={3}>{submitButtonText ? submitButtonText : 'Submit'}</Box></Button>
                        </Box>

                    </Box>
                }}
            </Formik>
        </Box>
    )
})
