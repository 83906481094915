/**
 * Created by sammy on 1/21/20.
 * Project: webapp-template. File: DataLoaderHooks
 */

import * as React from 'react';
import {Poller, startPolling, stopPolling} from '../../../common/poller/Poller';
import {useEffectOnce} from 'react-use';
import {ServerData} from './ServerData';
import {LoadingBox, LoadingListItems} from '../../../common/progress/LoadingIndicators';

export interface UseInitDataConfig<T = any> {
    init?: Function;
    deinit?: Function;
    poll?: () => Promise<T>;
    pollInterval?: number;
}

export const useInitData = <T extends {}>(config: UseInitDataConfig<T>) => {
    return useEffectOnce(() => {
        // init
        if (typeof config.init === 'function')
        {
            config.init();
        }

        let poller: Poller;
        if (typeof config.poll === 'function')
        {
            poller = startPolling(config.poll, config.pollInterval || 1);
        }

        return () => {
            // deinit
            stopPolling(poller);
            if (typeof config.deinit === 'function')
            {
                config.deinit();
            }
        };
    });
};


export type UseServerDataRenderFunction<T> = (data: T) => React.ReactElement;
export type UseServerDataConfig<T> = {
                                         data: ServerData<T>;
                                         resetServerDataOnExit?: boolean;
                                     } | ServerData<T> ;
export const useServerData = <T extends any>(config: UseServerDataConfig<T>,
                                             renderLoaderOrLoader: React.ReactElement | (() => React.ReactElement),
                                             renderReady: UseServerDataRenderFunction<T>): React.ReactElement => {
    const serverData            = config instanceof ServerData ? config : config.data;
    const resetServerDataOnExit = config instanceof ServerData ? false : config.resetServerDataOnExit;

    useEffectOnce(() => {
        return () => {
            if (resetServerDataOnExit)
            {
                serverData.resetData();
            }
        };
    });


    if (!serverData.ready)
    {
        return renderLoaderOrLoader && React.isValidElement(renderLoaderOrLoader) ? renderLoaderOrLoader :
               typeof renderLoaderOrLoader === 'function' ? renderLoaderOrLoader() : null;
    }
    else
    {
        return renderReady(serverData.data);
    }
};


export const useServerDataWithLoadingList = <T extends any>(config: UseServerDataConfig<T>,
                                                            renderReady: UseServerDataRenderFunction<T>) => {
    return useServerData(config, <LoadingListItems count={2}/>, renderReady);
};

export const useServerDataWithLoadingBox = <T extends any>(config: UseServerDataConfig<T>,
                                                           renderReady: UseServerDataRenderFunction<T>) => {
    return useServerData(config, <LoadingBox/>, renderReady);
};