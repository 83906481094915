import {observable} from "mobx";
import { TableState } from "../../common/table/TableState";
import {ServerAPI} from "../core/ServerAPI";
import {DialogService} from "../core/dialog/DialogService";
import {ProgressService} from "../core/progress/ProgressService";
import {REPORT_LIST_TYPE, SUBMIT_REPORT_TYPE} from "./report_types";
import {ServerData} from "../core/data/ServerData";
import {APP_ROUTES} from "../app/AppRoutes";
import axios from "axios";
import {MIGRATION_PROJECT_STATUS, MIGRATION_PROJECT_STATUS_TYPE} from "../migration/MigrationProjectTypes";


export class ReportsService {
    TABLE_PAGE_SIZE = 10;
    api: ServerAPI;
    dialogService: DialogService;
    progressService: ProgressService;
    apiPrefix = APP_ROUTES.REPORTS

    @observable reportsTableState = new TableState('reportsTable', this.TABLE_PAGE_SIZE);
    @observable reports = new ServerData<REPORT_LIST_TYPE>();

    constructor(api: ServerAPI, dialogService: DialogService, progressService: ProgressService){
        this.api = api;
        this.dialogService = dialogService;
        this.progressService = progressService;
    }


    async fetchReports(){
        return await this.reports.loadDataFromAPI(this.dialogService.catchAndAlertError(this.api.GET(`${this.apiPrefix}`, {
            params: {
                page: this.reportsTableState.page + 1,
                pageSize: this.TABLE_PAGE_SIZE
            }})))
    }

    async downloadFile(reportID: number, reportName: string){
        const response = await this.dialogService.catchAndAlertError(this.api.GET(`${this.apiPrefix}/report-files/${reportID}`))
        if (response){
            const url = response.downloadUrl;
            const file = await (axios.get(url, {responseType:'arraybuffer'}));
            if (file){
                const blob = new Blob([file.data], {type: 'text/csv'} )
                const downloadLink = URL.createObjectURL(blob);

                // Create an invisible A element
                const a = document.createElement("a");
                a.style.display = "none";
                document.body.appendChild(a);

                // Set the HREF to a Blob representation of the data to be downloaded
                a.href = downloadLink;

                // Use download attribute to set desired file name
                a.setAttribute("download", reportName);

                // Trigger the download by simulating click
                a.click();

                // Cleanup
                window.URL.revokeObjectURL(a.href);
                document.body.removeChild(a)
            }

        }
    }


    async generateProjectSummaryReport(engineer?: string, projectStatus?: Array<MIGRATION_PROJECT_STATUS_TYPE> ){
        await this.dialogService.catchAndAlertError(this.progressService.track(this.api.POST(`${this.apiPrefix}/migration-projects-summary-reports/`, {
            person: engineer || undefined,
            projectStatuses: projectStatus
    }
    ), "Generating report..."
        )
        )
        await this.fetchReports();
    }

    async deleteReport(reportID: number){
        return await this.dialogService.catchAndAlertError(this.api.DELETE(`${this.apiPrefix}/report-files/${reportID}`));
    }

}