import {observable} from "mobx";
import {ServerData} from "../../modules/core/data/ServerData";
import {EMPLOYEE_TYPE, PROJECT_LIST_TYPE} from "../../modules/migration/MigrationProjectTypes";
import {ServerAPI} from "../../modules/core/ServerAPI";
import {PopoverState} from "../popover/PopoverService";
import {AutocompleteState} from "../../modules/core/autocomplete/AutocompleteState";

export class TableState<FilterType>{

    tableId: string;
    filters: FilterType;
    autocompleteFields: Array<AutocompleteState<any>>;


    constructor(tableId: string, pageSize: number, filters?:FilterType, autocompleteFields?: Array<AutocompleteState<any>>){
        this.tableId = tableId;
        this.pageSize = pageSize;
        this.filters = filters;
        this.autocompleteFields = autocompleteFields;
    }

    @observable page: number = 0;
    @observable pageSize: number;
    @observable filterOn: boolean = false;
    @observable filterLabels: any = null;


    filterPopoverState = new PopoverState('filterPopover')

    changePage(page:number){
        this.page=page;
    }

    setFilterValues(values: FilterType){
        this.filters = values;
        for (let value in values){
            if (values[value] !== null){
                this.setFilterStatus(true)
                break;
            }
            if (typeof values[value]==='object'){
                if (values[value] && !Object.values(values[value]).every(value=>value===null)){
                    this.setFilterStatus(true)
                    break;
                } else {
                    this.setFilterStatus(false)
                }
            }
            if (values[value]===null){
                this.setFilterStatus(false)
            }

        }
        this.filterPopoverState.handleClose()
    }

    setFilterLabels(values: any){
        this.filterLabels = values;
    }

    setFilterStatus(value: boolean){
        this.filterOn = value;
    }

    resetFilters(){
        for (let value in this.filters){
            this.filters[value] = null
        }
        this.setFilterStatus(false)
        this.filterPopoverState.handleClose()
    }

    matchAutocomplete(id: string){
        return this.autocompleteFields.find(field=>field.id===id)
    }

}