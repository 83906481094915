// ======================
// StatusIcon
// ======================

import {Chip, ChipProps, Theme} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {amber, blue, deepOrange, grey, lightBlue, lightGreen, orange, pink, red} from '@material-ui/core/colors';
import React from 'react';
import {observer} from 'mobx-react-lite';
import {FaCheckCircle, FaRegCheckCircle, FiAlertCircle, FiLoader, FaRegHandPaper} from 'react-icons/all';
import {
    MIGRATION_PROJECT_STATUS,
    MIGRATION_PROJECT_STATUS_TYPE,
    MigrationProjectDealType,
} from './MigrationProjectTypes';
import Typography from '@material-ui/core/Typography';

export const useMigrationProjectListStyles = makeStyles((t: Theme) => ({
                                                            titleBar    : {
                                                                display   : 'flex',
                                                                alignItems: 'center',
                                                            },
                                                            title       : {
                                                                flexGrow: 1,
                                                            },
                                                            tabs        : {
                                                                display       : 'flex',
                                                                justifyContent: 'center',
                                                            },
                                                            dateChip    : {
                                                                backgroundColor: pink['100'],
                                                            },
                                                            link        : {
                                                                color: grey['900'],
                                                            },
                                                            centerTitle : {
                                                                display       : 'flex',
                                                                justifyContent: 'center',
                                                            },
                                                            buttonLink  : {
                                                                display       : 'flex',
                                                                flexWrap      : 'nowrap',
                                                                textDecoration: 'none',
                                                            },
                                                            mobileLink  : {
                                                                textDecoration: 'none',
                                                            },
                                                            cardTitleBar: {
                                                                display       : 'flex',
                                                                alignItems    : 'center',
                                                                justifyContent: 'space-between',
                                                                flexWrap      : 'wrap',
                                                            },

                                                            mobileCardProgressLine: {
                                                                display       : 'flex',
                                                                alignItems    : 'flex-end',
                                                                justifyContent: 'center',

                                                            },
                                                            overline              : {
                                                                lineHeight: 1.2,
                                                            },
                                                            path                  : {
                                                                display   : 'flex',
                                                                alignItems: 'center',
                                                            },
                                                            progressMeter         : {
                                                                textAlign: 'right',
                                                            },
                                                            questionnaireButton   : {
                                                                display       : 'flex',
                                                                justifyContent: 'center',
                                                            },
                                                            shortOverline         : {
                                                                lineHeight: 1,
                                                            },
                                                            button                : {
                                                                textAlign: 'left',
                                                            },
                                                            projectLink           : {
                                                                color      : '#000000',
                                                                '&:hover'  : {
                                                                    color              : blue['500'],
                                                                    textDecorationColor: blue['500'],
                                                                },
                                                                '&:active' : {
                                                                    color              : blue['500'],
                                                                    textDecorationColor: blue['500'],
                                                                },
                                                                '&:visited': {
                                                                    color: '#000000',
                                                                },
                                                            },

                                                        }),
);


interface StatusIconProps {
    status: string;
}

const getBoxBase = (t: Theme) => {
    return {
        color      : '#fff',
        fontWeight : 700,
        paddingLeft: t.spacing(1),
    };

};

const useStatusStyles = makeStyles((t: Theme) => ({

    boxBase: getBoxBase(t),

    icon    : {
        color: '#FFFFFF',
    },
    approved: {
        ...getBoxBase(t),
        backgroundColor: lightBlue['500'],

    },
    proposed: {
        ...getBoxBase(t),
        backgroundColor: red['300'],
    },

    inProgress: {
        ...getBoxBase(t),
        backgroundColor: amber['400'],
    },

    onHold: {
        ...getBoxBase(t),
        backgroundColor: deepOrange['400'],
    },

    completed: {
        ...getBoxBase(t),
        backgroundColor: lightGreen['500'],
    },

    pending: {
        ...getBoxBase(t),
        backgroundColor: grey['500'],
    },

}));

export const StatusIcon: React.FC<StatusIconProps & Partial<ChipProps>> = observer((props) => {
    const {status, ...p} = props;
    const styles         = useStatusStyles(props);

    switch (status)
    {
        case 'APPROVED':
            return <Chip {...p} label={renderStatusText(status)} icon={<FaRegCheckCircle className={styles.icon}/>} className={styles.approved}/>;
        case 'PROPOSED' :
            return <Chip {...p} label={renderStatusText(status)} icon={<FiAlertCircle className={styles.icon}/>} className={styles.proposed}/>;
        case 'IN_PROGRESS' :
            return <Chip {...p} label={renderStatusText(status)} icon={<FiLoader className={styles.icon}/>} className={styles.inProgress}/>;
        case 'ON_HOLD' :
            return <Chip {...p} label={renderStatusText(status)} icon={<FaRegHandPaper className={styles.icon}/>} className={styles.onHold}/>;
        case 'COMPLETED' :
            return <Chip {...p} label={renderStatusText(status)} icon={<FaCheckCircle className={styles.icon}/>} className={styles.completed}/>;
        default :
            return <Chip {...p} label={renderStatusText(status)} icon={<FiLoader className={styles.icon}/>} className={styles.pending}/>;

    }

});

export const renderStatusText = (status: string) => {
    if (status === MIGRATION_PROJECT_STATUS.IN_PROGRESS)
    {
        return 'IN PROGRESS';
    }
    if (status === MIGRATION_PROJECT_STATUS.ON_HOLD)
    {
        return 'ON HOLD';
    }
    return status;
};

export const renderDealType = (dealType: string, size?: 'inherit' | 'button' | 'overline' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'srOnly') => {
    switch (dealType)
    {
        case MigrationProjectDealType.GENERAL_PS:
            return <Typography variant={size ? size : 'body1'}>General PS / Support</Typography>;
        case MigrationProjectDealType.OTHER:
            return <Typography variant={size ? size : 'body1'}>Other</Typography>;
        case MigrationProjectDealType.PURE_BUNDLE:
            return <Typography variant={size ? size : 'body1'}>Pure Storage Bundle</Typography>;
        default:
            return <Typography variant={size ? size : 'body1'}>Other</Typography>;
    }
};

export const shortenDate = (date: string) => {
    if (date)
    {
        return `${date.split('-')[1]}/${date.split('-')[2]}/${date.split('-')[0].slice(2, 4)}`;
    }
    else
    {
        return 'not set';
    }
};

export const shortenName = (name: string, chars: number) => {
    if (name.length > chars)
    {
        return `${name.slice(0, chars)}...`;
    }
    else
    {
        return name;
    }
};