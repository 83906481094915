import React from "react";
import {observer} from "mobx-react-lite";
import {useHistory} from "react-router-dom";
import {Button, ButtonProps, SvgIcon} from "@material-ui/core";
import {IoMdArrowBack, IoMdArrowRoundBack, MdKeyboardArrowLeft, TiArrowLeft, TiArrowLeftThick} from "react-icons/all";

// ======================
// BackButton
// ======================

interface BackButtonProps{
    backPageName? : string,
    backPagePath?: string;
}

export const BackButton: React.FC<BackButtonProps & Partial<ButtonProps>> = observer((props)=>{

    const { backPageName, backPagePath, ...p } = props;

    const renderText = () =>{
        return !!backPageName ? `${backPageName}` : "Go Back"
    }

    const history = useHistory();

    return <Button onClick={!backPagePath ? ()=>history.goBack(): ()=>history.push(backPagePath)}
                   {...p} size={'small'} startIcon={<IoMdArrowBack/>}>{renderText()}</Button>
})