import {
    EMPLOYEE_FORM_LABEL_TYPE,
    MIGRATION_PROJECT_STATUS,
    ProjectActivityEventIDs
} from "../migration/MigrationProjectTypes";

export enum REPORT_TYPES {
    MIGRATION_PROJECTS_SUMMARY = 'MIGRATION_PROJECTS_SUMMARY',
}

export interface SUBMIT_REPORT_TYPE {
    name?: string;
    description?: string;
    engineer?: string;
    projectStatus?: {[key in MIGRATION_PROJECT_STATUS]?: boolean};
    reportType: REPORT_TYPES | string;
}

export interface REPORT_LIST_TYPE {
    items: Array<REPORT_TYPE>
    pagination: PAGINATION_TYPE
}

export interface PAGINATION_TYPE {
    total: number;
    page: number;
    pageSize: number;
}

export interface REPORT_TYPE {
    id: number;
    name: string;
    description: string;
    reportType: REPORT_TYPES;
    createdAt: string;
    createdById: number;
    info: Info;
    filename: string;
    fileObject: string;
    fileSize: string;
    createdBy: USER_TYPE;
}

export interface USER_TYPE {
    id: number;
    userType: string;
    email: string;
    pictureUrl: string;
    fullName: string;
    employeeId: number;
}

export interface Info {
}
