import {observable} from "mobx";
import {
    DROPZONE_FILE_RESPONSE_TYPE,
    DROPZONE_FILE_TYPE,
} from "./dropzone_types";
import {ServerAPI} from "../ServerAPI";
import {DialogService} from "../dialog/DialogService";
import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';


export class DropzoneService {

    api: ServerAPI;
    dialogService: DialogService;

    constructor(api:ServerAPI, dialogService: DialogService){
        this.api=api;
        this.dialogService = dialogService;
    }

    @observable dropzoneStates = new Map()

    initDropzoneState(dropzoneId: string) {
        if (!this.dropzoneStates.has(dropzoneId)){
            this.dropzoneStates.set(dropzoneId, new DropzoneState(dropzoneId, this.api, this.dialogService))
        }
        return this.dropzoneStates.get(dropzoneId)
    }

    deInitDropzoneState(dropzoneId: string){
        this.dropzoneStates.delete(dropzoneId)
    }

    getDropzoneState(dropzoneId: string){
        return this.dropzoneStates.get(dropzoneId)
    }

}

export class DropzoneState {

    dropzoneId: string;
    api: ServerAPI;
    dialogService: DialogService;

    constructor(dropzoneId: string, api: ServerAPI, dialogService: DialogService) {
        this.dropzoneId = dropzoneId;
        this.api = api;
        this.dialogService = dialogService;
    }

    @observable files: Array<DROPZONE_FILE_TYPE> = [];
    @observable uploadedFiles: Array<string> = [];

    @observable uploadProgress= {
        loaded: 0,
        total: 0,
        percent: 0,
    }

    addFile(file: Array<DROPZONE_FILE_TYPE>) {
        this.files = this.files.concat(file)
    }

    formatFileToPost(file: DROPZONE_FILE_TYPE){
        return {
            file_type: file.type,
            filename: file.name
        }
    }

    config = {
        onUploadProgress: (progressEvent: ProgressEvent) => {
            this.uploadProgress.loaded = progressEvent.loaded;
            this.uploadProgress.total = progressEvent.total;
            this.uploadProgress.percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        }
    }

    async uploadFiles(){
        this.files.map(async(file: DROPZONE_FILE_TYPE)=>{
            //const formattedFile = this.formatFileToPost(file)
            const result: DROPZONE_FILE_RESPONSE_TYPE = await this.dialogService.catchAndAlertError(this.api.POST('/files', {}));
            //console.log(result)
            await this.dialogService.catchAndAlertError(axios.put(result.uploadUrl, file, this.config))
            file.uuid = result.uuid
        })
    }

    deleteFile(file: DROPZONE_FILE_TYPE){
        this.files = this.files.filter(existingFile=>existingFile!==file)
    }

}