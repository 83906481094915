import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useParams, useHistory} from "react-router-dom";
import {useAppServices} from "../../app/AppStates";
import {useMigrationProjectDetailsStyles} from "./MigrationProjectStyles";
import {useIsDesktop} from "../../layout/MainLayout";
import {useInitData, useServerData} from "../../core/data/DataLoaderHooks";
import {Box, Button, Card, Chip, Divider, SvgIcon, Tab, Tabs, useTheme} from "@material-ui/core";
import {BackButton} from "../../../common/buttons/commonButtons";
import {ProjectActivity} from "./MigrationProjectActivity";
import {MobileProjectTitleBar, ProjectTitleBar} from "./MigrationProjectDetailsView";
import {
    MIGRATION_PROJECT_QUESTIONNAIRE_TYPE,
    NEW_PROJECT_FORM_VALUES_TYPE,
    PROJECT_TYPE
} from "../MigrationProjectTypes";
import {MigrationProjectDetailsState} from "./MigrationProjectDetailsState";
import {ContactView, EmployeeView} from "../../auth/EmployeeView";
import {AiOutlineProject, AiOutlineSetting, FiActivity, IoMdCalendar, MdCheck} from "react-icons/all";
import Typography from "@material-ui/core/Typography";
import {MuiTabContent} from "../../core/tab/Tabs";
import {ProjectQuestionnaireView, QuestionnaireHeader} from "./Questionnaire/MigrationProjectQuestionnaireView";
import {APP_ROUTES} from "../../app/AppRoutes";
import {renderDealType, StatusIcon} from "../MigrationProjectCommon";
import xbytes from "xbytes";
import {PopoverChip, UserListPopover} from "../../../common/popover/PopoverButton";
import {Path} from "../../../common/path/Path";
import {PopoverState} from "../../../common/popover/PopoverService";

// ======================
// ProposedMigrationDetailsPage
// ======================

interface ProposedMigrationDetailsPageProps {
    data: PROJECT_TYPE,
    detailsState: MigrationProjectDetailsState
}

export const ProposedMigrationDetailsPage: React.FC<ProposedMigrationDetailsPageProps> = observer((props) => {
    const {data, detailsState} = props;
    const {migrationProjectService} = useAppServices();
    const isDesktop = useIsDesktop();
    const styles = useMigrationProjectDetailsStyles(props);
    const history = useHistory();

    const [popoverState, setPopoverState] = useState(new PopoverState(data.projectId))

    const EDIT_DETAILS_TAB = 3;

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        detailsState.setTab(newValue);
    };

    const handleApproval = () => {
        migrationProjectService.approveProposedProject(data.projectId).then((value) => {
            if (value === true) {
                detailsState.setTab(EDIT_DETAILS_TAB)
            }
        });
    }

    const deleteProject = async() => {
        const deleted = await migrationProjectService.deleteProject(data.projectId);
        if (deleted){
            history.replace(APP_ROUTES.MIGRATION)
        }
    }

    return <Box pb={4}>
        <Box pb={1} className={styles.titleBar}>
            <BackButton backPageName={'All Projects'} backPagePath={APP_ROUTES.MIGRATION}/>
        </Box>
        {isDesktop &&
        <ProjectTitleBar data={data} detailsState={detailsState} proposal/>
        }
        {!isDesktop &&
            <MobileProposalTitleBar data={data} detailsState={detailsState}/>
        }
        <Divider/>
{/*        <Box pt={4} width={'100%'}>
            <Tabs value={detailsState.tab} onChange={handleTabChange} centered={true} variant={'fullWidth'}>
                <Tab label={<Box className={styles.tabIcons}><SvgIcon><AiOutlineSetting/></SvgIcon><Typography
                    variant={'caption'}>Questionnaire</Typography></Box>}/>
                <Tab label={<Box className={styles.tabIcons}><SvgIcon><AiOutlineSetting/></SvgIcon><Typography
                    variant={'caption'}>Proposal Details</Typography></Box>}/>
            </Tabs>
        </Box>*/}
        <MuiTabContent value={detailsState.tab} index={0}>
            <Box pt={4}>
                {data.project.questionnaireId &&
                <ProjectQuestionnaireView fetchDataFunction={() => detailsState.fetchQuestionnaireInfo()}
                                          data={detailsState.questionnaire} proposalId={data.projectId}
                                          setTabFunction={() => detailsState.setTab(EDIT_DETAILS_TAB)}
                                          popoverState={detailsState.questionnaireMenuPopoverState}
                                          updateQuestionnaireFunction={(values: MIGRATION_PROJECT_QUESTIONNAIRE_TYPE)=>detailsState.updateQuestionnaireInfo(values)}
                />
                }
                {!data.project.questionnaireId &&
                <Box>
                    <QuestionnaireHeader popoverState={popoverState} approveProject={handleApproval} deleteProject={deleteProject}/>
                    <Box pt={6}>
                        <Card elevation={0}>
                            <Box p={2} className={styles.noQuestionnaireData}>
                                <Typography variant={'h6'}>No questionnaire data.</Typography>

                            </Box>
                        </Card>
                    </Box>
                </Box>
                }

            </Box>
        </MuiTabContent>
{/*        <MuiTabContent value={detailsState.tab} index={1}>
            <Box pt={4}>
                <EditProjectDetailsSection
                    updateDetailsFunction={(values: NEW_PROJECT_FORM_VALUES_TYPE, message?: boolean) => detailsState.updateProjectInfo(values, message)}
                    data={data} isProposal={true} approvalFunction={handleApproval}/>
            </Box>
        </MuiTabContent>*/}
    </Box>
})

// ======================
// MobileProposalTitleBar
// ======================

interface MobileProposalTitleBarProps {
    data: PROJECT_TYPE,
    detailsState: MigrationProjectDetailsState

}

export const MobileProposalTitleBar: React.FC<MobileProposalTitleBarProps> = observer((props) => {
    const styles = useMigrationProjectDetailsStyles(props);
    const {data, detailsState} = props;


    return <>
        <Box className={styles.mobileProjectTitleBar}>
            <Box>
                <Typography variant={'h4'} className={styles.number}>{data.project.projectName}</Typography>
            </Box>
        </Box>
        <Box className={styles.mobileProjectTitleBar} pt={2}>
            <StatusIcon status={data.project.status}/>

            <Box>
                    <PopoverChip popoverState={detailsState.timeFramePopover}
                                 label={'More Info...'}
                                 children={<>
                                     <Box pb={2}>
                                         <Typography variant={'overline'} className={styles.moreInfoTitles}>
                                             Data
                                         </Typography>
                                         <br/>
                                         <Chip label={xbytes(data.migrationDataAmount as number)}/>
                                     </Box>
                                     <Box pb={2}>
                                         <Typography variant={'overline'} className={styles.moreInfoTitles}>
                                             Project ID
                                         </Typography>
                                         <br/>
                                         <Chip label={data.project.projectCode}/>
                                     </Box>
                                     <Box pb={2}>
                                         <Typography variant={'overline'} className={styles.moreInfoTitles}>
                                             Time Frame
                                         </Typography>
                                         <Path from={data.project.projectTimeFrameFrom}
                                               to={data.project.projectTimeFrameTo}/>
                                     </Box>
                                     <Box pb={2}>
                                         <Typography variant={'overline'} className={styles.moreInfoTitles}>
                                             Migration Path
                                         </Typography>
                                         <Path from={data.migrationSource} to={data.migrationDestination}/>
                                     </Box>
                                     <EmployeeView>
                                         <Box pb={2}>
                                             <Typography variant={'overline'} className={styles.moreInfoTitles}>
                                                 Deal Type
                                             </Typography>
                                             <br/>
                                             <Chip label={renderDealType(data.dealType, 'caption')}/>
                                         </Box>
                                     </EmployeeView>
                                 </>}
                    />
            </Box>
        </Box>
        {
            data.project.projectDescription &&
            <Box pb={3} pt={2}>
                <Typography variant={'body1'}>{data.project.projectDescription}</Typography>
            </Box>
        }
        { !data.project.projectDescription &&
            <Box pb={3}/>
        }
    </>
})